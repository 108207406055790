:root {
    --font-family: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
}

/* MINDBREEZE REBRAND CSS CODE */

/* 
    sizes being used for mobile development:
    iPad: 768px x 1024px
    Phone: 375px x 812px
*/

@media only screen and (min-width: 768px){
    /** Search button icon */
    .btn.btn-link.mb-no-print {
        right: 5px !important;
    }

.span3.box-width.people {
     height: 243px !important;
 }
    #filter-column-nav{
        margin-top: 0px !important;
        padding-left: 5px !important;
        width: 100vw !important;
        padding-right: 0px !important;
    }
    
    .container {
        max-width: 1440px !important;
        margin:auto;
        padding-left: 0% !important;
        padding-right: 0% !important;
    }

    .mb-stack{
       
        max-width: 1440px !important;
    }
    .mb-stack-result {
        margin: 0% !important;
        padding: 0% !important;
    }

    .mb-stack-results-container.container .row-fluid{
        width: 100% !important;
        max-width: 1440px !important;
    }

    .box-width {
        width: 260px !important;
        text-align: center !important;
        cursor: pointer !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .box-width.people {
        width: 253px !important;
        text-align: center !important;
        cursor: pointer !important;
        margin-left: 0px!important; /* margin between boxes */
        margin-right: 10px!important;
        margin-top: 10px!important;
        margin-bottom: 10px!important;

    }

    .content-title {
        line-height: 1;
        color: #141B4D;
        font-size: 36px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
    }

    .content-title-left {
        line-height: 1;
        color: #141B4D;
        font-size: 36px !important;
        font-weight: bold!important;
        font-family: var(--font-family);
    }

    .content-title-right {
        font-size: 24px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
        margin-left: auto !important;
    }

    .row.people{
        margin-left: 5px!important;
    }

    .content-title-advanced{
        display: none!important;
    }

    .sourceTabs {
        padding-left: 12px !important;
        display: flex !important;
        flex-direction: row!important;
    
    }

    .sourceTabs>li{
        /* flex-wrap: nowrap; */
        flex:1
    }
    .nav{
        flex-wrap: nowrap;
    }
    .navbar-sourcetabs .container {
        max-width: 100%;
        padding: 0px!important;
    }
    .navbar-inner>.container{
        padding: 0px;
    }
    .navbar .nav {
        display: flex !important;
        gap: 14px !important;
        flex-direction: row!important;
        margin: 0 10px 0 0;
    }
    #filters-column {
            margin-top: -20px !important;
        padding-left: 8px !important;
        width: 100% !important;
            max-width: 250px !important;
    }
   
    .results-more-people{
        width: 80% !important;

    }

    #search-results-container {
        max-width: 1000px !important;
            width: 100% !important;
        }
        
        .media.center.all {
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            align-items: center;
            justify-content: center;
            height: 145px !important;
            padding: 0px !important;
        }
        
        #people-box {
            width: 100% !important;
            max-width: 235px;
        }
                                /* for people results boxes */
                                [class~="mb-dont-serialize"] {
                                    padding-left: 0px !important;
                                    padding-right: 10px !important;
                                }
}

/* phone */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .sourceTabs {
        display: flex !important;
        align-items: center;
    }
    .navbar-sourcetabs .container {
        padding-left: 15px !important;
    }
.navbar-sourcetabs {
    padding-right: 0px !important;
}

#search-by-container {
    padding-right: 0px !important;
}

#more-button button {
    padding: 0px 15px !important;
    line-height: 27px;
    color: #000;
    border: 1px solid #D0D3D4;
    border-bottom-color: white;
    height: 35px;
}

#more-button::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 31px;
    background-color: #D0D3D4;
    height: 1px;
    width: 100%;
    z-index: -1;
}

.more-button-before::before {
    content: ' ';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #D0D3D4;
}

#more-chevron {
    margin-left: 2px;
}

#search-by-options {
    padding: 0 !important;
}

.more-active {
    font-weight: 500;
    color: #0047bb !important;
}

.extra-tabs {
    position: absolute !important;
    top: 45px;
}

.learning-tab {
    left: 15% !important;
}

.policies-tab {
    left: 50% !important;
}
    
    .mb-component-container{
        padding-bottom: 25px !important;
    }

    #results-container {
        margin-top: 63px !important;
    }

    #filter-column-nav{
        margin-top: 0px !important;
        width: 90vw !important;
    }

    .media.center.people{
        width: 245px!important;
        height: 160px!important;
        padding-left: 5px !important;
        padding-right: 0px;
    }
            .media.center.all,
            .media.center.people {
        display: grid !important;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            align-items: center;
            justify-content: center;
        height: 145px!important;
        padding-left: 5px !important;
        padding-right: 0px;
        }

    .only-desktop{
        visibility: hidden; /* NS input field */
    }

    .nw-container {
        width: 100% !important;
        max-width: none !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .search-field {
        padding-top: 10px !important;
        margin-bottom: 10px !important;
        display: none!important; /* NS input field */
    }

    .underline{
        margin-top: 50px!important;
    }

    .navbar-sourcetabs .mb-role {
        padding-bottom: 20px !important;
        width: 100% !important; /* header tabs "All,People,News,Learnings,Policies"*/
    }

    #search-homepage-id {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }

    #people-container {
        width: 300px !important;
        height: 117 !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .media.center{
        width: 90%!important
    }

    .content-title {
        color: #141B4D;
        font-size: 32px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
        text-align: left;
    }

    .content-title-left {
        color: #141B4D;
        font-size: 32px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
        text-align: left;
    }

    .content-title-right {
        display: none;
        font-size: 14px!important;
        padding:5px;
        font-family: var(--font-family);
        position: absolute !important;
        text-align: left !important;
    }

    .content-title-advanced{
        display: block!important;
        font-size: 14px!important;
        padding:5px;
        font-family: var(--font-family);
        position: absolute !important;
        text-align: left !important;
    }

    .box-width {
        width: 260px !important;
        height: 148px !important;
        text-align: center !important;
        cursor: pointer !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    /* show more people */
    .box-width.people {
        width: 260px !important;  
        height: 148px !important; 
        text-align: center !important;
        cursor: pointer !important;
        margin: 10px!important;
    }

    #media-body-id h5 {
        margin-left: 10px !important;
        margin-bottom: 4px !important;
        
    }

    #media-body-id h4 {
        margin-left: 10px !important;
        margin-bottom: 4px !important;
        padding-top: 6px !important;
    }

    #people-search-results-container {
        align-content: center !important;
        text-align: center !important;
    }

    #advanced-people-search-title-container {
        align-content: center !important;
        text-align: center !important;
        padding-bottom: 50px !important;
    }

    #people-search-results-container .mb-dont-serialize {
        padding-bottom: 10px !important;
    }

    .offset7 {
        margin-left: 0px !important;
    }
    .people-search {
        text-align: center !important;
    }

    .best-bets-results-container {
        width: 300px !important;
        padding-left: 0px !important;
    }

    .mb-stack-results {
        width: 300px !important;
    }

    .mb-bestbets-result {
        max-width: 200px !important;
        padding-right: 60px !important;
    }

    .best-bets-title {
        max-width: 300px !important;
    }

    .best-bets-url-p {
        max-width: 300px !important;
    }

    .best-bets-url {
        max-width: 300px !important;
    }

    .best-bets-description {
        max-width: 300px !important;
    }

    .row{
        margin-left: 8px!important;
    }
#about-container {
    display: none !important;
}
}

#people-container .media, .span3.box-width.people .media {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
}

/* to allow for more results to appear when scrolling to the bottom of mobile view */
.mb-scroll-placeholder {
    /* allows scrolling down to get more results on mobile */
    display: block !important;
    /* stops people results from loading indefinitely on all devices/mobile */
    clear: both !important;
}

#search-results-container {
    margin: 0px !important;
}

@media only screen and (min-width: 1250px) {
    #about-container {
        z-index: 3 !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1250px) {
    #about-container {
        top: -120px !important;
        position: absolute !important;
        z-index: 0 !important;
    }

    #filters-column {
        margin-top: 125px !important;
    }
}
/* tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .sourceTabs {
        padding-left: 0px !important;
    }
    #filter-column-nav{
        margin-top: 0px !important;
        width: 100vw !important;
    }

#filters-column {
    width: 40vw !important;
    max-width: 277px !important;
    margin-top: 125px !important;
}

#about-container {
    position: absolute !important;
    top: -120px !important;
    z-index: 0 !important;
}
    .media.center.all{
        display: flex !important;
            flex-direction: column !important;
            justify-content: space-between !important;
            align-items: center !important;
            gap: 6px !important;
            height: 100% !important;
            padding: 0px !important;
            padding-top: 20px !important;
        }

        .box-width.people {
            margin-right: 6px !important;
        }
        
        #people-box {
            width: 184px !important;
            height: 243px !important;
        }
        
        [class~="mb-dont-serialize"] {
            padding-right: 8px !important;
        }
        
        .span3.box-width .column-right #search-preferred-name,
        .span3.box-width .column-right #search-job-title {
            text-align: center !important;
            width: 100% !important;
        }
        
        .span3.box-width .column-right #search-preferred-name {
            -webkit-line-clamp: 1 !important;
            line-clamp: 1 !important;
        }
                                .only-desktop {
                                    /* NS input field */
        visibility: hidden;
}

#search-login-id {
    transform: translateX(50%);
    }

    #mb-component-container {
        padding: 0px !important;
        margin: 0px !important;
        max-width: none !important;
    }

    #search-results-container {
        width: 55vw !important;
            margin-left: 40px !important;
    }

    .mb-query {
        margin: 0px !important;
        max-width: none !important;
    }

    .navbar-sourcetabs {
        width: 100% !important;
        padding-bottom: 10px !important;
    }

    #align-left {
        display: none !important;
    }

    #results-container {
        padding-top: 10px !important;
        margin-top: 0px !important;
    }

    .search-field {
        z-index: 120 !important;
    }


    .mb-hint {
        padding-top: 3px !important;
    }

    .btn-link {
        padding: 5px !important;
    }
    .results-title {
        align-content: center !important;
    }

    #advanced-people-search-title-container {
        align-content: center !important;
        padding-bottom: 50px !important;
    }

    .mb-bestbets-result {
        max-width: 109px !important;
    }

    .best-bets-img {
        display: none !important;
    }

    .media-object.media-object-icon {
        display: none !important;
        border: none !important;
    }

    .mobile-view-tag ul {
        margin-left: 0px !important;
    }

    .best-bets-results-container {
        padding-left: 0px !important;
    }

    #media-body-id h4,
    h5 {
        margin-left: 10px !important;
    }

    .offset7 {
        margin-left: 0px !important;
    }

    .no-results-view {
        text-align: center !important;
        margin-left: 0px !important;
    }
    
    .container-fluid {
        margin-left: 0px!important; /* when adjusting screen resolution */ 
    }

    .content-title {
        color: #141B4D;
        font-size: 32px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
        text-align: left;
    }

    .content-title-left {
        color: #141B4D;
        font-size: 32px!important;
        font-weight: bold!important;
        font-family: var(--font-family);
        text-align: left;
    }

    .content-title-right {
        display: none;
        font-size: 14px!important;
        padding:5px;
        font-family: var(--font-family);
        position: absolute !important;
        text-align: left !important;
    }

    .content-title-advanced{
        display: block!important;
        font-size: 14px!important;
        padding:5px;
        font-family: var(--font-family);
        position: absolute !important;
        text-align: left !important;
    }

    .navbar-sourcetabs .container {
        padding-left: 15px !important;
    }

}

/* desktop / general styles */
/* 3-4 divs */
@media (min-width: 1530px){
    .results-more-people{
        width: 100% !important;
        flex-wrap: wrap !important;
        max-width: 1140px !important;
    }
}

@media  (min-width: 1198px) and (max-width: 1530px){
    .results-more-people{
        width: 100% !important;
        flex-wrap: wrap !important;
        max-width: 1140px !important;
    }
}


@media (max-width: 1198px){
    .results-more-people{
        width: 100% !important;
        flex-wrap: wrap !important;
        max-width: 1140px !important;
    }
}


.only-desktop{ /* NS input field */
    visibility: hidden;
}

em {
    background-color: #f9e27d !important;
}

/* for search bar */
#mb-query-id {
    background-color: white !important;
    border-bottom-color: blue !important;
    border-bottom-width: 4px !important;
    font-size: 20px !important;
    height: 50px !important;
    padding-right: 0px !important;
    box-shadow: 0px 3px 0px blue !important;
    padding-bottom: 0px !important;
}

/* for results number at top left of page */
#search-homepage-id-h1 {
    color: #000 !important;
    text-transform: none !important;
    line-height: 1;
    font-size: 16px !important;
        font-weight: 700;
    text-align: left !important;
}

#align-left {
    width: 150px !important;
}

/* for the container surrounding the results */
#results-container {
    margin-left: 0px !important;
    margin-right: auto !important;    
}

/* for filters - important so that they display in a vertical list */
[role~="tabpanel"] ul {
    display: block !important;
}

/* for the titles of the filters */
[role~="tab"] {
    font-weight: 500 !important;
    letter-spacing: normal !important;
}

/* for the checkbox in filters */
.checkbox {
    width: 100% !important;
}

/* for the thick blue line under the search bar */
.underline {
    padding: 0px !important;
    border-top: 3px solid #0047bb;
    margin: 0px ;
    stroke-linecap: butt !important;
    margin-top: 0px;
    max-width: 1440px;
}

/* actual height of the search bar */
.search-field {
    height: 0px;
    margin-bottom: 0px;
    width: 100% !important;
}

#search-field:focus {
    border: 1px solid #cccccc !important;

}

/* for search bar when a user does not click into it */
.mb-query {
    background-image: none !important;
    height: 50px !important;
    font-size: 18px !important;
    width: 100% !important;
}

/* for search bar when a user clicks into it */
.mb-query:focus {
    margin-bottom: 0px !important;

    box-shadow: 0 0 1px 0 black !important;

}

/* to make the suggestions appear above everything else when typing in the search bar */
.ui-menu {
    z-index: 1000 !important;
}

.mb-role {
    position: static !important;
}

/* for different types of search underneath search bar */
.navbar-sourcetabs {
    padding-top: 0px !important;
    text-decoration-color: white !important;
    -webkit-focus-ring-color: white !important;


}

.navbar-sourcetabs:focus {
    font-weight: 700 !important;
}

a[class^='action tab-']:focus {
    outline: none !important;
}

/* for search bar */
.navbar-inner {
    padding-left: 0px !important;
    margin-left: 0px !important;
}

/* for search bar */
.navbar {
    padding-left: 0px !important;
}

/* for each type of search underneath search bar when not hovered on */
.action {
    font-size: 18px !important;
    padding-top: 0px !important;

    text-transform: initial !important;
    font-weight: normal !important;

}

/* for each type of search underneath search bar when hovered on */
.action:focus {
    font-weight: bold !important;
}

/* for relevance drop down under search bar */
.mb-line-item {
    padding-top: 10px !important;
    height: 30px !important;
    font-size: 12px !important;
    color: #161616 !important;
}

/* for padding on the "relevance" text inside the drop down bar */
.mb-line-item select {
    padding: 5px !important;
}

/* for the ascending/descening button next to relevance dropdown */
.mb-line-item fieldset {
    height: 30px !important;
}

/* for image in front of each source - NOT FOR V1 */
.image {
    position: absolute;
    right: 15px;
    top: 10px;
}

/* for lines surrounding filter titles */
.mb-role h3:before {
    background-color: #6eceb2 !important;
}

/* bottom of tabs beneath search bar */
.mb-component-container {
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* to put padding under the nav tabs */
.mb-stack-results {
    padding-top: 0px !important;
    padding-left: 0px !important;
    max-width: inherit !important;
}

.mb-stack-content.mb-stack-results{
    width: 100%!important;
}

/* to space people results out on People page */
.people-result {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

/* for people results - width of actual result */
#media-body-id div {
    width: 200px !important;
}

/* for people results -  preferred first/last name */
#search-preferred-name{
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
    width: 130px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
}

#search-login-id{
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0px;
    color: #171717;
    text-transform: uppercase;
    opacity: 1;
    clear: both;
    bottom: 10px;
    position: absolute;
}

@media (min-width: 1024px) {
    .media.center.people #search-login-id {
        left: 50%;
        transform: translateX(-50%);
    }
}

#search-login-id:hover {
    color: blue;
    text-decoration: underline !important;
}

#search-job-title{
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
}

.us-phone{
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
}

  .column-right {
    width: 140px !important;
        margin-top: 5px !important;
    padding: 5px!important;
  }

  /* Clear floats after the columns */
  .row:after {
    width: 235px !important;
    content: "";
    display: table;
    clear: both;
  }

#media-body-id h4 {
    text-align: center !important;
    width: 160px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding-top: 12px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
    --max-lines: 1 !important;
    -webkit-box-orient: vertical !important;
    white-space: nowrap !important;
}

/* for people results -  Job Title, Business Title */
#media-body-id h5 {
    font-size: 14px !important;
    font-weight: normal !important;
    width: 155px !important;
    height: 20px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
    --max-lines: 1 !important;
    -webkit-box-orient: vertical !important;
    white-space: nowrap !important;
}

/* for people results - email address link */
#media-body-id small {
    font-size: 10px !important;
    color: #0047bb !important;
}

/* for people results - Azure AD image */
.media-object-icon img {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
    border: 1px solid #D0D3D4 !important;
}


/* for people results - box border when not focused, might not need this code */
.media-center {
    width: 184px !important;
    height: 256px !important;
    align-content: center !important;
    border-left: 1px solid #d0d3d4 !important;
    border-right: 1px solid #d0d3d4 !important;
    border-top: 1px solid #d0d3d4 !important;
    border-bottom: 1px solid #d0d3d4 !important;
}

/* for people results - box border when not focused */
.box-width {
    align-content: center !important;
    border-left: 1px solid #d0d3d4 !important;
    border-right: 1px solid #d0d3d4 !important;
    border-top: 1px solid #d0d3d4 !important;
    border-bottom: 1px solid #d0d3d4 !important;
    text-align: center !important;
    cursor: pointer !important; 
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

/* for people results - box border when focused */
.box-width:hover {
    border-left: 1px solid #6eceb2 !important;
    border-right: 1px solid #6eceb2 !important;
    border-top: 1px solid #6eceb2 !important;
    border-bottom: 1px solid #6eceb2 !important;
}

/* for people results - email addresses */
#wrap {
    text-align: center !important;
    width: 155px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    white-space: nowrap !important;
}

/* for all titles related to Content search */
.content-title {
    line-height: 1;
    color: #141B4D;
    font-size: 36px;
    font-weight: bold!important;
    font-family: var(--font-family);
}




/* for all titles related to People search */
.people-search {
    font-size: 16px;
    color: #0047bb !important;
    text-align: right;
}

/* For "Advanced people search" title */
.advanced-people-search {
    text-align: left;
}

/* People and Advanced People Search row */
#advanced-people-search-title-container {
    padding-top: 10px;
    margin-right: 30px !important;
}

/* Title for people results "Show more results" */
#show-more-results-title {
    padding-top: 1px;
    color: #0047bb !important;
}

#show-more-results-title:hover {
    text-decoration: underline #0047bb !important;
}

/* little lines in between Open, Open Parent, and Preview options */
.nowrap::before {
    background-color: #6eceb2 !important;
    color: #6eceb2 !important;
}

/* line between voting and message */
.voting-confirmation::before {
    background-color: #6eceb2 !important;
    color: #6eceb2 !important;
}

/* Open, Open Parent, Preview options when not hovered */
.mb-actions a {
    color: #0047bb !important;
    font-size: 16px !important;
    font-weight: normal !important;
}

/* Open, Open Parent, Preview options when hovered */
.mb-actions a:hover {
    color: #307fe2 !important;
}

.voting-confirmation {
    visibility: hidden !important;
    
}

.media-heading {
    font-weight: bold !important;
}

/* Title of each search result without hover */
.media-heading a {
    color: #141b4d !important;
}

/* Title of search result when hovered on */
.media-heading a:hover {
    color: #141b4d !important;
}

/* Meta data on each search result */
.mb-metadata {
    color: #7e7e82 !important;
    font-size: 10px !important;
}

/* Description under meta data on each search result */
.mb-content {
    color: #171717 !important;
    font-size: 14px !important;
    width: 90% !important;
    font-weight: normal;
}

/* for best bets - result container to align with other results */
.best-bets-results-container {
    padding-left: 16px;
}

/* for best bets - image */
.best-bets-img img {
    height: 100px !important;
    width: 80px !important;
    align-content: center !important;
    margin-left: 4px !important;
    margin-top: 3px !important;
}

.best-bets-img {
    align-content: center !important;
    width: 100px !important;
}

/* for best bets - title */
.best-bets-title {
    padding-left: 10px !important;
}

/* for best bets - url */
.best-bets-url {
    padding-left: 11px !important;
    font-size: 14px !important;
    width: 600px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
    --max-lines: 1 !important;
    -webkit-box-orient: vertical !important;
    white-space: nowrap !important;
}

.best-bets-url-p {
    margin-bottom: 0px !important;
}

/* for best bets - description */
.best-bets-description {
    padding-left: 6px !important;
    font-size: 14px !important;
}

/* SOURCE TAGS FOR RESULTS */
.media-body{
    font-family: var(--font-family);
    width: 80% !important;
}

.media-body li {
    width: 100% !important;
}

.res-source-SharePoint {
    background-color: #307fe2 !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-InSide {
    background-color: #0047bb !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-HRConnect {
    background-color: #008b8b !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-ServiceNow {
    background-color: darkcyan !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-Support {
    background-color: #7e7e82 !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-News {
    background-color: #141b4d !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

.res-source-Policies {
    background-color: #cb333b !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    width: 94px !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}


li[class~="A-Z"] {
    background-color: #008775 !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    display: normal !important;
    padding-left: 4px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
}

/* to be able to set a width for the source labels */
.mb-separated {
    width: 90% !important;
}

/* for "Saved Searches" title above filters */
.mb-saved-searches h2 {
    color: #171717 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}

/* for hint below "Saved Searches" title */
.mb-hint {
    font-size: 12.5px !important;
    color: #171717 !important;
    font-weight: normal !important;
}

/* for a Saved Search */
.mb-text {
    width: 100% !important;
    text-align: left !important;
    padding-right: 10px !important;
    padding-left: 5px !important;
    font-size: 12px !important;
    color: #0047bb !important;
    font-weight: 500 !important;
    display: block !important;
    font-family: var(--font-family);

}

/* getting rid of outline around saved search */
.mb-text:focus-visible {
    outline: 0px !important;
}

/* getting rid of outline around saved search */
.mb-saved-searches .mb-line-item .mb-inplace-editable:focus {
    outline: 0px !important;
}

/* increase padding on either side of copy icon */
.copy-url-icon {
    margin-right: 3px !important;
    margin-left: 3px !important;
}

/* to make search appear in line with "Saved Searches" title */
li .mb-line-item {
    padding: 0px !important;
}

/* to make garbage can go to the right hand side of the saved searches container */
[role~="region"] li {
    width: 100% !important;
    text-align: left !important;
}

/** Search button icon */
.btn.btn-link.mb-no-print {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 0;
}

/* trash icon for saved searches */
.icon-trash:focus-visible {
    outline: none !important;
}

/* getting rid of outline on trash icon */
.mb-saved-searches button:focus {
    outline-color: white !important;
}

/* for magnifying glass */
.btn.btn-link.mb-no-print>img {
    width: 40px;
    height: 40px;
    padding: 5px;
}

.mb-has-child {
    padding-top: 40px !important;
    align-content: center !important;
    padding-left: 100px !important;
}

#query {
    font-style: normal !important;
    color: #171717 !important;
}


/* for default results image */
#result-img {
    border-radius: 0px !important;
    height: 90px !important;
    width: 80px !important;
    align-content: center !important;
}

/* for video results image */
#video-result-img {
    width: 100px !important;
    height: auto !important;
    border-radius: 0px !important;
}

.media-object.media-object-icon img {
    max-width: none !important;
}

.mb-stack-close {
    position: static !important;
}

.mb-progress-bar::before {
    border-top: 4px solid #204D9B !important;
    margin-top: -10px !important;
}

@media (max-width: 1025px) {
    .mb-progress-bar::before {
        margin-top: 0px !important;
    }
}

/* feedback text click */
.feedbackClick {
    display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
        border: 1px solid #0047BB !important;
        border-radius: 4px;
    left: 19px;
    width: 137px;
    height: 36px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.feedbackClick:hover,
.mb-feedbackbutton:hover {
    text-decoration: none;
    color: #0047BB;
}

.mb-feedbackbutton {
    width: 137px !important;
    position: sticky !important;
    outline: none !important;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #0047BB;
    cursor: pointer;
    z-index: 0 !important;
}

.backToTop{
    height: 31px;
    width: 119px;
    text-align: center;
    border-radius: 2px;
    border: solid 1px #7e7e82;
    background-color: #e7e9e9;
    position: fixed;
    transform: rotate(-90deg) translateX(-50%);
    right: -45px;
    margin-top: auto;
    top: 55%;
    z-index: 5;
  }

  .backToTop:hover{
    border: solid 1px #7e7e82;
    background-color: #d0d3d4;
    text-decoration: none !important;
  }
  .backToTopText{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #171717;
}

.backToTopText:hover {
    color: #171717;
    text-decoration: none !important;
  } 
 
  .title-hr{
    border-top: 2px solid #141B4D!important;
    padding-bottom: 20px !important;
 }
 
 #more-people-container{
    margin-top: -10px !important;
 }

 .media.center.people{
    display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        height: 100%;
    padding: 0px !important;
    padding-top: 20px !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .media.center.people {
        padding-top: 0px !important;
    }
}

.no-results-view, .no-people-view{
    display: none;
    padding-top: 3px;
}

.no-results-view, .no-people-view, 
.no-results-view .mb-dont-serialize, 
.no-people-view .mb-dont-serialize,
.pDidYouMean
{
    margin-left: 0 !important;
    padding-left: 0 !important;
    font-size: 18px;
    font-family: var(--font-family);
    font-weight: 700;
    color: #171717;
    text-align: left;
}

p.pDidYouMean{
    padding-left: 0 !important;
}

.pDidYouMean a > em, #cDidYouMean a > em{
    color: #0047BB !important;
    background-color: white !important;
}

.no-results-view p, 
.no-people-view p{
    text-align: left;
    padding-left: 0 !important;
    font-family: var(--font-family);
    letter-spacing: 0px;
    font-size: 18px;
    color: #171717;
    font-weight: 700;
    margin: 0;
} 

#tipsId{
    margin-top: 10px !important;
}

#tipsId p{
    font-weight: normal !important;
    padding-bottom: 5px !important;
}

p.tips a{
    color  : #0047BB;
    font-weight: bold;
}

.no-people-view p a em,
.no-results-view p a em{
    background-color: white !important;
}

#newsearch-main-div {
    width: 100% !important;
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    }
    
    @media (min-width: 1024px) {
        #newsearch-main-div {
            margin-top: 90px;
        }
}
.newsearch-main-div {
    width: 100% !important;
    max-width: 1440px;
    margin: auto;
}
#people-search-results-container {
    padding-top: none !important;
    max-width: 1140px !important;
    padding-bottom: 25px !important;
}
.results-more-people{
    max-width: 1140px !important;
}

.mb-filters h3>span {
    z-index: 0 !important;
}

.mb-stack-results-container.container .row-fluid {
    max-width: 1440px !important;
}

.navbar-sourcetabs .container ul.nav li >  a{
    line-height: 27px !important;
    text-shadow: none !important;
    font-weight: 600 !important;
}

#sources-div h3 span,
#date-div h3 span,
#file-div h3 span,
#kb-div h3 span,
#updated-div h3 span {
    font-weight: bold;
}

.mb-tooltip,
.pull-right {
    font-weight: 500;
}

.backgroundDiv {
    position: fixed;
    top: 130px;
    width: 100vw;
    background-color: white;
}

#search-by-container {
    display: flex !important;
}

#search-by-options {
    margin: 0 !important;
}

#about-container {
    position: fixed;
}

.sourceTabs li {
    color: #171717 !important;
    border: 1px solid #0047BB;
    border-radius: 1000px;
    font-size: 18px !important;
}

.sourceTabs li a {
    padding: 0 14px !important;
}

.navbar-inner .nav>li>a:after {
    content: none !important;
}

.navbar-sourcetabs .nav>li:hover {
    background-color: #0047BB !important;
}

.navbar-sourcetabs .nav>li:hover a {
    color: white !important;
    font-weight: bold !important;
    text-shadow: none !important;
}

#source-tabs-container {
    max-width: 1000px;
    position: fixed;
    z-index: 2;
    top: 210px;
}

#all-people-results-button {
    color: #171717 !important;
    border: 1px solid #0047BB;
    border-radius: 1000px;
    padding: 5px 14px;
    font-weight: 500;
    font-size: 18px !important;
}

#all-people-results-button:hover {
    background-color: #0047BB !important;
    color: white !important;
    text-decoration: none !important;
}

#people-content {
    display: inline-block;
}

#results-title, #people-content {
    margin-bottom: 10px;
}

.span3.box-width.people .column-right #search-preferred-name,
.span3.box-width.people .column-right #search-job-title {
    text-align: center !important;
    width: 100% !important;
}

@media (max-width: 767px) {
    .span3.box-width.people .column-right #search-preferred-name,
    .span3.box-width.people .column-right #search-job-title {
        text-align: left !important;
        width: auto !important;
    }
}


@media (max-width: 1024px) {
    .backgroundDiv {
        top: 50px;
    }

    #search-by-container span {
        display: none !important;
    }

    #source-tabs-container {
        top: 100px !important;
    }

    .sourceTabs li {
        border: none;
    }

    .sourceTabs li:not(:has(button)) {
        position: relative;
    }

    .navbar-sourcetabs .container ul.nav li>a {
        color: #000 !important;
    }

    .navbar-sourcetabs .nav>li:has(a[aria-selected=true]) {
        background-color: transparent !important;
    }
    
    .navbar-sourcetabs .nav>li>a[aria-selected=true] {
        font-weight: 500 !important;
        text-shadow: none !important;
    }

    .navbar-sourcetabs .nav>li:not(:has(button))::after {
        content: ' ';
        position: absolute;
        width: 65%;
        height: 2px;
        background-color: transparent;
        margin: auto;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }

    .navbar-sourcetabs .nav>li:has(a[aria-selected=true])::after {
        background-color: #0047BB;
    }

    .navbar-sourcetabs .nav>li:hover {
        background-color: transparent !important;
    }

    .navbar-sourcetabs .nav>li:hover a {
        font-weight: 500 !important;
    }

    .navbar-sourcetabs .nav>li:not(:has(button)):hover::after {
        background-color: #0047BB;
    }


}