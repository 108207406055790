@import "../../../assets/scss/index.module.scss";

.events {
  margin-bottom: 5px;
  border: 0;

  &.companyNews {

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      -ms-grid-column: 1;
      -ms-grid-row: 7;
      grid-column: 1;
      grid-row: 7;
    }

    @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
      -ms-grid-column: 3;
      grid-column: 2;
      grid-row: 3;
    }
  }

  .toolsHeading {
    position: relative;
    border-bottom: solid 4px $steel-grey;
    font-family: $SegoeUI;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    margin-top: 0;
    // margin-bottom: -1px;

    .titleLink {
      color: $charcoal-grey;
    }

    .titleLink:hover {
      cursor: pointer;
      color: $accent-blue !important;
    }

    .title {
      font-family: $SegoeUI;
      font-size: 24px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal-grey;
      display: inline-block;
      margin: 0;
      margin-bottom: 6px;
      margin-top: -20px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    border: 1px solid transparent;
    font-family: $SegoeUI;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $accent-blue;
    margin-bottom: 2px;
  }

  a {

    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: underline;

      .visitlink {
        text-decoration: underline;
      }
    }
  }

  .visiteventlink {
    background-color: transparent;
    margin-right: -10px;
  }

  .eventsBorder {
    border-bottom: 1px solid $med-grey;
  }

  .nextpayday {
    width: 100%;
    background-color: $silver-two;
    padding: 0.5em;
    margin-top: 0px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .npLabel {
      width: 35%;

      font-family: $SegoeUI;
      font-size: 14px;
      font-weight: 600;
      color: $charcoal-grey;

      display: block;

      @media (min-width: $bp-phone-min) {
        font-size: 11px;
      }

      @media (min-width: $bp-tablet-min) {
        width: 30%;
      }

      @media (min-width: $bp-desktop) {
        width: 35%;
      }
    }

    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;

      @media (min-width: $bp-phone-min) {
        font-size: 10px;
        width: 70%;
      }

      @media (min-width: $bp-tablet-min) {
        font-size: 10px;
        width: 100%;
      }

      .npItem {
        font-size: 12px;
        color: $charcoal-grey;
        padding: 0 0.25em;

        display: block;

        @media (min-width: $bp-phone-min) {
          font-size: 10px;
          padding: 0;
        }

        @media (min-width: $bp-tablet-min) {
          padding: 0 0.25em;
        }

        &:first-child {
          font-weight: bold;
        }

        a {
          color: $charcoal-grey;

          padding: 0 0.5em;
          text-decoration: none;
          cursor: auto;
          outline: 0;

          @media (min-width: $bp-phone-min) {
            font-size: 10px;
            padding: 0;
          }

          @media (min-width: $bp-tablet-min) {
            padding: 0 0.5em;
          }
        }
      }

      &.npItemActive {
        font-weight: bold;
      }
    }
  }

  .eventList {
    padding-bottom: 6px;
    min-height: 228px;

    @media (max-width: $bp-phone-max-events) {
      padding-bottom: 0;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
      min-height: 286px;
    }

    @media (max-width: $bp-phone-max-events) and (orientation: landscape) {
      min-height: 316px;
    }

    ul li {
      border-bottom: 1px solid $silver-two;
    }

    ul li:last-child {
      border-bottom: solid 1px transparent;
    }

    @media (min-width: $bp-desktop) {
      ul li:hover {
        box-shadow: 0 6px 10px 3px rgba(188, 189, 188, 0.55);
        cursor: pointer;
      }
    }

    .eventWrapper {
      display: flex;
      width: 100%;
      height: 100px;

      border: none;
      padding: 0;
      background-color: transparent;

      @media (min-width: 667px) {
        height: 105px;
      }

      .eventCalendar {
        float: left;
        padding: 0.5em;
        height: 100%;

        .eventCalendarContent {
          width: 80px;
          height: 82px;
          border: solid 1px $accent-blue;
          text-align: center;

          .eventCalendarDay {
            font-size: 14px;
            font-weight: 600;
            color: $charcoal-grey;
          }

          .eventCalendarDate,
          .eventCalendarDateblue,
          .eventCalendarDateteal,
          .eventCalendarDateorange,
          .eventCalendarDatepurple {
            font-size: 38px;
            font-weight: bold;
            line-height: 1;
          }

          .eventCalendarDateblue {
            color: $accent-blue;
          }

          .eventCalendarDateteal {
            color: #279989;
          }

          .eventCalendarDateorange {
            color: #e85b16;
          }

          .eventCalendarDatepurple {
            color: #8e4ea6;
          }

          .eventCalendarMonth {
            font-size: 13px;
            font-weight: 300;
            color: $charcoal-grey;
          }
        }
      }

      .eventDetail {
        display: flex;
        padding: 0.5em;
        width: 100%;
        height: 100%;

        .eventDetailContent {
          position: relative;
          width: 100%;
          min-width: 150px;
          text-align: left;

          .eventDetailTitle {
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            color: $charcoal-grey;
            padding-bottom: 4px;
            text-transform: capitalize;
            overflow-y: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .eventDetailTime {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            color: $charcoal-grey;
          }

          .eventDetailOutlook {
            width: auto;
            font-family: $SegoeUI;
            font-size: 14px;
            font-weight: 300;
            color: $accent-blue;
            position: absolute;
            bottom: 0;
            display: inline-block;

            a {
              font-family: $SegoeUI;
              display: inline-block;

              &:hover,
              &:active,
              &:focus {
                font-weight: 600;
              }
            }

            .eventCalendarIcon {
              background-size: contain;
              background-repeat: no-repeat;
              width: 15px;
              height: 15px;
              height: auto;
              float: left;
              display: block;
            }

            .eventLinkText {
              font-family: $SegoeUI;
              font-size: 1em;
              padding: 0 6px;
              float: left;
            }
          }
        }
      }
    }
  }

  ul li:nth-child(2) .eventWrapper .eventCalendar .eventCalendarContent {
    border: solid 1px #279989;
  }

  ul li:nth-child(3) .eventWrapper .eventCalendar .eventCalendarContent {
    border: solid 1px #e85b16;
  }

  ul li:nth-child(4) .eventWrapper .eventCalendar .eventCalendarContent {
    border: solid 1px #8e4ea6;
  }

  ul li:nth-child(2) .eventWrapper .eventCalendar .eventCalendarContent .eventCalendarDate {
    color: #279989;
  }

  ul li:nth-child(3) .eventWrapper .eventCalendar .eventCalendarContent .eventCalendarDate {
    color: #e85b16;
  }

  ul li:nth-child(4) .eventWrapper .eventCalendar .eventCalendarContent .eventCalendarDate {
    color: #8e4ea6;
  }

  .noResultStyles {
    min-height: 418px;
    background-position: center 20px !important;

    p {
      min-height: 226px;
      padding-top: 144px;
      padding-left: 5%;
      padding-right: 5%;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      min-height: 200px;
      background-position: center 40px !important;

      p {
        min-height: 236px;
        padding-top: 156px;
      }
    }

    @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-phone-max) {
      min-height: 300px;

      p {
        min-height: 160px;
      }
    }

    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      min-height: 268px;

      p {
        min-height: 124px;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }

  .noResultBackgroundStyles {
    background-position: center 28px !important;
    min-height: 204px;

    p {
      min-height: 67px;
      padding-top: 145px;
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  .eventListWithNoBanner {
    min-height: 435px !important;

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
        min-height: 329px !important;
      }
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      min-height: 200px !important;
    }

    @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-phone-max) and (orientation: landscape) {
      min-height: 316px !important;
    }

  }

  .payDayFiller {
    height: 38px;
  }
}