@import "../../../assets/scss/index.module.scss";

.footerMenu {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  margin-top: 21px;
  width: 100%;
  justify-content: left;

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
  }

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    display: block;
    padding-left: 20px;
    margin-top: 5px;
  }

  .footerHeader {
    height: auto;
    font-family: $Gotham;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
    width: 140px;
    background-color: transparent;

    @media all and (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      width: 95%;
      border-bottom: 1px solid white;
    }
  }

  .footerLink {
    color: #ffffff;
    font-family: $Gotham;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    margin-right: 50px;
    text-decoration: none;
    &:hover {
        color: $hr-connect-accent;
        text-decoration: underline;
      }
    @media all and (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
      color: white;
    }
  }

  .footerItem {
    font-family: $Gotham;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #ffffff;
    margin-right: 50px;
    text-decoration: none;
    @media all and (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
      color: white;
    }
  }
}

@supports (-moz-appearance: none) {
  .footerMenu {
    margin-top: 2.5rem;
  }
  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    .footerMenu {
      margin-top: 0;
    }
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    .footerMenu {
      margin-top: 0;
    }
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
    .footerMenu {
      margin-top: 4%;
    }
  }
}
