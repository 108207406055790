@import "../../../assets/scss/index.module.scss";

.hrConnect {
  background-color: $hr-connect-background;
  grid-area: HrConnect;
  padding-top: 33px;
  font-family: $Gotham;
  margin-top: 35px;
  @include columnsAndRows(0, 5, 0, 5);

  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    @include columnsAndRows(0, 7, 3, 5);
  }

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    @include columnsAndRows(0, 11, 1, 9);
  }
}

.body {
  max-width: 1200px;
  margin: auto;
  @media screen and (max-width: 1260px) {
    width: 92.5% !important;
    font-size: 15px;
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    padding: 0 20px 40px 20px;
  }
}

.header {
  color: $hr-connect-accent;
  height: 41px;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  border-bottom: 2px solid $hr-connect-accent-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 36px;
  // position: relative;
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.title {
  color: $hr-connect-accent;
  &:hover {
    color: $hr-connect-accent;
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    font-size: 30px;
  }
}

.customize {
  position: absolute;
  bottom: 8px;
  right: 0;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  & span {
    align-self: flex-end;
  }
  & img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.widgets {
  color: $hr-connect-accent-2;
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;

  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    padding-left: 10px;
    padding-right: 10px;
    row-gap: 40px;
    align-items: center;
    margin-bottom: 54px;
  }

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    gap: 40px;
    margin-bottom: 70px;
  }
}

.widget {
  width: 32%;
  max-width: 359px;
  height: 248px;

  @media (min-width: $bp-phone-max-events-plus) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 47%;
    max-width: 437px;
  }
  @media (max-width: $bp-phone-max-events) {
    min-width: 100%;
  }
}

.linksWidget {
  display: none;
  @media (min-width: $bp-phone-max-events-plus) and (max-width: $bp-tablet-ipad-lanscape) {
    display: flex;
  }
}

.links {
  background-color: $hr-connect-accent-5;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .links_container{
    display: flex;
    justify-content: space-evenly;
    max-width: 1200px;
    @media (max-width: $bp-phone-max-events) {
      flex-direction: column;
      width: 100%;
    }
    @media (min-width: $bp-phone-max-events-plus) and (max-width: $bp-tablet-ipad-lanscape) {
      display: none;
    }
  }
}

.linkWrapper {
  min-height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  color: $hr-connect-accent-2;
  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    min-height: 80px;
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: $bp-phone-max-events) {
    width: 100%;
    min-height: 40px;
    justify-content: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  &:hover,
  &:focus {
    color: $hr-connect-accent-2;
    text-decoration: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
  }
}

.link {
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    cursor: pointer;
  }
}

.screenReaderOnly {
  position: absolute;
  overflow: hidden;
  left: -9999em;
  top: 0;
  width: 1px;
  height: 1px;
  color: transparent;
  background-color: transparent;
}
