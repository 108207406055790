@import "../../../assets/scss/index.module.scss";

.popularToolsItem{
  display:flex;
  flex:1;
  border: 1px solid $vibrant-blue;
  border-radius: 4px;
  padding: 10px 25px;
  // border: 1px solid $vibrant-blue;
  // border-radius: 4px;
  margin-left:30px;
  // color:$vibrant-blue;
  // font-size:18px;
  // font-weight:600;
  // padding: 0px 40px;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    margin-top:10px;
    padding-top:10px;
    padding-bottom: 10px;
    margin-left:0px;
  }
  // @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  //   padding: 10px;
  //   min-width: 40%;
  //   margin:10px 20px!important;
  //   }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      padding: 10px;
      min-width: 40%;
      // margin-right: 0px!important;
      // margin-left: 40px!important;
      margin: 10px 0px 10px 40px!important;
      }
  .popularToolsLink{
    display:flex;
    flex:1;
    color:$vibrant-blue;
    font-size:18px;
  font-weight:600;

  text-align: center;
  align-items: center;
  justify-content: center;
  }
}

