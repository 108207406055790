@import "../../../assets/scss/index.module.scss";

.aboutMe {
  width: 100%;
  text-align: center;
  font-family: $Gotham;
  max-height: 500px;
  overflow-y: auto;
  padding-top: 14px;

  /* phone */
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    overflow-y: initial;
    max-height: initial;
  }

  /* Tablet stuff */
  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    overflow-y: initial;
    max-height: initial;
  }

  .aboutMeTitleWrapper {
    font-family: $Gotham;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    text-align: left;

    .title {
      padding: 0;
      margin: 0;
      color: $charcoal;
      border: 0px solid grey;
      font-family: $Gotham;
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      line-height: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .editLink {
      color: $nw-blue;
      float: right;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .AboutMeCssGrid {
    color: $charcoal;
    text-align: left;

    h3 {
      margin-top: 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
    }

    .aboutMeParagraph {
      padding: 0 10px 10px 10px;
      overflow-y: auto;
    }

    .aboutMeZeroState {
      overflow-y: auto;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .twoColumn {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      justify-items: start;
      margin: 10px 10px 10px 10px;

      div:nth-child(1),
      div:nth-child(3),
      div:nth-child(5) {
        width: 100%;
        border-right: 1px solid $nw-grey;
        padding-right: 5px;
      }

      div:nth-child(2),
      div:nth-child(4) {
        padding-left: 20px;
      }

      .schools {
        p {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
        }
      }
    }

    .oneColumn {
      display: flex;
      flex-flow: column nowrap;
      padding: 0 10px 10px 10px;
    }
  }
}

.line{
  border-bottom: $charcoal 1px solid;
  width: 100%;
  margin-bottom: 17px;
}