/* COLORS */
$charcoal-Dark-gray: #747578;
$medium-blue: #1F74DB;
$accent-blue: #0072cf;
$nw-blue: #1c57a5;
$navy: #23527c;
$hr-connect-background: #141b4d;
$hr-connect-accent: #6eceb2;
$hr-connect-accent-2: #ffffff;
$hr-connect-accent-3: #307fe2;
$hr-connect-accent-4: #8cc8e9;
$hr-connect-accent-5: #0047bb;
$white: #ffffff;
$dark-min: #038574;
$nw-grey: grey;
$med-grey: #979797;
$metal-grey: #87888A;
$steel-grey: #87898b;
$page-bg-color: #fbfbfb;
$charcoal-grey: #4d4f53;
$light-burgundy: #9e2a2b;
$accent-green: #58c140;
$silver: #bcbdbc;
$silver-two: #e2e3e5;
$chat-blue: #5bc6e8;
$chat-purple: #8E4EA6;
$news-blue: #003b5c;
$accent-teal: #279989;
$fowc-pink: #C20E8E;
$sites-atoz-green: #3a913f;
$sites-atoz-blue: #0047bb;
$sites-atoz-black: #171717;
$footer-background: #141b4d;
//new redesign color
$vibrant-blue: #0047BB;
$mint: #6ECEB2;
$cherry-red: #CB333B;
$charcoal:#171717;
$dark-blue:#141B4D;
$charcoal-black:#231F20;
$ultralight-gray:#F3F3F3;
$pale-gray:#D0D3D4;
$ocean-green:#008775;
/* BREAKPOINTS */
$bp-mobile: 830px; //not sure about these values
$bp-mobile-min: 0; //not sure about these values
$bp-mobile-max: 834px; //not sure about these values

/* Verified the following values */
$bp-phone: 320px;
$bp-phone-min: 320px;
$bp-iphone-6-8: 415px;
$bp-phone-max: 767px;
$bp-ugly-phone-min: 0px;
$bp-ugly-phone-max: 319px;
$bp-phone-max-landscape: 815px;

$bp-tablet: 1023px;
$bp-tablet-min: 768px;
$bp-tablet-max: 1023px;
$bp-tablet-ipad-lanscape: 1024px;

$bp-desktop: 1024px;
$bp-desktop-min: 1024px;
$bp-desktop-max: 1440px;
$bp-ugly-desktop-min: 1441px;
$bp-ugly-desktop-max: 3000px;

$bp-phone-max-events: 667px;
$bp-phone-min-landscape-events: 667px;
$bp-phone-max-events-plus: 668px;

$fow-guage-size: 60px;
$fow-guage-size-2x: 120px;
