.fc .fc-list-table{
  border: 0px solid black;
}

.fc-list-event:focus-within {
  background-color: #bbb;
  border: 2px solid #171717;
}

.fc-button.fc-button-primary,
.fc-today-button.fc-button.fc-button-primary {
  background-color: #0047bb;
}

.fc-button.fc-button-primary.fc-button-active,
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button.fc-button-active {
  background-color: white;
  color: #0047bb;
  font-weight: bold;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.emptyDiv {
  height: 400px;
  width: 100%;
  clear: both;
}

.timeSpan {
  color: #171717;
  font-size: 12px;
  font-weight: normal;
  font-family: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

.fc-list-event-graphic {
  display: none;
}

.alignleft {
  color: #0047bb;
  float: left;
}

.alignright {
  color: #0047bb;
  float: right;
}

.calendarIcons {
  float: left;
}

.calendarEventTitle {
  padding: 0 7px;
  white-space: normal;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: normal;
}

.calendarEventDiv {
  text-align: left;
  margin-left: 5px;
  margin-right: 6px;
  height: 42px;
  overflow: hidden;
  width: 100%;
}
.calendarEventDot {
  vertical-align: top;
}

.fc-h-event{
  background-color:  #f3f3f3;
  border: 0px;
}

.calendarEventTitle,
.calendarEventDot {
  display: inline-block;
}

/* remove rounded corners */
.fc .fc-button {
  border-radius: 0px;
}

button.fc-today-button.fc-button.fc-button-primary:not([disabled]):hover {
  background-color: #1e2b37;
}
