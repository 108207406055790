.office365Images {
  height: 37px;
  width: 37px;
  border-radius: 50%;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: 2px solid white;
  }
}
