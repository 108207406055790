@import "../../../assets/scss/index.module.scss";

.tab {
    // width: 33%;
    height: 28px;
    text-align: center;
    
    a{
        padding: 10px 17px;
        @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
            padding: 0px 9px;
        }
    }
}

.tab:hover {
    height: 28px;
    text-align: center;
    border-bottom: 4px solid #0047bb;
    cursor: pointer;
}

.tabSelected {
    height: 28px;
    text-align: center;
    border-bottom: 4px solid #0047bb;
    text-decoration: none !important;
    
    a{
        padding: 10px 17px;
        @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
            padding: 0px 9px;
        }
    }
}

.tabLabel {
    font-family: $Gotham;
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #171717;
    text-decoration: none !important;
}

.tabLabelSelected {
    font-family: $Gotham;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0047bb;
    text-decoration: none !important;
}


@media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    .tab {
        // width: 33%;
        height: 28px;
        margin: 0px 10px !important;
        text-align: center;
    }

    .tab:hover {
        height: 28px;
        margin: 0px 10px !important;
        text-align: center;
        border-bottom: 4px solid #0047bb;
        cursor: pointer;
    }

    .tabSelected {
        height: 28px;
        margin: 0px 10px !important;
        text-align: center;
        border-bottom: 4px solid #0047bb;
        text-decoration: none !important;
    }

    .tabLabel {
        font-family: $Gotham;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #171717;
        text-decoration: none !important;

    }

    .tabLabelSelected {
        font-family: $Gotham;
        margin: 0px !important;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #0047bb;
        text-decoration: none !important;
    }

}