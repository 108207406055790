@import "../../../assets/scss/index.module.scss";
.popularToolsButtonWrapper {
  display: flex;
  flex: 1;
  margin: 0px!important;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    flex-wrap: wrap;
    // width: 75%;
    margin-left:40px!important;
    }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      flex-direction: column;
    }  
    li{
      list-style: none;
    } 
}