@import "../../../assets/scss/index.module.scss";

.story {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-height: 82px;
  margin-bottom: 13px;

  @media (min-width: 450px) and (max-width: $bp-phone-max-events) {
      grid-template-columns: 1fr 3fr;
    }
  
    @media (max-width: $bp-phone-max-events) {
    min-height: 55px;
    padding-top: 20px;
    margin-bottom: 0;
  }

    .imgLink {
    .thumbnail {
      width: 100%;
      height: 76px;
      border: 1px solid $silver;
    }
    .thumbnail:hover {
      opacity: 0.5;
    }

  }
  
  @media (max-width: $bp-phone-max) {
    gap: 14px;
  }

  .thumbnail {
    width: 100%;
    height: 76px;
    display: inline-block;
    vertical-align: top;
    object-fit: cover;
    border: 1px solid $silver;
  }

  .storyInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 14px;
    max-height: 76px;

    @media (max-width: $bp-phone-max) {
      padding: 0;
      position: relative;
    }

    .link {
      text-decoration: none;

      @media (max-width: $bp-phone-max) {
        display: block;
      }

      .heading:hover {
        color: $accent-blue;
      }
    }
  }
}

.heading {
  margin: 0;
  margin-top: -2.5px;
  font-size: 20px;
  font-family: $TiemposHeadline;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $charcoal;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}