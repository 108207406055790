.content {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.header-placeholder {
  width: 100vw;
  display: flex;
  align-items: center;
  height: 81px;
  background-color: #0047BB;
  border-bottom: 4px solid #6ECEB2;
  .skeleton-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      width: 100%;
      margin: auto;
  
      .profile-placeholder {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        gap: 20px;
      }
  
      .header-logo {
        flex-shrink: 0;
        margin: auto;
      }
  
      .date-placeholder {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
      }
        }
}

.megamenu-container {
  width: 100vw;
  height: 50px;
  border-bottom: solid 2px #e2e3e5;

  .megamenu-placeholder {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .megamenu-categories-placeholder {
      display: flex;
      gap: 25px;
      align-items: center;
    }

    .tools-placeholder {
      margin-top: -4px;
    }

  }

}

.searchbar-placeholder {
  width: 100%;
  max-width: 1200px;
  height: 50px;
  margin-top: 20px;
}

.news-placeholder {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  .skeleton-news {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .skeleton-snapshot {
      display: flex;
      gap: 6px;

      .skeleton-snapshot-more {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-top: 6px;
      }
    }
  }
}

.announcements-placeholder {
  padding: 10px;
  width: 100%;
  height: 372px;
  background-color: #fafafa;

  .announcement-placeholder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 2px solid #d0d3d4;
    }
  }
}

.events-otherNews-placeholder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;

  .events-placeholder {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .event-placeholder {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 2px solid #d0d3d4;
      padding-bottom: 6px;
    }
  }

  .otherNews-placeholder {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .otherNews-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 6px;
      border-bottom: 2px solid #d0d3d4;
    }

    .otherNews-snapshot {
      display: flex;
      gap: 8px;
      border-bottom: 2px solid #d0d3d4;
      padding-bottom: 6px;
    }
  }
}

@media (max-width: 1260px) {

  .content {
    width: 92.5%;
  }

  .header-placeholder {
    .skeleton-header {
       width: 92.5%;
    }
  }

  .megamenu-container {
    .megamenu-placeholder {
      width: 92.5%;
    }
  }

  .announcement-placeholder {
    width: 92.5%;
  }

}

@media (min-width: 667px) and (max-width: 1024px) {
  .header-placeholder {
    .skeleton-header {
      .profile-placeholder {
        display: none;
      }

      .date-placeholder {
        display: none;
      }
    }
    }
  .megamenu-container {
    display: none;
  }

  .searchbar-placeholder {
    margin-top: 0;
  }

  .news-placeholder {
    grid-template-columns: 1fr 1fr;

    .announcements-placeholder {
      grid-column: 1 / -1;
    }
  }

  .events-otherNews-placeholder {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 667px) {
  .header-placeholder {

    .skeleton-header {
      .profile-placeholder {
        display: none;
      }
  
      .date-placeholder {
        display: none;
      }
    }
  }

  .megamenu-container {
    display: none;
  }

  .searchbar-placeholder {
    margin-top: 0;
  }

  .news-placeholder {
    grid-template-columns: 1fr;

    .announcements-placeholder {
      grid-column: 1 / -1;
    }
  }

  .events-otherNews-placeholder {
    display: none;
  }
}