@import "../../../assets/scss/index.module.scss";

.error {
  padding: 5em 10% 21em;
  font-family: $SegoeUI;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  text-align: left;
  min-width: 70%;
  flex-grow: 0;
}

.body {
  text-align: left;
  width: 100%;
  padding-left: 5%;
  font-family: Arial, sans-serif;
}

.mainMessage {
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: $charcoal-grey;
}

h2 {
  font-size: 42px;
  font-weight: bold;
}