@import "../../../assets/scss/index.module.scss";

.header {
  width: 430px;
  height: 41px;

  h1{
    font-family: $Gotham;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    color: $charcoal;
  }
}

.line {
  height: 1px;
  margin: 0;
  background-color: $charcoal;
  margin-bottom: 12px;
}

.feedbackLink {text-align: right;
  font-family: $Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $sites-atoz-blue !important;
}

.feedbackLink:hover {
  color: #0047bb;
}

.letterNav {
  height: 48px;
  text-align: center;
  font-size: 28px;
  position: sticky !important;
  top: 130px;
  background-color: white;
  display:flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top:27px;
}

.letterInnerWrap {
  max-width: 1115px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.tableWrapper {
  width: 100%;
  margin-top: 30px;

  table {
    max-width: 1120px;
    margin: auto;
  }
}

.filterSearchWrap {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  font-family: $Gotham;

  label {
    color: $charcoal;
    font-family: $Gotham;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

    input {
      border-color: $charcoal;
      height: 50px;
      border-radius: 5;
      margin: 0;
      margin-left: 15px;
      width: 265px;
      
      @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
        width: 206px;
      }
    }
  }

}

.filterTabWrap {
  display: flex;
  width: 460px;
  justify-content: space-between;
}

.spacingBlock {
  width: 75% !important;
  max-width: 1250px;
  margin: auto;
}

.filterView {
  display: flex;
  margin: auto;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 1120px;
  width: 100%;
  height: 50px;

  div{
    align-items: center;
  }
}
.aToZViewTitle {
  max-width: 1120px;
  margin: auto;
  width: 100%;
}

.aToZView {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 35px;
  text-align: left;
  table {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      // table-layout: fixed;
    }
  
    thead {
      width: 100%;
    }
  
    tr {
      display: flex;
      flex-direction: row;
    }
  
    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
}

.letterNavMob {
  width: 100%;
  text-align: center;
  font-size: 28px;
  position: sticky !important;
  top: 100px !important;
  // z-index: 99 !important;
  background-color: #ffffff;
  display: none;
}




.starHeader {
  flex: 0.05;
  min-width: 40px;
  padding-right: 2px;
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    padding-left: 13px;
  }
  background-color: #eeeeee;
}

.dragAndDropIconHeader {
  width: 26px;
  background-color: #eeeeee;
}

.nameHeader {
  flex: 0.35;
  width: 380px;
  padding: 10px;
  font-family: $Gotham;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: $sites-atoz-black;
  background-color: #eeeeee;

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    flex: 0.95;
  }
}

.noMatch {
  font-family: $Gotham;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: $sites-atoz-black;
}



.descriptionHeader {
  flex: 0.60;
  padding: 10px;
  width: 613px;
  font-family: $Gotham;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: $sites-atoz-black;
  background-color: #eeeeee;
}

.nameHeaderForRecentlyVisited{
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    flex:1 !important;
  }
}

.descriptionHeaderForRecentlyVisited{
  flex: 0.65;
}

.siteLink {
  color: $sites-atoz-black;

  @media (max-width: $bp-tablet-ipad-lanscape) {
    font-size: 14px;
  }
}

.siteLink:hover {
  color: $sites-atoz-blue;
}

.sortIconDown {
  width: 21.2px!important;
  height: 21.2px!important;
  margin: 0 0 5.9px 10.4px;
  object-fit: contain;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.sortIconUp {
  transform: rotate(-180deg) translateY(-10.5px);
  width: 21.2px!important;
  height: 21.2px!important;
  margin: 0 0 5.9px 10.4px;
  object-fit: contain;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-transform: rotate(-180deg) translateY(-10.5px);
  /* IE 9 */
}

.dropBtn {
  color: #0047bb;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropBtn:hover,
.dropBtn:focus {
  background-color: #3e8e41;
}

.atozSelect {
  float: left;
  overflow: hidden;
  width: 30%;
  font-family: $Gotham;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #0047bb;
}

.atozSelect a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.name {
  font-family: $Gotham;
  font-size: 24px;
  margin-bottom: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.filterTab {
  // width: 30% !important;
  padding: 0px 10px !important;
}

.clearFloat {
  clear: both;
}

//mobile view
@media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {

  .letterInnerWrap {
    // display:inline-flex;
    // width: 100%;
    justify-content: space-around;
  }

  .letterInnerWrap>a {
    display: inline-block;
    flex: 1;
  }

  // .aToZView {
  //   width: 100%;
  //   overflow: show;
  //   }
  .aToZView {
    width: 100%;
    overflow: show;
    font-size: 14px !important;
  }

  .letterNavMob {
    width: 100%;
    text-align: center;
    font-size: 28px;
    position: sticky !important;
    top: 100px !important;
    // z-index: 99 !important;
    background-color: #ffffff;
    display: block
  }

  .letterNav {
    // width: 100%;
    text-align: center;
    font-size: 28px;
    position: sticky !important;
    top: 100px !important;
    // z-index: 99 !important;
    background-color: #ffffff;
    display: none;
    padding-left: 10px;
    padding-right: 10px;
  }

  .atoZNavMob {
    display: block;
    overflow: hidden;
    background-color: #fff;
    border-top: 1px solid #0047bb;
    border-bottom: 4px solid #0047bb;
    position: sticky !important;
  }

  .descriptionHeader {
    display: none;
  }

  .nameHeader {
    width: 30px !important;
    font-size: 18px;
  }

  .filterTab {
    //  width: 30% !important;
    padding: 0px !important;
  }

  .filterView {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100%;
  }

  .filterSearchWrap {
    display: inline-block;
    font-family: $Gotham;

    label {
      width: 100%;

      input{
        width: 100%;
        margin: 0;
        margin-top: 8px;
      }
    }
  }
  .filterTabWrap {
    width: 100%;
    }
  .spacingBlock{
    width: 90% !important; 
      // max-width: 1250px;
      margin: auto;
  }

  .aToZViewTitle {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tableWrapper {
    margin: 0;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100% !important;

  }
}

//tablet view ??
@media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {


  .letterInnerWrap {
    justify-content: space-between;
  }

  .tableWrapper {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .letterNav {
    padding-left: 10px;
    padding-right: 10px;
    top: 0px;
  }

  .aToZViewTitle {
    padding-left: 10px;
    padding-right: 10px;
  }

  .filterView {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .filterSearchWrap {
    min-width: 355px;
  }

  .descriptionHeader {
    width: 170px;
  }

  .nameHeader {
    width: 40px;
  }

  .aToZView {
    width: 100%;
    overflow: hidden;
    tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}