@import "../../../assets/scss/index.module.scss";
.text {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wrap {
    overflow-y: hidden;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: $sites-atoz-black;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    &:hover{
      color: $sites-atoz-blue;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
        -webkit-line-clamp: 1;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      white-space: nowrap;
      display: inline-block;

    }
  }

  .descriptionWrap {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $sites-atoz-black;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;

    @media (max-width: $bp-tablet-max) {
        -webkit-line-clamp: 1;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      white-space: nowrap;
      display: inline-block;

    }
  }
  
  .expanded {
    color: $sites-atoz-black;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
  }

  .descriptionExpanded {
    width: 100%;
    color: $sites-atoz-black;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
  }
  
  // table {
  //   width: 75%;
  //   text-align: center;
  // }

  // td {
  //   padding: 10px;
  //   // max-width: 30%;
    
  // }
  .atozLink {
    
    tr{
      display: flex;
      flex-direction: row;
    }
    
    td {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    td:nth-child(1) {
      flex: 0.05;
      width: 40px;
      padding-right: 2px;
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        padding-left: 13px;
      }
    }
  
    td:nth-child(2) {
      flex: 0.35;
      width: 380px;
      align-items: stretch;
      padding: 10px;
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        flex: 0.95;
      }
    }
  
    td:nth-child(3) {
      flex: 0.60;
      width: 613px;
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        flex: 0.65;
      }
    }
  }

  .row:nth-child(even) {
    text-align: left;
    background-color: rgb(238, 238, 238);
  }
  
  .row:nth-child(odd) {
    text-align: left;
    background-color: white;
  }

  .row_favorite:nth-child(odd) {
    text-align: left;
    background-color: white;
  }
  
  .row_favorite:nth-child(even) {
    text-align: left;
    background-color: rgb(238, 238, 238);
  }
  .name {
    font-family: $Gotham;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      width: 100%;
      font-size: 12px !important;
    }

    @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
      width: 40px;
    }
  }
  
  
  .description {
    width: 100%;
    font-family: $Gotham;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: $sites-atoz-black;
    padding-left: 10px;
    padding-right: 10px;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    display: none;
    }

    @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
      width: 170px;
    }
  }

  .descriptionFieldForRecentlyVisited {
    flex: 0.65 !important;
  }

  .nameFieldRecentlyVisited{
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      flex:1 !important;
    }
  }

  .atoZlinkText{
    width: 100%;
    font-family: $Gotham;
    font-size: 18px;
  }
  
  .dragAndDropIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    img{
      width: 26px;
      height: 21px;
    }
  }