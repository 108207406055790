@import "../../../assets/scss/index.module.scss";

.footerWrapper {
  font-weight: bold;
  width: 100%;
  background-color: $footer-background;
  border-top: 2px solid $hr-connect-accent-2;

  .footerMenu {
    text-align: left;
  }

  /* PHONE */
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    .feedbackHelp {
      order: 2;
      height: 100px;
      background-color: transparent;
      width: 45%;
    }
  }

  /* TABLET */
  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    .footerMenu {
        position: relative;
        top: 0px;
        background-color: transparent;
        height: auto;
        width: 100%;
        padding-left: 30px;
    }
  }

  /* DESKTOP */
  @media (min-width: $bp-desktop-min) {
     .footerMenu {
      background-color: transparent;
      height: auto;
    }
    .footerMenuWrapper {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
        @media (max-width: 1260px) {
            width: 92.5% !important;
          }
    }
    .copyrightBox{
      display: flex;
        max-width: 1200px;
        margin: auto;
        @media (max-width: 1260px) {
            width: 92.5% !important;
          }
    }
        .footerSystemAdminWrapper {
        width: 150px;
        height: auto;
        padding-right: 1px;
    }
  }
}

/* we tell all items to be 100% width via flex-basis */
.footerWrapper > * {
   padding: 5px;
}
