@import "../../../assets/scss/index.module.scss";

.customizeTools {
  font-family: $SegoeUI;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $accent-blue;
  text-align: right;
  position: relative;
  left: 12px;
  

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    max-width: 100%;
  }

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    margin-top: -29px;
    margin-right: -11px;
    left: 0;
  }
}