@import "../../../assets/scss/index.module.scss";
.eventDetailOutlook {
  width: auto; // 100%;
  font-size: 14px;
  font-weight: 300;
  color: $accent-blue;
  position: absolute;
  bottom: 6px;
  display: inline-block; // flex;
  border: none;
  background-color: transparent;
  padding: 0;

  a {
    display: inline-block; // flex;

    &:hover,
    &:active,
    &:focus {
      font-weight: 600;
    }
  }
  .eventCalendarIcon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    float: left;
    margin-top: 1px;
  }
  .eventLinkText {
    float: left;
    width: auto;
    height: 19px;
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--accent-blue);
    padding-left: 5px;

    &:hover,
    &:active,
    &:focus {
      font-weight: 600;
    }
  }
  @media (min-width: 0px) and (max-width: $bp-tablet-ipad-lanscape) {
    display: none;
  }
}