@import "../../../assets/scss/index.module.scss";

.toolsNavigationLink{
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
   width:100%;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) { 
    width:100%;
    }
  button {
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      z-index: 0;
      box-shadow: 0px 8px 19px #00000040;
  }
}
}
.mobileOnlyView{
  display: none;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) { 
    display: inline-block;
    }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      display: none !important;
     }
}
.searchIcon{
  fill:$dark-blue;
  width:28px;
  height:28px;
}
.toolsDropDownOpen{
  width:110px;
  height:60px;
  z-index: 999;
  text-decoration: none!important;
  border: 0!important;
    background: $dark-blue;
    stroke: $mint!important;
    color: $mint!important;
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      display: none;
    }
    .toolsIcon{
      stroke:$mint;
      width:19px;
      height:19px;
    }
    .toolsDropDownLink{
      color: $mint;
    }
    .toolsArrowIcon{
      stroke: $mint;
    }
  
}
.toolsDropDown{
  width:110px;
  height:60px;
  background: $mint;
  z-index: 1;
  text-decoration: none!important;
  border: 0!important;
  // outline:none!important;
  stroke: $dark-blue;
  color: $dark-blue;
  position: absolute;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    display: flex;
    width:100%;
    height:60px;
    padding-left: 20px;
    padding-top: 15px;
  }
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width:100%;
    display: flex;
    padding-left: 15px;
    align-items: center;
    padding-right: 5px;
   }
  .toolsIcon{
  width:19px;
  height:19px;
  }
  &:hover{
    background: $dark-blue;
    stroke: $mint!important;
    color: $mint!important;
    .toolsIcon{
      stroke:$mint;
    }
    .toolsDropDownLink{
      color: $mint;
    }
    .toolsArrowIcon{
      stroke: $mint;
      @media only screen and (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
        border-bottom: 4px solid $mint;
        border-right: 4px solid $mint;
      }
    }
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    position: relative;
  }
}
.toolsDropDownIcon{
  justify-content: center;
  text-align: center;
}

.toolsDropDownLink{
  text-align: center;
  display: flex;
  justify-content: center;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: start;
   }
   @media (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    width: 100%;
    text-align: start;
    justify-content: space-between;
  }
  span{
    font-weight: 600;
    font-family: $Gotham;
    font-size: 18px;
    @media (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      display: inline-block;
      padding-left: 10px;
      width: 100%;
    }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      display: inline-block;
      padding-left: 10px;
      width: 95%;
     }
  }
}

.toolsArrowIconContainer {
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    // width: 7%;
    margin-right: 20px;
  }
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
   width: 0%;
   }
}
.toolsArrowIcon{
  margin:0px 5px;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    transform: rotate(-45deg);
    height: 12px;
    width: 12px;
    border-bottom: 4px solid #141B4D;
    border-right: 4px solid #141B4D;
    margin: 0px 0px;
  }
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    transform: rotate(-45deg);
    height: 12px;
    width: 12px;
    border-bottom: 4px solid #141B4D;
    border-right: 4px solid #141B4D;
    margin-left: 6px;
   }
   path{
    @media (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
      display: none !important;
     }
   }
}

.toolsDropDownContent {
  position: absolute;
  top: 50px;
  flex: 1;
  left: 0px;
  width: 100%;
  padding: 0px 15px;
  z-index: 999;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 19px #00000040;
  border: 1px solid #D8D9DA;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    top: 0px;
    padding: 0px 20px;
  }
  @media (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
    top: 0px!important;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto;
   }
}
.toolsDropDownContentInnerWrap{
  max-width: 1200px;
  display: flex;
  margin: auto;
  flex-direction: column;
  padding-bottom: 40px;
@media (max-width: 1260px) {
    width: 95.5% !important;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    width:100%;

      }
}

.popularToolsWrapper{
  display: flex;
  flex-direction: row;
  padding: 45px 0px 32px 0px;
  border-bottom: 1px solid $pale-gray;
  margin-bottom: 32px;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  padding: 25px 20px 10px 20px;
  margin-bottom: 40px;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    border-bottom: none;
}
}

.toolsSubCategoryWrapper{
  display: flex;
  flex-direction: row;
  padding: 45px 0px 32px 0px;
  border-bottom: 1px solid $pale-gray;
  margin-bottom: 32px;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  padding: 25px 0px 10px 0px;
  margin-bottom: 40px;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    flex-direction: column;
    padding: 0px;
    border-bottom: none;
 }
}
.toolsNavigationPrevButtonWrapper{
  display:none;
  max-width: 728px!important;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    display:flex;
    color:$charcoal-black;
    font-weight:600;
    width: 100%;
    max-width: $bp-tablet-ipad-lanscape!important;
    margin-top: 10px;
    border-bottom: 1px solid $pale-gray;
    padding: 8px 20px!important;
    .toolIcon{
      margin:0px 10px 0px 5px;
      stroke:$vibrant-blue;
      width:18px;
      height:18px;
    }
    .navigationBackIcon{
      fill: $dark-blue;
      margin:0px 5px 0px 0px;
      width:12px;
      height:18px;
    }
    .navBackArrowWrapper{
      display:flex;
      align-items: center;
    }
    .toolIconWrapper{
      display:flex;
      align-items: center;
    }
    .toolsNavTitle{
      display:flex;
      width:90%;
      align-items: center;
    }
    .toolsNavTitleText{
        color:$dark-blue;
        font-size: 18px;
        font-family: $Gotham;
        font-weight: bold;
    }
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    display:flex;
    font-weight:600;
    width: 100%;
    max-width: $bp-tablet-ipad-lanscape!important;
    border-bottom: 1px solid $pale-gray;
    padding: 8px 0px!important;
    .toolIcon{
      margin:0px 10px 0px 5px;
      width:18px;
      height:18px;
    }
    .navigationBackIcon{
      margin:0px 5px 0px 0px;
      width:12px;
      height:18px;
    }
    .navBackArrowWrapper{
      display:flex;
      align-items: center;
    }
    .toolIconWrapper{
      display:flex;
      align-items: center;
    }
    .toolsNavTitle{
      display:flex;
      width:90%;
      align-items: center;
    }
    .toolsNavTitleText{
        font-size: 18px;
        font-family: $Gotham;
        font-weight: bold;
    }
  }
}
.popularToolsLabelWrapper{
  display: flex;
  flex:1;
  align-items: center;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    align-items: flex-start;
    margin-top:10px;
    }
}
.popularToolsLabel{
  display:flex;
  color:$charcoal-black;
  font-weight:600;
  // margin-right: 40px;
  .navigationBackIcon{
    fill: $dark-blue;
    margin:0px 5px 0px 0px;
    width:12px;
    height:18px;
  }
  .toolsIcon{
    margin:0px 10px 0px 5px;
    stroke:$vibrant-blue;
    width:18px;
    height:18px;
  }
  .popularToolsTitle{
    font-size:22px;
    font-family: $Gotham;
    font-weight: bold;
    color: $charcoal-black;
    margin-bottom: 0px!important;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
   border-bottom: 1px solid $pale-gray;
   padding-top: 10px;
   padding-bottom: 10px;
  //  margin-left: 20px;
  //  margin-right: 20px;
   .popularToolsTitle{
    font-size:18px;
    color:$dark-blue;
    font-weight: bold;
  }
 }
 @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  // width: 20%;
  }
}


.myToolsLabel{
  display:none;
  color:$charcoal-black;
  font-weight:600;
  margin-right: 10px;
  margin-bottom: 35px;
  .navigationBackIcon{
    fill: $dark-blue;
    margin:0px 5px 0px 0px;
    width:12px;
    height:18px;
  }
  .toolsIcon{
    margin:0px 10px 0px 5px;
    stroke:$vibrant-blue;
    width:18px;
    height:18px;
  }

  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
   border-bottom: 1px solid $pale-gray;
   padding-bottom: 10px;
 }

}
.popularToolsTitle{
  font-size:22px; 
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    margin-left: 20px !important;
    font-size:18px;
    color:$dark-blue;
    font-weight: bold;
  }
}
.myToolsAtoZWrapper{
  display:flex;
  flex: 1;
  flex-direction: row;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) { 
  flex-direction: column;
  }

  .myToolsWrapper{
    flex:1;
    margin-right: 100px;
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      margin-right: 80px;
      width:40%;
      padding-left: 20px;
      }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    margin-right: 0px;
    padding: 20px 20px 10px 20px;
      }
  }
  .myAtoZFavWrapper{
    flex:1;
    margin-left: 100px;
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      margin-left: 0px;
      width:40%;
      padding-right: 20px;
      }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      margin-left: 0px;
      padding: 20px 20px 10px 20px;
        }
  }
}

.mintStroke{
  stroke: $mint;
}
.darkBlueStroke{
  stroke: $dark-blue;
}
.vibrantBlueStroke{
  stroke: $vibrant-blue;
}
.mintFill{
  fill: $mint;
}
.darkBlueFill{
  fill: $dark-blue;
}
.vibrantBlueFill{
  fill: $vibrant-blue;
}