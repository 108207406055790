@import "../../../assets/scss/index.module.scss";
@import "../../../assets//scss/fonts.module.scss";

//popup styles
.whatschanged_modal {
  position: fixed;
  z-index: 20000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 8%;
}



.modal_header {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}

.modal_content {
  background: #FFFFFF;
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 900px;
  min-height: 250px;
  height: auto;
  border: 1px #FFFFFF;
  flex-direction: column;
  display: flex;
  padding: 30px;
  box-shadow: 0px 8px 19px #00000040;
 
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    padding: 5px;
    }


  .close_button_wrapper {
    position: absolute;
    border: none;
    background: none;
    .close_button{
      color:$charcoal-black;
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        width: 25px;
        height: 25px;
        }
    }
  }

  .modal_body {
    display: flex;
    height: auto;
    flex-direction: column;

   
    .modal_main_body{
      display: flex;
      height: auto;
      flex-direction: row;
    }

    .modal_prev_arrow_wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .prev_button{
        border:none;
        background: none;
        .prev_button_arrow{
          fill:$pale-gray;
          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
            width: 25px;
            height: 25px;
            }
        }
        .prev_button_arrow_active{
          fill:$vibrant-blue;
          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
            width: 25px;
            height: 25px;
            }
        }
      }
    }

    .modal_next_arrow_wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      .next_button_wrapper {
        .next_button{
          border:none;
          background: none;
          .next_button_arrow{
            fill:$pale-gray;
            @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
              width: 25px;
              height: 25px;
              }
          }
          .next_button_arrow_active{
            fill:$vibrant-blue;
            @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
              width: 25px;
              height: 25px;
              }
          
          }
        }
      }
    }

    .modal_main_content_wrapper {
      flex: 6;
      display: flex;
      flex-direction: column;

      .title_wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    
        h2 {
          color: $vibrant-blue;
          font-size: 32px;
          font-weight: 600;
          font-family: $Gotham;
        }
      }
      .description_wrapper {
        flex: 2;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .description_text {
          font-size: 20px;
          font-family: $Gotham;
          text-align: center;
          color: $charcoal-black;
          font-weight: 500;
          div{
            font-size: 20px!important;
            font-family: $Gotham!important;
            text-align: center;
            color: $charcoal-black!important;
          }
        }
      }

      .image_wrapper {
        flex: 3;
        display: flex;
        justify-content: center;

        .feature_image {
          margin: 40px 10px 35px 10px;
          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
          margin: 5px;
            }
        
            @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
          margin: 5px;
            }
        }
      }

      .button_wrapper {
        flex: 1;
        display: flex;
        justify-content: center;

        .button {
          background: $mint;
          min-width: 124px;
          min-height: 35px;
          font-weight: 500;
          font-family: $Gotham;
          font-size: 18px;
          color: $dark-blue;
          border: 1px solid $mint;
          text-align: center;
          padding:8px 10px 5px 10px;
          margin-right: 15px;
          text-decoration: none;
        }
        
      .remind_me_later{
        background: none;
        font-size: 18px;
        font-weight: 500;
        font-family: $Gotham;
        text-align: center;
        color:$vibrant-blue;
        padding:8px 10px 5px 10px;
        border:1px solid $vibrant-blue;
        margin-left: 15px;
      }
      }
      .slide_count_wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-bottom: 5px;
        margin-top: 30px;

        .slide_bubble {
          width: 25px;
          height: 25px;
          background: $pale-gray;
          border-radius: 15px;
          margin-right: 10px;
          border: none;
          
          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
            width: 16px;
            height: 16px;
            }
        
        }

        .slide_bubble_active {
          width: 25px;
          height: 25px;
          background: $vibrant-blue;
          border-radius: 15px;
          margin-right: 10px;
          border: none;
          
          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
            width: 16px;
            height: 16px;
            }
        
          
        }
      }
    }
  }

}