@import "../../../assets/scss/index.module.scss";

.orgChart {
  width: 100%;
  text-align: center;
  font-family: $SegoeUI;
  max-height: 800px;

  /* Phone stuff */
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    max-height: fit-content;
  }

 .expandedOrgChart {
    margin-left: 160px;
    text-align: right;
    display: inline-block;
    color: $accent-blue;
    font-size: 12px;
    font-family: $SegoeUI;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
              
    .expandedOrgChartLink {
       float: right;
       color: inherit;      
    }
 }
 
  .orgTitleWrapper {
    font-family: $SegoeUI;
    padding: 10px 0px;
    margin: 10px;
    display: inherit;
    background-color: transparent;
    border-bottom: $med-grey 1px solid;
    text-align: left;

    .title {
      color: $steel-grey;
      display: inline-block;
      border: 0px solid grey;
      height: 27px;
      font-family: $SegoeUI;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-overflow: ellipsis;
      width: calc(100% - 120px);
      overflow: hidden;
      white-space: nowrap;
    }

    .viewWorkGroupLink {
      color: $accent-blue;
      padding-top: 5px;
      float: right;
      min-width: 90px;
    }
  }

  .orgChart {
    .circle {
      position: relative;
      border: 2px solid #999;
      border-radius: 100%;
      width: 50px;
      line-height: 50px;
      text-align: center;
      margin-top: 50px;
      background-color: #fff;
      z-index: 2;
    }
    .circle:first-child {
      margin-top: 0;
    }
    .circle:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      border: 1px dotted;
      border-width: 0 0 0 1px;
      width: 1px;
      height: 100px;
    }
  }

  .workGroup {
    background-color: transparent;
    border-right: 1px solid #fff;
    padding-right: 2%;
    padding-left: 2%;
    margin-bottom: 2rem;
    height: auto;
    list-style: none!important;
    margin-left: 21px;

    li,
    .line {
      display: block;
      text-align: left;
      height: 45px;
      background-color: transparent;
      position: relative;
      margin-top: 7px;
      list-style: none!important;
    }
  }

  ul:last-child {
    border-right: none;
    list-style: none!important;
  }
}
