@import "../../../assets/scss/index.module.scss";

.copyright {
  font-family: $Gotham;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  height: auto;

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    width: initial;
    text-align: left;
    margin-left: 0;
    padding-left: 30px;
  }

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    width: 88%;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    padding-bottom: 6px;
  }

}
