@import "../../../assets/scss/index.module.scss";

.featuredStory {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  min-height: 407px;
  max-height: 407px;
  
  @media (min-width: $bp-phone) and (max-width: $bp-phone-max) {
    min-height: 320px;
  }

  .header {
    display: none;
  }

  .featuredImage {
    height: 226px;
    width: 100%;
    object-fit: cover;
    border: 1px solid $silver;
  }

  .imgLink {
    width: 100%;

    .featuredImage:hover {
      opacity: 0.7;
    }
  }

    .link {
      text-decoration: none;
      width: 100%;
    }

    .heading {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      line-height: normal;
      color: $charcoal;
      margin: 0;
      font-family: $TiemposHeadline;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word !important;
      word-wrap: break-word !important;
    }

    .heading:hover {
      color: $vibrant-blue;
    }

  .storySummary {
    font-family: $Gotham;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 23px;
    color: $charcoal;
    margin: 0;
    width: 100%;
    min-height: 92px;
    padding: 0;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
  }
}

.noBorder {
  @media (max-width: ($bp-phone-max-events)-1) {
    border-bottom: none;
    margin-bottom: -30px;
  }
}
