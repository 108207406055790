@import "../../../../../assets/scss/index.module.scss";

$guage-size: 60px;
$guage-width: 20px;

.guageSection {
  padding-top: 10px;
  position: relative;
  margin: 0px auto !important;
  min-height: 0px !important;
  margin-bottom: 20px !important;

  button{
    top:11px
  }
}

.gaugeContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.guage {
  position: relative;
  overflow: hidden !important;
  margin: 0px !important;
  padding: 0px !important;
  background: $hr-connect-accent-2;
  width: calc(2 * #{$guage-size});
  height: $guage-size;
  border-radius: calc(2 * #{$guage-size}) calc(2 * #{$guage-size}) 0px 0px;
  text-align: center;
}

.dial {
  position: absolute;
  margin: 0px !important;
  padding: 0px !important;
  top: $guage-size;
  left: -200%;
  width: 400%;
  height: 400%;
  background: $hr-connect-accent;
  margin-left: $guage-size !important;
  transform-origin: top center;
}

.guageMask {
  background: $hr-connect-background;
  height: calc(#{$guage-size} - #{$guage-width}) !important;
  width: calc(2 * #{$guage-size} - 2 * #{$guage-width});
  margin: 0px auto !important;
  border-radius: calc(2 * #{$guage-size} - 2 * #{$guage-width}) calc(2 * #{$guage-size} - 2 * #{$guage-width}) 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.showHide{
  top:25px;
  @media screen and (min-width: 1921px){
    right: -200px !important;
  }
  @media screen and (max-width: 1920px) and (min-width:1800px){
    right: -120px !important;
  }
  @media screen and (max-width: 1801px) and (min-width:1660px){
    right: -110px !important;
  }
  @media screen and (max-width: 1661px) and (min-width: 1520px){
    right: -95px !important;
  }
  @media screen and (max-width: 1521px) and (min-width: 1380px){
    right: -75px !important;
  }
  @media screen and (max-width: 1381px) and (min-width: 1200px){
    right: -60px !important;
  }
  @media screen and (max-width: 1201px) and (min-width: 1025px){
    right: -75px !important;
  }
  @media screen and (max-width: 1026px) and (min-width:900px){
    right: -120px !important;
  }
  @media screen and (max-width: 901px) and (min-width:721px){
    right: -75px !important;
  }
  @media screen and (max-width: 720px) and (min-width:670px){
    right: -70px !important;
  }
  @media screen and (max-width: 671px) and (min-width:610px){
    right: -200px !important;
  }
  @media screen and (max-width: 611px) and (min-width:575px){
    right: -180px !important;
  }
  @media screen and (max-width: 576px) and (min-width:475px){
    right: -160px !important;
  }
  @media screen and (max-width: 476px) and (min-width: 410px){
    right: -100px !important;
  }
  @media screen and (max-width: 411px){
    right: -80px !important
  }
}

.guageText {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  bottom: -18px;
  font-size: 18px;
  font-weight: bold;
  left: 0;
  right: 0;
}

.meters {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 80%;
}

.meter {
  background-color: $hr-connect-accent-2;
  height: 5px;
  margin-top: 0px !important;
  font-size: 16px;
  margin-bottom: 5px !important;
}

.meterComplete {
  height: 5px;
  margin: 0 !important;
  background-color: $hr-connect-accent;
}

.meterLabel {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  cursor: default!important;
  height: 18px;
  & .meterText {
    font-size:16px;
    position: absolute;
    left: 0;
    font-weight: 400;
    line-height: normal;
  }
  & .meterPercent {
    position: absolute;
    right: 0;
  }
}