@import "../../../assets/scss/index.module.scss";

.announcement {
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  padding: 4px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $pale-gray;
  }

  .link {
    color: inherit;
  }

  .link:hover {
    color: $accent-blue;
    text-decoration: none;
  }

  .text {
    margin-bottom: 0;
    font-size: 18px;
    font-family: $Gotham;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
      -webkit-line-clamp: 3; // Number of lines to show
    -webkit-box-orient: vertical;

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      /*
      For desktop you can hover and see the entire text, 
      for mobile and tablet you can't, so we disable line clamp.
      */
      overflow: visible;
      text-overflow: clip;
      display: block;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
    }
  }

  .closeButton {
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      display: none;
    }
  }
}