@import "../../../assets/scss/index.module.scss";

.SystemAdmin {
  display: flex;
    justify-content: flex-end;
    margin-right: 10px !important;
  position: relative;
  color: white;
  background-color: transparent;
  margin-top: 26px;
  width: 100%;


  @media (max-width: $bp-tablet-ipad-lanscape) {
    display: none;
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    display: none;
  }

  .screenReaderOnly {
    position: absolute;
    overflow: hidden;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    color: transparent;
    background-color: transparent;
  }

  .adminBox {
    position: absolute;
    background-color: transparent;
    width: 80%;
    text-align: right;
    z-index: 1;
    padding-right: 0px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding-right: 15px;
      margin-top: 7px;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: none;
    }

    .adminText {
      width: 41px;
      height: 19px;
      font-family: $SegoeUI;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: -1px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-left: -2px;
        margin-top: -2px;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
        /* Retina-specific stuff here, put your CSS sprite stuff here for Retina */
        margin-top: 0%;
        margin-left: -6%;
      }
    }

    .adminButton {
      text-align: center;
      width: 53px;
      height: 22px;
      border-radius: 4px;
      border: solid 1px #ffffff;
      background-color: transparent;
      margin-right: 3px;
      cursor: pointer;
    }

    .clearStorageButton {
      cursor: pointer;
      text-align: center;
      height: 22px;
      border-radius: 4px;
      border: solid 1px #ffffff;
      background-color: transparent;
      font-family: $SegoeUI;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 5px;
      padding-top: 0;
      margin-bottom: 7px;
    }
  }

  .adminBoxHidden {
    display: none;
    position: absolute;
    background-color: transparent;
    width: 80%;
    text-align: right;
    z-index: 1;
    padding-right: 30px;
  }
}
