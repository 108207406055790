@import "../../../../../assets/scss/index.module.scss";

.container {
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title {
      text-decoration: none;
      font-family: $Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #fff;

      &:hover,
      &:focus {
        color: $mint;
        text-decoration: none;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

  }
}