@import "../../../assets/scss/index.module.scss";

.column {
    height: 60px;
    padding-bottom: 46px;
    text-align: center;
}

// td {
//     padding-left: auto;
//     padding-right: auto;
// }

p {  
    padding-left: 3px;
    padding-right: 3px;
}

.enabled {
    text-transform: uppercase;
    font-family: $Gotham;
    font-size: 28px !important;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: $vibrant-blue;
}
.enabled:hover {    
    text-decoration: underline;
    text-decoration-color: #0047bb;
    text-decoration-thickness: 4px;
    text-align: center;
}

.activeLetter {
    text-decoration: underline;
    text-decoration-color: #0047bb;
    text-decoration-thickness: 4px;
    text-align: center;

    text-transform: uppercase;
    font-family: $Gotham;
    font-size: 28px !important;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    color: #0047bb;

    display: inline-block;
    position: static;
}
.letterBorder {    
    text-decoration: underline;
    text-decoration-color: #0047bb;
    text-decoration-thickness: 4px;
    
}
.disabled {
    text-transform: uppercase;
    font-family: $Gotham;
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #d0d3d4;
 
      display: inline-block;
    position: static;
}

.letter {
    min-width: 30px;
    @media (min-width: $bp-phone-max) and (max-width: $bp-tablet-ipad-lanscape) {
      min-width: 25px;
    }
}
