@import "../../../assets/scss/index.module.scss";

.megaMenuDropdown {
  width: 100%;
  position: absolute;
  border-top: solid 2px #D0D3D4;
  box-shadow: 0px 8px 19px #00000040;
  left: 0;
  display: none;
  z-index: 99;
  background: white;

  @media (max-width: $bp-tablet-ipad-lanscape) {
    border-top: none;
    border: none;
    border-bottom: solid 1.5px $silver;
  }

  .categoryHeaderContainer {
    display: none;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: block;
      width: 100%;
      margin: auto;
      border-bottom: 2px solid $ultralight-gray;
      &:hover {
        cursor: pointer;
      }   
    }

    .categoryHeaderChevron {
      display: none;
      @media (max-width: $bp-tablet-ipad-lanscape) {
        display: inline-block;
        margin-left: 13px;
        border-color: $dark-blue;
        width: 12px;
        height: 12px;
      }
    }

    .categoryHeader {
      display: none;

      @media (max-width: $bp-tablet-ipad-lanscape) {
        display: inline-block;
        font-family: $Gotham;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark-blue;
        margin-top: 10px;
        margin-left: 6px;
      }
    }
  }

  .subCategories {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      display: inline-block;
    }

    @media (min-width: $bp-tablet + 1) and (max-width: 1260px) {
      width: 92.5%;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
      width: 100%;
      margin: 0;
      display: inline-block;
    }

    .helpfulList {
      @media (min-width: $bp-tablet + 1) {
        background: $ultralight-gray;
        padding-left: 10px;
        position: relative;
        height: auto;
      }

      @media (max-width: $bp-tablet-ipad-lanscape) {
        background: #fff;
      }
    }

    .subCategory {
      padding-top: 25px;
      // padding-right: 15px;
      width: 25%;
      display: inline-block;
      vertical-align: top;

      @media (max-width: $bp-tablet-ipad-lanscape) {
        padding-top: 0;
        padding-left: 0;
        width: 100%;
        display: block;

        &:focus,
        &:hover {
          background-color: $ultralight-gray;
          cursor: pointer;
        }
      }

      .subCategoryHeader {
        font-family: $Gotham;
        font-size: 28px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: normal;
        text-align: left;
        color: $dark-blue;
        margin-bottom: 0;
        margin-top: 0;

        @media (max-width: $bp-tablet-ipad-lanscape) {
          width: 100%;
          font-family: $Gotham;
          font-size: 18px;
          line-height: 26px;
          margin-top: 10px;
          padding-bottom: 10px;
          padding-left: 31px;
          border-bottom: 2px solid $ultralight-gray;
          display: inline-block;

          &:last-child {
            margin-bottom: 25px;
          }

          &:after {
            content: "";
            height: 12px;
            width: 12px;
            position: absolute;
            left: 94%;
            border-bottom: 4px solid $charcoal-grey;
            border-right: 4px solid $charcoal-grey;
            transform: rotate(-45deg);
            margin-top: 5px;
            @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
              margin-top: 8px;
              margin-left: 10px;
            }
          }
        }

        @media (max-width: $bp-phone-max-landscape) and (orientation: landscape) {
          width: 86.5%;
        }

        @media (max-width: $bp-phone-max) and (orientation: portrait) {
          width: 82.5%;
        }
      }

      .subCategoryList {
        list-style-type: none;
        padding-left: 0;
        padding-right: 50px;
        margin-bottom: 30px;
        margin-left: 0px;

        @media (max-width: $bp-tablet-ipad-lanscape) {
          position: absolute;
          padding-right: 0;
          left: 105%;
          width: 100%;
          z-index: 1;
          transition-property: all;
          -webkit-transition-property: all;
          -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
          border-bottom: solid 1.5px $silver;
          background-color: #fff;
          margin-top: 0;
        }

        @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
          top: -40px;
        }

      
          .subCategoryListHeaderContainer {
            display: none;

            @media (max-width: $bp-tablet-ipad-lanscape) {
              display: block;
              width: 97%;
              margin: auto;
              margin-top: 10px;
              border-bottom: 1px solid $silver-two;

              .chevronStyles {
                border-color: $dark-blue;
              }

              .chevronButtonStyles {
                margin-left: 5px;
              }
            }

            /* @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
            width: 95%;
          }

          @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
            width: 90%;
          } */

            .subCategoryListHeader {
              display: none;

              @media (max-width: $bp-tablet-ipad-lanscape) {
                display: flex;
                font-family: $Gotham;
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                color: $dark-blue;
                margin-left: 25px;
                margin-bottom: 15px;
                margin-top: -26px;
              }
            }
          }
        

        .subCategoryListItem {
          margin: 10px 0;

          @media (max-width: $bp-tablet-ipad-lanscape) {
            padding-top: 0;
            padding-left: 0;
            width: 100%;
            display: block;
            margin: 0;
          }

          &:focus {
            background-color: $silver-two;
            color: initial;
          }

          &:last-child {
            margin-bottom: 25px;
          }

          .subCategoryLink {
            height: 24px;
            font-family: $Gotham;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 26px;
            letter-spacing: normal;
            text-align: left;
            color: $charcoal-black;
            text-decoration: none;
            &:hover {
              font-weight: 500;
              color: $vibrant-blue;
            }

            @media (max-width: $bp-tablet-ipad-lanscape) {
              height: 37px;
              font-size: 15px;
              padding-left: 40px; // 47px;
              padding-top: 8px;
              display: block;
              width: 100%; // 100%;
              margin-left: 0%;
              margin-right: 5%;

              &:focus,
              &:hover {
                background-color: $ultralight-gray;
                color: $charcoal-black;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}

.megaMenuSearchDropdown {
  width: 100%;
  position: absolute;
  border: solid 1.5px $silver;
  border-top: solid 4px $accent-blue;
  border-radius: 4px;
  left: 0;
  display: none;
  z-index: 99;
  background: white;

  @media (max-width: $bp-tablet-ipad-lanscape) {
    border-top: none;
    border: none;
    border-bottom: solid 1.5px $silver;
  }

  .categoryHeaderContainer {
    /* search */
    display: block;
    width: 90%;
    margin: auto;
    border-bottom: 1px solid $silver-two;

    .categoryHeaderChevron {
      /* search */
      display: inline-block;
      margin-left: 5px;
      border-color: $dark-blue;
      width: 12px;
      height: 12px;
    }

    .categoryHeader {
      /* search */
      display: inline-block;
      font-family: $Gotham;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $accent-blue;
      margin-top: 10px;
      margin-left: 6px;
    }
  }

  .subCategories {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    display: inline-block;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      display: inline-block;
    }

    @media (min-width: $bp-tablet + 1) and (max-width: 1260px) {
      width: 90%;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
      width: 100%;
      margin: 0;
      display: inline-block;
    }

    .helpfulList {
      background: $ultralight-gray;
      position: relative;
      height: auto;
      padding-left: 10px;

      .MegaMenuDropdown_subCategoryList__VqJzM {
        padding-left: 10px;
      }
      
      #mm-special-subCategory-title-3 {
        padding-left: 10px;
      }

      @media (min-width: $bp-tablet + 1) {
        background: #e5f2f3;
        position: relative;
        height: auto;
      }

      @media (max-width: $bp-tablet-ipad-lanscape) {
        background: #fff;
      }
    }

    .subCategory {
      /* search */
      vertical-align: top;
      padding-top: 0;
      padding-left: 0;
      width: 100%;
      display: block;

      &:focus,
      &:hover {
        background-color: $silver-two;
      }

      .subCategoryHeader {
        font-size: 22px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $charcoal-grey;
        margin-bottom: 0;
        /* search */
        width: 86.5%;
        margin-left: calc(5%);
        margin-right: calc(5%);
        font-family: $Gotham;
        margin-top: 10px;
        padding-bottom: 10px;
        padding-left: 31px;
        border-bottom: 1px solid $silver-two;
        display: inline-block;

        &:last-child {
          margin-bottom: 25px;
        }

        &:after {
          content: "";
          height: 12px;
          width: 12px;
          position: absolute;
          // left: 86%;
          border-bottom: 4px solid $charcoal-grey;
          border-right: 4px solid $charcoal-grey;
          transform: rotate(-45deg);
          margin-top: 5px;
          @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
            margin-top: 0;
            margin-left: 27px;
          }
        }

        @media (max-width: $bp-phone-max-landscape) and (orientation: landscape) {
          width: 86.5%;
        }

        @media (max-width: $bp-phone-max) and (orientation: portrait) {
          width: 82.5%;
        }
      }

      .subCategoryList {
        list-style-type: none;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
        position: absolute;
        left: 105%;
        width: 100%;
        z-index: 1;
        transition-property: all;
        -webkit-transition-property: all;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        border-bottom: solid 1.5px $silver;
        background-color: #fff;
        margin-top: 0;
        top: -40px;

        li {
          .subCategoryListHeaderContainer {
              display: block;
              width: 90%;
              margin: auto;
              margin-top: 10px;
              border-bottom: 1px solid $ultralight-gray;

            .chevronStyles {
              border-color: $dark-blue;
            }

            .chevronButtonStyles {
              margin-left: 5px;
            }

            .subCategoryListHeader {
              display: flex;
              font-family: $Gotham;
              font-size: 16px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $accent-blue;
              margin-left: 25px;
              margin-bottom: 15px;
              margin-top: -26px;
            }
          }
        }

        .subCategoryListItem {
            padding-top: 0;
            padding-left: 0;
            width: 100%;
            display: block;
            margin: 0;

          &:focus {
            background-color: $silver-two;
            color: initial;
          }

          &:last-child {
            margin-bottom: 25px;
          }

          .subCategoryLink {
            height: 24px;
            font-family: $Gotham;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $charcoal-black;
            text-decoration: none;

            &:hover {
              font-weight: 500;
              color: $accent-blue;
            }

            padding-left: 26px; // 47px;
            padding-top: 8px;
            display: block;
            width: 90%; // 100%;
            margin-left: 5%;
            margin-right: 5%;

            &:focus,
            &:hover {
              background-color: $silver-two;
              color: $charcoal-grey;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}