@import "../../../assets/scss/index.module.scss";

.search {
    height: 50px !important;
    border-left: 2px solid $silver-two !important;
    border-bottom: 2px solid $silver-two !important;
    position: relative !important;
    width: 100% !important;
    max-width: 1200px;
    margin: auto;
   
    margin-top: 20px!important;
    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
            border-left: none !important;
        }
@media (min-width: $bp-tablet-ipad-lanscape) and (max-width: 1260px) {
        width: 92.5% !important;
    }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
        margin-top: 0px!important;
    }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
        margin-top: 0px!important;
        }

    &:not(.searchPage) {
        height: 50px !important;
        display: inline-block !important;
        border-left: 2px solid $silver-two !important;
        border-bottom: 2px solid $silver-two !important;
        vertical-align: top !important;
        position: relative !important;
        width: 22% !important;

        @media (max-width: 1024px) {
            width: 100% !important;
        }   
    }
}

.resetIcon{
    fill:#747578;
    stroke:rgba(0,0,0,0);
    width:14px;
    height:14px;
  }

.searchIcon{
  fill:$vibrant-blue;
  width:28px;
  height:28px;
}
.ulwidthNotSearchPage{
    vertical-align: middle!important;
    max-width: 1196px !important;
        width: 100vw!important;
        height: auto!important;
        left: 0vw!important;

        @media (max-width: 1025px) {
            vertical-align: middle!important;
            width: 100% !important;
            height: auto !important;
            left: 0vw!important;
        }
}



.ulwidth{
      vertical-align: middle!important;
    max-width: 1196px !important;
      height: auto!important;
      left: 0vw!important; /* comment for full-width*/
      @media (max-width: 1025px) {
        vertical-align: middle!important;
        width: 100% !important;
        left: 0vw!important;

     }
}

#search input {
    box-sizing: border-box;
    height: 50px !important;
    border-left: 2px solid #D0D3D4 !important;
    border-right: 2px solid #D0D3D4 !important;
    border-top: 2px solid #D0D3D4 !important;
    border-bottom: 2px solid #0047BB !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100% !important;
    font-family: $Gotham !important;
    font-size: 18px !important;
    padding-left: 10px !important;
    padding-right: 2px !important;

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
            border-left: none !important;
            border-right: none !important;
        }
    @media (max-width: 1023px) {
        width: 100% !important;
        
    }   
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
        width: 100% !important;
        }
}

#search form {
    padding-bottom: 0px !important;
    font-family: $Gotham !important;
    width: 100% !important;
    @media (max-width: 1023px) {
        width: 100% !important;
    } 
}

.magnifyingG {
    position: absolute;
    right: 5px !important;
    width: 50px;
    height: 50px;
    padding: 0;
    top: 0;
}

.refresh {
    position: absolute;
    right: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0;
    top: 10px;
    border: 0;
    background-color: white !important;
}

.refresh:focus{
    outline: none;
}

// to make the people result in small drop down serach bar appear correctly
[aria-describedBy="People"] {
    padding-left: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.searchResults {
    margin: 0px !important;
    margin-left: auto !important;
    margin-top: 20px !important;
    width: 1000px !important;

    @media (max-width: 1024px) {
        margin-top: 0 !important;
        width: 100% !important;
    }
}
    
        #search {
            z-index: 2 !important;
        }