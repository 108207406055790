@import "../../../assets/scss/index.module.scss";
.CommonToolsWrapper{
  background: $ultralight-gray;
  padding:15px;
.toolsMovedTitleWrapper{
  border-bottom: 2px solid $charcoal;
}
.toolsMovedTitle{
  font-family: $Gotham;
  font-weight: 600;
  font-size: 34px;
}
.toolsMovedIconWrapper{
  margin-top: 25px;
  margin-bottom: 15px;
  justify-content: center;
  display: flex;
}
.toolsMovedIcon{
  width: 70px;
  height:78px;
  color: #000000;
}
.toolsMovedContentWrapper{
  padding: 10px;
}
.toolsMovedContent{
  font-family: $Gotham;
  text-align: center;
  font-size: 18px;
  color: $charcoal;
  font-weight: 500;
}
}