@import "../../../assets/scss/index.module.scss";

.card {
  background: $ultralight-gray;
  padding: 15px;
}

.header {
  border-bottom: 2px solid $charcoal;
}

.title {
  font-family: $Gotham;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal
}

.content{
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 70px;
  min-width: 70px;
  height: 78px;
}

.description {
  font-family: $Gotham;
  font-size: 18px;
  color: $charcoal;
  font-weight: 400;
  padding: 0;
  line-height: 24px;
  margin: 0 21px;
  font-style: normal;
  text-align: justify;
}