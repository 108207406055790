@import "../../../assets/scss/index.module.scss";

.toggleArrowButton {
  display: inline-block;
  margin-top: 15px;

  .toggleArrowEnabled {
    border-right: 4px solid $accent-blue !important;
    border-bottom: 4px solid $accent-blue !important;
    cursor: pointer;
    width: 16.1px !important;
    height: 16.1px !important;
  }

  .rectangleColored,
  .rectangleNoColor {
    width: 5.9px;
    height: 5.9px;
    margin-right: 9px;
    margin-top: 30px;
    display: inline-block;
  }

  .rectangleColored {
    background-color: $accent-blue;
  }

  .rectangleNoColor {
    background-color: $silver;
  }

  .disableToggle {
    border-right: 4px solid $silver !important;
    border-bottom: 4px solid $silver !important;
    width: 16.1px;
    height: 16.1px;
  }
}
