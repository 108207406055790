@import "../../../assets/scss/index.module.scss";

.toolPicker {
  width: 100%;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .toolPickerArrow {
    position: absolute;
    bottom: 8%;
    right: 3.5%;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $accent-blue;
  }

  /* https://www.quackit.com/css/css_scrollbars.cfm */
  .toolsList {
    position: absolute;
    overflow-y: scroll;
    max-height: 200px;
    scrollbar-arrow-color: #888;
    scrollbar-base-color: #888;
    scrollbar-darkshadow-color: #555;
    width: 100%;
    top: 101%;
    left: 0;
    background: #fff;
    border: 1px solid #bcbcbc;
    text-align: center;
    display: none;
    z-index: 1;

    .toolTitle {
      width: 100%;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $charcoal-grey;
      border-bottom: solid 0.8px $silver-two;
      margin: auto;
      padding-top: 4.5px;
      padding-bottom: 4.5px;

      &:hover {
        background-color: $silver-two;
      }
    }

    .selectedTool {
      &:before {
        content: "\2713";
        position: absolute;
        left: 5px;
        font-weight: bold;
      }
    }
  }
}
