@import "../../../assets/scss/index.module.scss";
.commonTool {
  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    margin-bottom: 29px;
  }
}

.appLayoutContainer {
  width: 100%;
  display: grid;
  display: -ms-grid;
  grid-column-gap: 32px;
  grid-template-rows: auto;
  -ms-grid-rows: auto;
  grid-template-columns: repeat(6, minmax(0, 1fr));
    -ms-grid-columns: 1fr 1fr 32px 1fr 1fr 32px 1fr 1fr;
  grid-template-areas:
    "featuredStory featuredStory storySnapshot storySnapshot announcements announcements"
      "featuredStory featuredStory moreNews moreNews allAnnouncements allAnnouncements"
      "events events events favorites favorites favorites"
      "HrConnect HrConnect HrConnect HrConnect HrConnect HrConnect";

  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    margin-top: 65px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-columns: 1fr 1fr 32px 1fr 1fr;
        grid-column-gap: 32px;
    grid-template-rows: auto;
    grid-template-areas:
      "featuredStory featuredStory storySnapshot storySnapshot"
        "featuredStory featuredStory moreNews moreNews"
        "announcements announcements announcements announcements"
        "allAnnouncements allAnnouncements allAnnouncements allAnnouncements"
        "events events events events"
        "favorites favorites favorites favorites"
        "HrConnect HrConnect HrConnect HrConnect";
  }

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "featuredStory"
      "storySnapshot"
      "moreNews"
      "announcements"
      "allAnnouncements"
      "events"
      "favorites"
      "HrConnect";
  }

  // added in to make sure things are properly aligned, needed to overwrite a Mindbreeze style
  ul, ol {
    margin-left: 0px !important;
  }

  .featuredStory {
    grid-area: featuredStory;
      grid-column: 1 / 3;
        grid-row: 1;
      
        @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
          grid-column: 1 / 3;
          grid-row: 1;
        }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 1;
      margin-bottom: 20px;
    }
  }

  .storySnapshot {
    grid-area: storySnapshot;
    border-bottom: 1px solid $pale-gray;
    margin-bottom: 12px;
    grid-column: 3 / 5;
      grid-row: 1;
    
      @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
        grid-column: 3 / 5;
        grid-row: 1;
      }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 2;
      padding-bottom: 12px;
    }
  }

  .moreNews {
    grid-area: moreNews;
    grid-column: 3 / 5;
      grid-row: 2;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 3;
    }
  }

  .announcements {
    grid-area: announcements;
    grid-column: 5 / 7;
      grid-row: 1;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      margin-top: 25px;
      grid-column: 1 / -1;
      grid-row: 3;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      margin-top: 25px;
      grid-column: 1;
        grid-row: 4;
    }
  }

  .allAnnouncements {
    grid-area: allAnnouncements;
    grid-column: 5 / 7;
      grid-row: 2;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 1 / 3;
        grid-row: 4;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 5;
    }
  }

  .events {
    margin-top: 25px;
    grid-area: events;
    grid-column: 1 / 4;
      grid-row: 3;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 1 / 3;
        grid-row: 5;
        margin-top: 25px;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 6;
      margin-top: 40px;
    }
  }

  .eventsOld{
    margin-top: 25px;
    grid-area: events;
    grid-column: 1 / 4;
      grid-row: 3;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 3 / 7;
        grid-row: 3;
        margin-top: 25px;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 6;
    }
  }

  .favorites {
    margin-top: 25px;
    grid-area: favorites;
    grid-column: 4 / 7;
      grid-row: 3;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 3 / 5;
        grid-row: 5;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 7;
    }
  }

  .favoritesOld {
    margin-top: 25px;
    grid-area: favorites;
    grid-column: 4 / 7;
      grid-row: 3;

    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 1 / 7;
        grid-row: 5;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      grid-column: 1;
        grid-row: 7;
    }
  }
}

.loadingEventsOld {
  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    display: none;
  }
}