@font-face {
  font-family: "TiemposHeadlineSemibold";  
  src: local("TiemposHeadlineSemibold"),
    url("../fonts/Tiempos/TiemposHeadlineSemibold.woff2") format("woff2");
}

$SegoeUI: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$SegoeUILite: "Segoe UI Web (West European)", Segoe UI Light, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$Gotham: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$TiemposHeadline: "TiemposHeadlineSemibold", serif;
