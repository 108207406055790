@import "../../../assets/scss/index.module.scss";

.tool {
  box-sizing: border-box;
  border: solid 1px $silver;
  width: 46%;
  height: 50px;
  margin: 6px 5px;
  padding: 4px;
  color: $charcoal-grey;
  text-decoration: none !important;
  text-align: left;
  display: inline-block;
  position: relative;
  overflow: hidden;
  
  .toolLink {
    text-decoration: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    & .imagePlaceHolder {
      width: 30px;
      height: 30px;
      margin: 5px 7px 5px 5px;
    }
  }

  .availabletoolLink {
    padding-left: 10px;
    @media (min-width: $bp-mobile-max) and (max-width: $bp-tablet) {
      padding-left: 8px !important;
    }
  }

  .customizeTool {
    height: 100%;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border:0px;
    background: none;
  }

  .emptyTool {
    display: block;
    width: 100%;
    margin-top: 5px;
    font-family: $SegoeUI;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $silver;
  }
  .toolTextWrapper{
    line-height: 18px;
  }

  strong {
    height: 24px;
    font-family: $SegoeUI;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    // line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
  }
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 46%;
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
    width: 95%;
    margin: 9px;
    strong {
      height: 24px;
      font-family: $SegoeUI;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal-grey;
    }
  }
  img {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    margin: 5px;
    margin-right: 7px;
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      margin-right: 25px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tool {
    margin: 3px 5px;
  }
}

.newTool {
  div {
    display: inline-block;
  }
  &:before {
    @extend %newToolAlert;
  }
}

.hoverableTool:hover {
  box-shadow: 0 6px 10px 3px rgba(188, 189, 188, 0.55);
}

.tooltipStyle {
  // margin-bottom: 50px !important;
  top: auto !important;
  bottom: 57px !important;
  left: 5px !important;
  position: absolute !important;
  border-width: 5px 7px 0 !important;
  background-color: $charcoal-grey !important;
  text-align: center;
  margin-left: -5px !important;
  margin-right: -5px !important;
  min-height: 25.6px !important;
  border-radius: 2px !important;
  // padding-top: 3px !important;
  padding: 3px !important;
  display: block !important;
  width: 100% !important;
  z-index: 0 !important;

  &:after,
  &:before {
    border-top-color: $charcoal-grey !important;
  }

  @media (max-width: $bp-tablet-ipad-lanscape) {
    visibility: hidden !important;
    display: none !important;
  }
}

.availableToolsTooltip {
  margin-left: -15px !important;
  margin-right: 5px !important;
  left: 13px !important;
}

%newToolAlert {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: $accent-green;
  margin-right: 5px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 20px;
  @media (min-width: $bp-mobile-max) and (max-width: 870px) {
    left: 3px !important;
  }
}

