@import "../../../assets/scss/index.module.scss";

.employeeDetailViewContainer {
  display: grid;
  font-family: $SegoeUI;
  background-repeat: no-repeat;
  grid-template-columns: 27% 44% 27%;
  grid-template-rows: minmax(0, 625px) auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: "contactInfo hrLocation orgchart" "aboutMe aboutMe orgchart";
  width: auto;
  background-color: #ffffff;
  padding: 10px;
  padding-top: 164px;
  padding-left: 10%;
  padding-right: 10%;

  @media (min-width: 1440px) {
    background-size: 100% auto;
  }

  /* Phone stuff */
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    grid-template-columns: 100%;
    -ms-grid-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "mobileEditModal" "contactInfo" "hrLocation" "aboutMe" "orgchart";
    margin-top: 0px;
    padding-left: 6%;
    padding-right: 6%;
    grid-row-gap: 10px;
    width: 100%;
  }

  /* Tablet stuff */
  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
    grid-template-columns: 280px auto;
    grid-template-rows: auto;
    -ms-grid-columns: 280px auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas:
      "mobileEditModal mobileEditModal"
      "contactInfo hrLocation"
      "orgchart aboutMe";
    margin-top: 0px;
    padding: 2%;
    padding-top: 80px;
  }

  @media (max-width: 666px) {
    padding-top: 80px;
  }

  .mobileEditModal {
    grid-area: mobileEditModal;
    margin: 0 !important;
    margin-bottom: 50px;
    z-index: 1000;
  }

  .contactInfo {
    grid-area: contactInfo;
    background-color: #ffffff;
    border: 1px solid $pale-gray;
    width: auto;
    border-radius: 4px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 1;

    /* Tablet stuff */
    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      min-height: 555px;
    }
  }

  .hrLocation {
    font-family: $SegoeUI;
    grid-area: hrLocation;
    background-color: #ffffff;
    border: 1px solid $silver;
    border-radius: 4px;
    width: 100%;
    padding-bottom: 20px;
    z-index: 1;

    /* Tablet stuff */
    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      min-height: 500px;
    }
  }

  .orgchart {
    grid-area: orgchart;
    background-color: #ffffff;
    border: 1px solid $silver;
    border-radius: 4px;
    width: auto;
    height: 100%;
    max-height: 1200px;
    overflow-y: auto;
    z-index: 1;

    /* phone */
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
      overflow-y: initial;
      max-height: initial;
    }
  }

  .aboutMe {
    grid-area: aboutMe;
    -ms-grid-col-span: 2;
    background-color: #ffffff;
    border: 1px solid $silver;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    height: 100%;
    min-height: 140px;
  }
}

.aboutMeHeading {
  padding-top: 15px;
}

//This is for Edge only (Needed because of the media query below.)
@supports (-ms-ime-align: auto) {
  .employeeDetailViewContainer {
    display: grid;
    grid-template-rows: none;
  }
}


// This is for Internet Explorer (May also affect edge)
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .employeeDetailViewContainer {
    display: -ms-grid;
    -ms-grid-columns: 27% 10px 44% 10px 27%;
    -ms-grid-rows: minmax(0, 625px) 10px auto;
    grid-template: "contactInfo hrLocation orgchart" "aboutMe aboutMe orgchart";

    // -ms-grid-rows: auto;

    .contactInfo {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .orgchart {
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      -ms-grid-column: 5;
    }

    .aboutMe {
      max-height: 500px;
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }

    .hrLocation {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }
}

.backgroundSkeleton {
  background-color: $ultralight-gray;
  position: absolute;
  left: 0;
  top: 0;
  height: 230px; 
  width: 100vw; 
  z-index: 0;
}