@import "../../../assets/scss/index.module.scss";
@import "../../../assets//scss/fonts.module.scss";

*{
margin:0;
padding:0;
}

.dropdown{
  width:100%;
  user-select: none;
  margin:0 auto;
  position: relative;
  justify-content: center;
  height: 100%;
}

.dropdown .dropdownBtn{
//  padding: 15px 20px;
  background:#ffffff;
//  box-shadow:3px 3px 10px 6px rgba(0,0,0,0.06);
  font-weight: 600!important;
  color: #0047bb!important;
  display:flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height:100%;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 24px;
}

.dropdown .dropdownBtn:hover{
    text-decoration: none;
  }

.dropdown .dropdownContent{
  position: absolute;
  top:45px;
  left:0;
  padding:10px;
  background:#ffffff;
  box-shadow:3px 3px 10px 6px rgba(0,0,0,0.06);
  font-weight: 500;
  color:#333;
  width:100%;
  height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dropdownContent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropdownContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dropdown .dropdownContent .dropdownItem {
  cursor: pointer;
  transition: all 0.2s;
  display: block;
  text-align: center!important;
  font-family: $Gotham;
  font-size: 24px;
  font-weight: 600!important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #171717;
}

.dropdown .dropdownContent .dropdownItem:hover {
  background:#f4f4f4;

}
.arrowCaret{
  width: 10px;
  height: 10px;
  border-bottom: 3px solid #0047bb;
  border-right: 3px solid #0047bb;
  transform: rotate(45deg);
  margin-bottom: 4px;
  margin-left: 14px;
}

.selectText{
  font-size: 16px;
}
.selectedAlphabet{
  font-size: 24px;
}


