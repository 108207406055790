@import "../../../assets/scss/index.module.scss";
.App {
  margin: auto;
  position: relative;
  margin-top: 30px;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    margin-top: 30px;
}
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    margin-top: 30px;
  }

  @media (device-width:767px),
  (max-width:767px) {
    padding-top: 0;
  }
}

.header{
  text-align: center;
  display: none;
}

.back_to_inside_link {
  color: $nw-blue!important;

  .back_to_inside_icon {
    stroke: $nw-blue!important;
  }
  &:hover{
    color: $mint!important;
    .back_to_inside_icon {
      stroke: $mint!important;
     
    }
  }
}
.headerWrapper{
  position: sticky;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 9;
}
.appWrapper{
  margin: auto;
}
.padded {
  width: 100% !important;
  max-width: 1440px;
  margin: auto;
  padding: 0 15px 40px 15px;
  @media (max-width: 1260px) {
    width: 92.5% !important;
  }
}

body {
  overflow-x: hidden;
  max-width: 100%;
}

.documentsAndCalendar {
  display: table;
  width: 100%;
  margin-bottom: 45px;
}


.searchBoxDefault {
  right:0px;
  left:0px;
  top:50px;
  width:100%;
  background: #ffffff;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    position: relative;
    top: 0px;
    z-index: 1;
}
@media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  position: relative;
  top: 0px;
  z-index: 1;
  }
}

.searchBoxFixed {
  position: fixed;
  top: 130px; //same height as header
  z-index: 8;
  height: 90px;
  box-shadow: 0px 8px 19px #00000040;
}
.searchPageFixed {
  max-width: 1200px !important;
    margin: auto;
  position: fixed;
  top: 130px;
  z-index: 3;

  @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
    top: 50px;
  }
}