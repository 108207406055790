@import "../../../assets/scss/index.module.scss";

.aToZ {
  border-bottom: 0px solid $med-grey;

  .headingLink {
    color: $charcoal-grey;
  }

  .headingLink:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $accent-blue !important;
  }
  .heading {
    font-family: $SegoeUI;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    margin-top: -1px;
    padding-bottom: 7px;
    border-bottom: solid 4px $steel-grey;


    .arrows {
      display: none;
      right: 10px;
      margin-top: -23px;
      @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
        color: transparent;
        float: right;
        display: inline-block;
        margin-right: -6px;
        margin-top: -33px;
        height: 31px;
      }
    }
  }

  .favoriteLists {
    max-width: 100%;
    min-height: 469px;

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      overflow: hidden;
      position: relative;
      min-height: 363px;
    }

    & ul:first-child {
      margin: 0;

      @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
        min-height: 250px;

        & li:last-child {
          border-bottom: solid 1px transparent;
        }
      }
    }

    & ul:last-child li:last-child {
      border-bottom: solid 1px transparent;
    }

    .favoriteList {
      list-style-type: none;
      padding: 0;
      border: 1px solid transparent;
      font-family: $SegoeUI;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $accent-blue;
      transition: all 0.3s;
      margin: 0;

      @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
        min-height: 250px;
        display: inline-block;
        width: 100%;
        position: absolute;
      }
    }

    .slideRight {
      left: 100%;
    }

    .slideRightIntoView {
      left: 0;
    }

    .slideLeftIntoView {
      right: 0%;
    }

    .slideLeft {
      right: 100%;
    }

    ul li {
      height: 42px;
      border-bottom: 1px solid $silver-two;
      width: 100%;
    }

    ul li:hover {
      box-shadow: 0 6px 10px 3px rgba(188, 189, 188, 0.55);
    }

    .visitAtoZlink {
      background-color: transparent;

      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        position: relative;
        left: 12px;
      }
    }

    .favLinkStyle {
      display: block;
      width: 96%;
      padding-top: 8px;
      padding-left: 16px;
      text-decoration: none;
      color: $accent-blue;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
        padding: 8px 5px;
      }
    }
  }

  .aTozBorder {
    border-bottom: 1px solid $med-grey;
    margin-top: -4px;
  }

  .noResultStyles {
    min-height: 0px;
    background-position: center 50px !important;
    p {
      min-height: 449px;
      padding-top: 167px;
    }

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      min-height: 0px;
      background-position: center 40px !important;
      p {
        min-height: 240px;
        padding-top: 156px;
      }
    }
  }
}
