@import "../../../assets/scss/index.module.scss";

.megaMenuButton[aria-expanded=true]{
  background: $ultralight-gray!important;
  span {
    color: $charcoal;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      color: $charcoal-grey;
    }
  }
}
.megaMenuSpacer {
  height: 0px;
  &:not(.searchPage) {
    height: 50px;
    @media (min-width: 667px) {
      height: 80px;
    }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      height: 0px;
    } 
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      height: 0px;
    }
  }
}
.headerInnerWrapper{
  max-width: $bp-desktop-max;
  margin: auto;
  display: flex;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    flex-direction: column;
  }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    display: block;
    margin: auto;
    background: #ffffff;
  }
}
.mainNavigationWrapper{
  display:flex;
  flex-direction:column;
  max-width: 1200px;
    margin: auto;
  width:100%;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) { 
    flex-direction:column-reverse;
    }
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      flex-direction: column-reverse;
    } 
    @media (min-width: 1025px) and (max-width: 1260px) {
      width: 95.5% !important;
    }
}
.megaMenuContainer {
  padding: 0px 15px;
  height: 50px;
  position: sticky;
  z-index: 99;
  background: #fff;
  top: 81px;
  border-bottom: solid 2px $silver-two;
  
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) { 
    padding: 0px;
    }
  @media only screen and (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 100%;
  }

  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    height: 50px;
    top:0;
    padding: 0px;
    
  }

  @media  (max-width:767px) {
    top: 0;
    margin-top: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: fixed;
    width: 100%;
  }

  .mobileMegaMenuHeader {
    display: none;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: block;
      width: 100%;
      height: 54px;
      border-bottom: 2px solid $silver-two;
      background-color: $vibrant-blue;
    }

    .insideImage {
      position: absolute;
      left: 35%;
      @media (min-width: $bp-phone-min) {
        left: 33%;
      }
      @media (min-width: 480px) {
        left: 40%;
      }
      @media (min-width: 640px) {
        left: 40%;
      }
      @media (min-width: $bp-phone-max-events) {
        left: 40%;
      }
      @media (min-width: 736px) {
        left: 42%;
      }
      @media (min-width: $bp-tablet-min) {
        left: 42%;
        width: 125px;
      }
      @media (min-width: $bp-tablet-ipad-lanscape) {
        left: 41%;
        width: 125px;
      }
    }
  }

  .megaMenuCategories {
    display: inline-flex;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      width: 100%;
      background: white;
    }

    .megaMenuCategory {
      display: inline-block;
      padding: 0px 15px 0px 0px!important;
      .megaMenuDropdown {
        left: 0;
       padding: 0px 0px;
        @media (max-width: $bp-tablet-ipad-lanscape) {
          left: 105%;
          top: 0px;
          transition-property: left;
          -webkit-transition-property: left;
          -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
          display: none;
        }
      }

      .showMobileSubCategory {
        @media (max-width: $bp-tablet-ipad-lanscape) {
          top: 62px;
          left: 0;
          display: block;
        }
      }

      .dropdownOpen {
        display: block;
      }
      .megaMenuButton {
        background: none;
    
      
          color: $charcoal;

          @media (max-width: $bp-tablet-ipad-lanscape) {
            color: $charcoal-grey;
          }
        
      }
      .dropdownOpenMobile {
        @media (max-width: $bp-tablet-ipad-lanscape) {
          display: block;
          left: 0;
        }
      }
      &:focus {
        .megaMenuDropdown {
          display: none;
        }

        .megaMenuButton {
          background: $ultralight-gray;
          span {
            color: $charcoal;

            @media (max-width: $bp-tablet-ipad-lanscape) {
              color: $charcoal-grey;
            }
          }
        }

        @media (max-width: $bp-tablet-ipad-lanscape) {
          background: $ultralight-gray;
        }
      }

      @media (max-width: $bp-tablet-ipad-lanscape) {
        width: 100%;
        text-align: left;
        display: block;
        background: white;
        border-bottom: 2px solid $ultralight-gray;
        
      }
    }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      position: relative;
      bottom: 50px;
      z-index: 9;
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    width: 100%;
  }

  @media only screen and (min-width: $bp-phone-min) and (max-width: $bp-phone-max) and (orientation: landscape) {
    width: 100%;
    top: 0;
  }

  @media (max-width: $bp-tablet-ipad-lanscape) {
    .hideMobileMenu {
      display: none;
    }

    .showMobileMenu {
      display: inline;
      padding-top: 5px;
      @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
        position: fixed;
        top: 53px;
        z-index: 9;
        box-shadow: 0px 8px 19px #00000040 !important;
        }
    }
  }

  @media (min-width: 641px) and (max-width: ($bp-tablet-min - 1)) {
    margin-left: -24px;
  }
}

.megaMenuContainerSearch {
  height: 50px;
  position: sticky;
  z-index: 99;
  background: #fff;
  top: 81px;

  .hideMobileMenu {
    display: none;
  }

  .showMobileMenu {
    display: inline;
    padding-top: 5px;
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      position: fixed;
      top: 104px;
      z-index: 9;
      box-shadow: 0px 8px 19px #00000040 !important;
      }
  }

  @media only screen and (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    height: 50px;
    width: 100%;
    top: 0;
  }

  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    height: 50px;
    top: 0;
  }

  @media  (max-width:767px) {
    top: 0;
    margin-top: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: fixed;
    width: 100%;
  }

  .mobileMegaMenuHeader {
    display: none;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: block;
      width: 100%;
      height: 50px;
      border-bottom: 2px solid $silver-two;
      background-color: #fff;
    }

    .insideImage {
      position: absolute;
      left: 40%;
      @media (min-width: $bp-phone-min) {
        left: 33%;
        top: 7px;
      }
      @media (min-width: 480px) {
        left: 40%;
      }
      @media (min-width: 640px) {
        left: 40%;
      }
      @media (min-width: $bp-phone-max-events) {
        left: 20%;
      }
      @media (min-width: 736px) {
        left: 22%;
      }
      @media (min-width: $bp-tablet-min) {
        left: 40%;
        width: 125px;
      }
      @media (min-width: $bp-tablet-ipad-lanscape) {
        left: 30%;
        width: 125px;
      }
      @media (min-width: $bp-ugly-desktop-min){
        left: 40%;
        width: 125px;
      }
    }
  }

  .mobileMegaMenuHeaderSearch {
    display: block;
    width: 100%;
    height: 50px;
    border-bottom: 2px solid $silver-two;
    background-color: #fff;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: block;
      width: 100%;
      height: 50px;
      border-bottom: 2px solid $silver-two;
      background-color: #fff;
    }

    .insideImage {
      position: absolute;
      left: 40%;
      @media (min-width: $bp-phone-min) {
        left: 33%;
        top: 7px;
      }
      @media (min-width: 480px) {
        left: 40%;
      }
      @media (min-width: 640px) {
        left: 40%;
      }
      @media (min-width: $bp-phone-max-events) {
        left: 20%; 
      }
      @media (min-width: 736px) {
        left: 22%;
      }
      @media (min-width: $bp-tablet-min) {
        left: 40%;
        width: 125px;
      }
      @media (min-width: $bp-tablet-ipad-lanscape) {
        left: 43%;
        width: 125px;
      }
    }
  }

  .megaMenuCategories {
    display: inline-flex;
    border-bottom: solid 2px $silver-two;
    height: 100%;
    min-height: 100%;
 
    @media (max-width: $bp-tablet-ipad-lanscape) {
      width: 100%;
      background: white;
    }

    .megaMenuCategory {
      width: 20%;
      display: inline-block;
      

      .megaMenuDropdown {
        left: 0;

        @media (max-width: $bp-tablet-ipad-lanscape) {
          left: 105%;
          top: 62px;
          transition-property: left;
          -webkit-transition-property: left;
          -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
          display: none;
        }
      }

      .showMobileSubCategory {
        @media (max-width: $bp-tablet-ipad-lanscape) {
          top: 62px;
          left: 0;
          display: block;
        }
      }

      .dropdownOpen {
        display: block;
      }

      .dropdownOpenMobile {
        @media (max-width: $bp-tablet-ipad-lanscape) {
          display: block;
          left: 0;
          top: 49px;
        }
      }

      &:hover {
        .megaMenuDropdown {
          display: block;
        }

        .megaMenuButton {
          background: $ultralight-gray;
          span {
            color: $charcoal;

            @media (max-width: $bp-tablet-ipad-lanscape) {
              color: $charcoal-grey;
            }
          }
        }

        @media (max-width: $bp-tablet-ipad-lanscape) {
          background: $ultralight-gray;
        }
      }
     
      &:focus {
        .megaMenuDropdown {
          display: none;
        }

        .megaMenuButton {
          background: $ultralight-gray;
          span {
            color: $nw-blue;

            @media (max-width: $bp-tablet-ipad-lanscape) {
              color: $charcoal-grey;
            }
          }
        }

        @media (max-width: $bp-tablet-ipad-lanscape) {
          background: $ultralight-gray;
        }
      }

      @media (max-width: $bp-tablet-ipad-lanscape) {
        width: 100%;
        text-align: left;
        display: block;
        background: white;
      }
    }
    
  }

  .megaMenuSearchCategories {
    width: 100%;
    border-bottom: solid 2px $silver-two;
    height: 100%;
    min-height: 100%;
    background: white;

    .megaMenuCategory {
      width: 100%;
      display: block;
      height: 50px;
      text-align: left;
      background: white;

      .megaMenuDropdown {
        left: 105%;
        top: 62px;
        transition-property: left;
        -webkit-transition-property: left;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        display: none;
      }

      .showMobileSubCategory {
        top: 62px;
        left: 0;
        display: block;
      }

      .dropdownOpen {
        display: block;
      }

      .dropdownOpenMobile {
        display: block;
        left: 0;
        top: 49px;
      }

      &:hover {
        .megaMenuDropdown {
          display: block;
        }

        .megaMenuButton {
          span {
          color: $charcoal-grey;
          }
        }

        background: $ultralight-gray;
      }

      &:focus {
        .megaMenuDropdown {
          display: none;
        }

        .megaMenuButton {
          span {
          color: $charcoal-grey;
          }
        }
        background: $ultralight-gray;
      }

      @media (max-width: $bp-tablet-ipad-lanscape) {
        width: 100%;
        text-align: left;
        display: block;
        background: white;
      }
    }
  }

  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    width: 100%;
  }

  @media only screen and (min-width: $bp-phone-min) and (max-width: $bp-phone-max) and (orientation: landscape) {
    width: 100%;
    top: 0;
  }

  @media (max-width: $bp-tablet-ipad-lanscape) {
    .hideMobileMenu {
      display: none;
    }

    .showMobileMenu {
      display: inline;
      padding-top: 5px;
      @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
        position: fixed;
        top: 104px;
        z-index: 9;
        box-shadow: 0px 8px 19px #00000040 !important;
        }
    }
  }

  @media (min-width: 641px) and (max-width: ($bp-tablet-min - 1)) {
    margin-left: -24px;
  }
}

.searchBox {
  position: absolute;
  right:0px;
  left:0px;
  top:50px;
  width:100%;
  background: #ffffff;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    position: relative;
    top: 0px;
    z-index: 1; 
    flex-direction: column-reverse;
}
@media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  position: relative;
  top: 0px;
  z-index: 1; 
  flex-direction: column-reverse;
  }

}
.searchIconContainer {
  display: flex !important;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: -15px !important;

  .searchIconButton {
    flex: 1;
    margin-right: 150px;
    border: none;
    background-color: transparent;
  }
}