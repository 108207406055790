@import "../HRConnectWidgets.module.scss";

.message {
  margin-top: calc(#{$section-height} + #{$header-margin-bottom});
  margin-bottom: calc(#{$section-div-margin-top-bottom} * 4 + #{$section-line-height} * 2);
  text-align: center;
  position: relative;
}

.text {
  position: absolute;
  background-color: $hr-connect-background;
  text-align: center;
  top: -4px;
  font-size: 18px;
}

.comingSoon {
  right: calc(50% - 75px);
  width: 150px;
}

.unavailable {
  right: calc(50% - 130px);
  width: 260px;
}

.divider {
  padding-top: 10px;
}