@import "../../../assets/scss/index.module.scss";

.tabOnce {
  margin-left: 22px;
  background-color: #eaeaea;
  border-left-style: solid;
  border-left-width: 2px;
  border-color: $silver;
  padding-left: 8px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    background-color: #deedfa;
    border-color: $news-blue;
    cursor: pointer;
  }
}
.blueBorder {
  border-left-color: $vibrant-blue;
}

.tabTwice {
  cursor: pointer;
  margin-left: 52px;
  background-color: transparent;
  padding-left: 15px;
  border-left-style: solid;
  border-left-width: 2px;
  border-color: $silver;
  margin-bottom: 5px;

  &:hover {
    background-color: #deedfa;
    border-color: $news-blue;
    cursor: pointer;
  }
}

.boss {
  &:hover {
    background-color: #deedfa;
    cursor: pointer;
    height: 45px;
  }
}

.directReportWrapper {
  font-family: $SegoeUI;
  display: flex;
  background-color: transpartent;
  margin-bottom: 5px;

  .pictureWrapper {
    width: 45px;
    background-color: transparent;
    text-align: center;

    .pictureWhiteBorder {
      border: 2px solid $pale-gray;
      padding: 2px;
      display: inline-block;
      border-radius: 50%;

      .employeeImage {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $page-bg-color;
        padding: 0px;
        z-index: 100;
        background-size: 100%, 40px;
        background-repeat: no-repeat;
      }
    }
  }

  .nameTitleWrapper {
    color: $charcoal;
    font-family: $Gotham;
    width: calc(100% - 60px);
    background-color: transparent;
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    font-style: normal;
    line-height: normal;

    .employeeFullName {
      width: 100%;
      font-weight: 700;
      height: 17px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .employeeTitle {
      width: 100%;
      font-weight: 400;
      height: 17px;
      word-wrap: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.connectingLine{
  border-left: 2px solid $pale-gray;
  margin-left: 22px;
  height: 9px;
}

.connectingLine:first-child {
  border-left: none !important;
  height: 0px;
}

.blueLine {
  border-left: 2px solid $news-blue;
  height: 9px;
  margin-top: -9px;
  margin-left: 22px;

}

.fixedheight {
  height: 9px;
}

.directReportWrapperBoss{
  height: 45px;
}

.directReportWrapperSkeleton{
  display: flex;
  gap: 10px;
  padding-bottom: 9px;
}

.nameTitleWrapperSkeleton{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}