// Settings
$arrow-color: inherit;
//inherit,that way, the before and after will be stlyed by their "parent" element
$arrow-size: 1em;
$arrow-border: 0.2em;

.desktopArrowDirection {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  box-shadow: inset 0 0 0 $arrow-border $arrow-color;
  top: -3.5px;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    transition: all 0.5s ease-in-out;
  }
  &:before {
    top: 50%;
    right: $arrow-size;
    width: $arrow-size;
    height: $arrow-size;
    transform-origin: 50% 50%;
    transform: translate(0, -($arrow-size / 2)) rotate(45deg);
    box-shadow: inset -#{$arrow-border} $arrow-border 0 0;
  }

  &:after {
    top: 50%;
    right: 1em;
    width: $arrow-size * 1.3;
    height: $arrow-border;
    transform: translate(0, -($arrow-border / 2));
    background-color: $arrow-color;
  }
}
