@import "../../../assets/scss/index.module.scss";
.CommonToolsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .myToolLink:nth-child(odd){
    padding: 5px 15px 5px 0px;
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      padding: 5px 0px 5px 0px;
    }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      padding: 5px 0px 5px 0px;
    }
  }
  .myToolLink:nth-child(even){
    padding: 5px 0px 5px 15px;
    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      padding: 5px 0px 5px 0px;
    }
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      padding: 5px 0px 5px 0px;
    }
  }
  .myToolLink {
    width: 48%;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 100%;
  }
    a {
      color: $charcoal-black !important;
      text-decoration: none;
      font-family: $Gotham;
      font-size: 18px;
      font-weight: 500 !important;
      &:hover {
        color: $vibrant-blue !important;
      }
    }
    
    @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
      width: 100%;
    }
    .linkClass{
      width: 100%;
    }
        .toolTitleText {
          overflow: hidden;
          word-wrap: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 120%;
          padding: 0px;
          margin: 0px;
        }
  }
  .toolsHeading {
    position: relative;
    font-family: $Gotham;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    border-bottom: solid 2px $charcoal;
    display: flex;
    flex-direction: row;

    .titleLink {
      color: $charcoal-grey;
      text-decoration: none;
    }
    .titleLink:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $accent-blue !important;
    }
      
    .title {
      font-family: $Gotham;
      font-size: 34px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal;
      margin: 0px !important; 
      display: inline-block;
      &:hover{
        color: $vibrant-blue;
      }

      @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
        font-size: 24px;
      }
      @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
        font-size: 24px;
      }
    }

    .newToolsOrArrows {
      display: flex;
      flex: 1;
      align-items: flex-end;
      margin-left: 20px;
      margin-bottom: 5px;

      .newTools {
        font-family: $Gotham;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $ocean-green;
        margin-left: 5px;
      }

    }

    .viewAllLink {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 5px;

      .viewAllToolsLink {
        align-items: center;
        display: flex;
        font-weight: 500;
        border:0px;
        background: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .gearIcon{
        height: 20px!important;
        width: 20px!important;
      
      }
      .viewAllLinkText {
        font-family: $Gotham;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $vibrant-blue;
        margin-left: 10px;
      }
    }
    }
  
 
  .toolsDisplay {
    margin: 0px!important;
    @media (min-width: $bp-phone-min) and (max-width: ($bp-tablet-min - 1)) {
      margin-bottom: 12px;
    }
  }
}

.gearIconSvg {
  height: 20px !important;
  width: 20px !important;
  stroke: $vibrant-blue;
}