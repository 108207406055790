@import "../../../../assets/scss/index.module.scss";

$header-height: 24px;
$section-height: 80px;
$header-margin-top: 0px;
$header-margin-bottom: 8px;
$section-div-margin-top-bottom: 10px;
$section-line-height: 21px;

.header {
  font-family: $Gotham;
  width: 100%;
  height: $header-height;
  text-align: center;
  margin: $header-margin-top 0px $header-margin-bottom 0px;

  h3{
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }
}

.widget {
  display: block;
  width: 100%;
}

.divider {
  height: 0px;
  border-bottom: 1px solid $hr-connect-accent-2;
  margin: 10px 0px 10px 0px;
}

.section {
  min-height: $section-height;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
}

.subSectionHeader {
  font-size: 18px;
  text-align: center;
  line-height: $section-line-height;
}

.subSectionContents {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  line-height: $section-line-height;
  margin-top: 11px;

  &:hover {
    cursor: default;
  }
}

.buttonRow {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;

  &:has(> a:only-child) {
    a {
      min-width: 220px;
    }
  }
}

.payButtonLink {
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $hr-connect-background;
  background-color: $hr-connect-accent;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  width: fit-content;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover,
  &:focus {
    background-color: $hr-connect-accent-4;
    text-decoration: none;
    color: $hr-connect-background;
    outline: none;
  }
}

.buttonLink {
  height: 36px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $hr-connect-background;
  background-color: $hr-connect-accent;
  display: inline-block;
  width: fit-content;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;

  &:hover,
  &:focus {
    background-color: $hr-connect-accent-4;
    text-decoration: none;
    color: $hr-connect-background;
    outline: none;
  }
}

.tooltip {
  top: auto !important;
  bottom: 20px !important;
  left: 5px !important;
  right: 5px !important;
  position: absolute !important;
  display: block !important;
  font-size: 18px !important;
  opacity: 1 !important;
  background-color: $hr-connect-accent-3 !important;

  &::after {
    border-top-color: $hr-connect-accent-3 !important;
  }
}

.obfuscate {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.benefitElectionHeader {
  background: #008775;
}

.beRow {
  margin: 0px !important;
  text-align: left;
}

.benefitElectionTopSection {
  min-height: 50px;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
}

.benefitElectionSubHeader {
  font-weight: 100;
}

.benefitElectionBottomSection {
  min-height: 120px;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
  margin-left: 15px;
  margin-right: 15px;
}

.benefitElectionsButtonRow {
  height: 35px;
  display: flex;
  justify-content: space-between;
  gap:20px;
  
  a{
    margin: 0;
  }
}

.benefitElectionRowClear {
  background: none;
}

.benefitElectionRowBg {
  background: #005670;
}

.benefitElectionButtonLink {
  height: 36px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $hr-connect-background;
  background-color: $hr-connect-accent;
  // margin-left: 5px;
  // margin-right: 5px;
  display: inline-block;
  width: fit-content;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;

  &:hover,
  &:focus {
    background-color: $hr-connect-accent-4;
    text-decoration: none;
    color: $hr-connect-background;
    outline: none;
  }
}

.btnLeft {
  margin-right: 5px;
  float: left;
  width: 48%;
}

.btnRight {
  margin-left: 5px;
  float: right;
  width: 48%;
}

.associateButtonLink {
  height: 36px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $hr-connect-background;
  background-color: $hr-connect-accent;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  width: fit-content;
  padding-top: 5px;
  padding-left: 48px;
  padding-right: 48px;

  &:hover,
  &:focus {
    background-color: $hr-connect-accent-4;
    text-decoration: none;
    color: $hr-connect-background;
    outline: none;
  }
}

.hideSpan {
  visibility: hidden;
}

.progressBarFilled {
  background-color: $hr-connect-accent;
  border-width: 1px;
  border-color: white;
  border-style: solid;
}

.progressBarUnfilled {
  border-style: solid;
  border-width: 1px;
  border-color: white;
}