@import "../../../assets/scss/index.module.scss";

.goToLink {
  text-align: right;
  margin-top: 5px;
  font-family: $SegoeUI;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $accent-blue;
  padding-right: 5px;

  .link {
    color: $accent-blue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .moreArrow {
    box-shadow: none;
    width: 20px;
    font-size: 8px;
    display: inline-block;
  }
}
