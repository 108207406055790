@import "../../../assets/scss/index.module.scss";
.editPhotoLink {
  color: $accent-blue !important;
  padding: 0px;
  position: absolute;
  bottom: 0px;
  right: -42px;
  cursor: pointer !important;
  &:hover {
    text-decoration: underline !important;
  }

}