@import "../../../assets/scss/index.module.scss";

.noResults {
  min-height: 232px;

  p {
    width: 100%;
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $charcoal-grey;
    background-color: transparent;
    padding-top: 150px;
    padding-left: 5%;
    padding-right: 5%;
  }
}
