@import "../../../assets/scss/index.module.scss";

.diagnosticTable {
  table-layout: fixed;
  padding-top: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;

  tr {
    border-bottom: 2px solid $steel-grey;
  }

  th {
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: 600;
    height: auto;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    text-align: left;
    text-size-adjust: 100%;
    width: auto;
  }

  // td {
  //   height: 30px;
  //   text-size-adjust: 100%;
  //   width: 50%;
  //   max-width: 50%;
  //   word-wrap: break-word;
  //   text-align: left;
  // }

  .rowLabel {
    max-width: 20%;
  }
}

.table {
  border: 1px solid black;
  padding: 5px;
  width: 100%;
  max-width: 100%;

  .row {
    border: 1px solid black;
    padding: 5px;
    width: 100%;
    max-width: 100%;
  }

  .column {
    border: 1px solid black;
    padding: 5px;
    width: 50%;
    font-size: 14px;
    font-weight: bold;
  }
}

.clearStorageButton {
  float: right;
  margin-bottom: 10px;
  color: white;
  &:hover {
    color: white !important;
    text-decoration: underline !important;
  }
  &:focus {
    color: white !important;
    text-decoration: underline !important;
  }
}

.unknown {
  background-color: rgb(255, 255, 128);
}

.success {
  background-color: rgb(128, 255, 128);
}

.error {
  background-color: rgb(255, 128, 128);
}
