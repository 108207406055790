@import "../../../assets/scss/index.module.scss";

.orgChart {
  width: 100%;
  font-family: $Gotham;
  max-height: 800px;
  color: $charcoal;

  /* Phone stuff */
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    max-height: fit-content;
  }

 .expandedOrgChart {
    text-align: right;
    color: $accent-blue;
    font-size: 12px;
    font-family: $SegoeUI;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
              
    .expandedOrgChartLink {
      background: none;
      border:none;
       float: right;
       color: inherit;      
    }
 }
 
  .orgTitleWrapper {
    padding-bottom: 10px;
    margin-top: 26px;
    display: inherit;
    background-color: transparent;
    border-bottom: $charcoal 1px solid;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    .title {
      font-family: $SegoeUI;
      padding: 0;
      color: $charcoal;
      margin-bottom: 0;
      min-height: 27px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: inline-block;
      border: 0px solid grey;
      height: 27px;
    }
  }

  .viewLinksContainer{
    display: flex;
    flex-direction: column;
    margin-top: -18px;
    padding-right: 20px;
    text-align: right;
  }

  .viewWorkGroupLink {
    color: $accent-blue;
    min-width: 90px;
  }

  .orgChart {
    .circle {
      position: relative;
      border: 2px solid #999;
      border-radius: 100%;
      width: 50px;
      line-height: 50px;
      text-align: center;
      margin-top: 50px;
      background-color: #fff;
      z-index: 2;
    }
    .circle:first-child {
      margin-top: 0;
    }
    .circle:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      border: 1px dotted;
      border-width: 0 0 0 1px;
      width: 1px;
      height: 100px;
    }
  }

  .workGroup {
    background-color: transparent;
    border-right: 1px solid #fff;
    padding-right: 2%;
    padding-left: 2%;
    margin-bottom: 2rem;
    height: auto;
    list-style: none!important;
    margin-left: 21px !important;
    margin-right: 21px !important;

    li,
    .line {
      display: block;
      text-align: left;
      height: 45px;
      background-color: transparent;
      position: relative;
      margin-top: 7px;
      list-style: none!important;
    }
  }

  ul:last-child {
    border-right: none;
    list-style: none!important;
    margin-left: 21px !important;
    margin-right: 21px !important;

  }
}

.orgTitleWrapperSkeleton {
  border-bottom: 1px solid $pale-gray !important;
}
