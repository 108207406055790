@import "../../../assets/scss/index.module.scss";

.hrLocation {
  font-family: $Gotham;
  color: $charcoal;
  width: 100%;
  height: auto;

  .hrCssGrid {
    display: grid;
    display: -ms-grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: transparent;
    color: #444;
    grid-template-areas: "leftColumn rightColumn";
    -ms-grid-columns: 1fr 0px 1fr;
    -ms-grid-rows: auto;
    padding: 0 24px;

    /* phone */
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) and (orientation: portrait) {
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "leftColumn"
        "rightColumn";
    }

    /* Tablet */
    @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-template-columns: 1fr 1fr;
      -ms-grid-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: "leftColumn rightColumn";
    }

    .leftColumn {
      grid-column: 1;
      grid-row: 1;
      background-color: transparent;
      color: black;
      border-radius: 5px;
      width: 100%;
      min-width: 100%;
      -ms-grid-column: 1;
      -ms-grid-row: 1;

      /* phone */
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) and (orientation: portrait) {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      /* tablet */
      @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
    }

    .rightColumn {
      grid-column: 2;
      grid-row: 1;
      background-color: transparent;
      color: black;
      border-radius: 5px;
      width: 100%;
      min-width: 100%;
      -ms-grid-column: 3;
      -ms-grid-row: 1;

      /* phone */
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
        grid-column: 1;
        grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      /* tablet */
      @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
        grid-column: 2;
        grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
    }

    .columnList {
      margin-left: 0px;
      height: auto;
      list-style-type: none;
      padding-inline-start: 0px;
      width: 100%;

      li {
        display: block;
        text-align: left;
        min-height: 45px;
        background-color: transparent;

        .rowWrapper {
          background-color: transparent;
          width: 100%;

          .mailCodeWrapper {
            display: -webkit-inline-box;
            border: 0px solid grey;

            .helpLink {
              color: $accent-blue;
              display: inline-block;
              margin-left: 10px;
            }

            .helpLink:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }

          .label {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .value {
            width: 100%;
            height: auto;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            padding-left: 10px;
            min-height: 20px;
            padding-bottom: 3px;
          }

          .reportsToLink {
            color: $vibrant-blue;
            text-decoration: none;

            &:hover {
              color: $navy;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .titleWrapper {
    padding-bottom: 10px;
    margin-top: 26px;
    display: inherit;
    background-color: transparent;
    border-bottom: $charcoal 1px solid;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    .title {
      padding: 0;
      color: $charcoal;
      margin-bottom: 0;
      min-height: 27px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      /* phone */
      @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) and (orientation: portrait) {
        height: 21px;
        font-size: 18px;
      }
      @media (min-width: $bp-phone-min-landscape-events) and (max-width: 982px) and (orientation: landscape) {
        height: 55px;
      }
    }
  }
}

.borderBottomSkeleton {
  border-bottom: 1px solid $pale-gray !important;
}