@import "../../../assets/scss/index.module.scss";

.link {
  display: inline-block;
  text-decoration: none;

  .screenReaderOnly {
    position: absolute;
    overflow: hidden;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    color: transparent;
    background-color: transparent;
  }
}
