@import "../../../assets/scss/index.module.scss";

.header {
  padding-bottom: 7px;
  border-bottom: solid 4px $metal-grey;
}

.title {
  font-family: $Gotham;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: $charcoal-grey;
  margin-top: 0;
  margin-bottom: 0;
}

.seperator {
  margin: 0;
  border-bottom: 1px solid $metal-grey;
}