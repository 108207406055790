@import "../../../assets/scss/index.module.scss";

.megaMenuButton {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;

  @media (max-width: $bp-tablet-ipad-lanscape) {
    display: block;
    margin: auto;
    padding-left: 5px;
  }
  @media (max-width: $bp-phone-max) {
    display: flex;
    align-items: center;
    flex-direction: row;    
    margin: auto;
    padding-left: 0px;
  }

  span {
    width: 100%;
    padding: 15px 10px 15px 0;
    margin: auto;
    display: inline-block;
    text-align: left;
    font-family: nw-primary, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 19px;
    letter-spacing: normal;
    color: $charcoal;
    cursor: pointer;

    @media (max-width: 1131px) and (min-width: 1087px) {
      font-size: 14px;
    }

    @media (max-width: 1086px) and (min-width: $bp-tablet-ipad-lanscape) {
      font-size: 12px;
      padding: 18px 0;
    }

    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      color: $dark-blue;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      width: 97%;
    }
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      font-size: 18px;
      color: $dark-blue;
      font-weight: 600;
      width: 89%;
      text-align: left;
      padding-left: 0px;
      margin-left: 20px;
    }
  }

  &:hover,
  &:focus {
    background: $ultralight-gray;
    span {
      color: $charcoal;

      @media (max-width: $bp-tablet-ipad-lanscape) {
        color: $charcoal-grey;
      }
    }
  }

  .megaMenuChevron {
    display: block;
    margin: 2px 0px 10px -5px;

    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      margin-top: 10px;
    }

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: inline-block;
      height: 25px;
      width: 25px;
      margin-left: -8px;
    }
  }

  .megaMenuButtonArrow {
    border-color: $charcoal;
    border-right: 2px solid $charcoal;
    border-bottom: 2px solid $charcoal;
    width: 8px;
    height: 8px;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      height: 12px;
      width: 12px;
      border-bottom: 4px solid $dark-blue;
      border-right: 4px solid $dark-blue;
      transform: rotate(-45deg);
    }

  }
}

/* search page */
.megaMenuSearchButton {
  height: 100%;
  display: block;
  border: none;
  border-bottom: solid 1px $silver-two;
  margin: auto auto auto 50px;
  width: 94%;
  padding-left: 20px;
  
  @media (max-width: $bp-tablet-ipad-lanscape) {
    width: calc(90%);
    display: block;
    margin: auto;
    border-bottom: solid 1px $silver-two;
    margin-left: 30px;
    padding-left: 20px;
  }

  @media (max-width: $bp-phone-max) {
    display: flex;
    margin: auto;
    padding-left: 0px;
  }

  span {
    padding: 15px 0;
    margin: auto;
    display: inline-block;
    font-family: $Gotham;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    cursor: pointer;
    /* search */
    text-align: left;
    width: 95%;

    @media (max-width: 1131px) and (min-width: 1087px) {
      font-size: 14px;
    }

    @media (max-width: 1086px) and (min-width: $bp-tablet-ipad-lanscape) {
      font-size: 12px;
      padding: 18px 0;
    }

    @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
      text-align: left;
      width: 95%;
    }
    @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
      width: 89%;
      text-align: left;
    }
  }

  &:hover,
  &:focus {
    background: rgba(226, 227, 229, 0.6);
    span {
      color: $charcoal;

      @media (max-width: $bp-tablet-ipad-lanscape) {
        color: $charcoal-grey;
      }
    }
  }

  .megaMenuChevron {
    display: inline-block;

    @media (max-width: $bp-tablet-ipad-lanscape) {
      display: inline-block;
      height: 25px;
      width: 25px;
      margin-left: -8px;
    }
  }

  .megaMenuButtonArrow {
    border-color: $charcoal-grey;
  }
}
