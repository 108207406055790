@import "../../../assets/scss/index.module.scss";

.container {
  padding: 0 15px;
  background-color: $ultralight-gray;
  max-height: 395px;
  min-height: 250px;
  font-family: $Gotham;

  @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
    max-height: 500px;
  }

  .header {
    color: $charcoal;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid $charcoal;
    padding-top: 10px;
  }

  .emptyAnnouncements {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    height: 75%;

    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      padding: 20px 0;
    }
  }

  .emptyAnnouncementsText {
    text-align: center;
    font-size: 18px;
    max-width: 213px;
  }

  .bellIcon {
    width: 70px;
  }
}
