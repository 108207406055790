@import "../../../assets/scss/_fonts.module.scss";
:root {
  --font-family: $Gotham !important;
}

@media (min-width: 767px) and (max-width: 1025px){

  .vertical {
    border-left: 2px solid #D8D9DA;
    position:absolute;
    left: 50%;
  }
  
  ul.sub-list{
    width: 50%!important;
    margin-left: 0px!important;
  }

  ul.sub-list.people, 
  ul.sub-list.people-app{
    position: sticky !important;
    padding-left: 4% !important;
    margin-left: 0px !important;
    left: 50%!important;
  }

  ul.sub-list.content,
  ul.sub-list.content-app{
    margin-left: 0px !important;
    padding-left: 4% !important;
  }

  ul.sub-list.content{
    padding-left: 4% !important;
  }


  #suggested-column-left {
    float: left;
    width:70px!important;
    padding-top: 10px !important;
    text-align: left !important;
  }
  
  #suggested-column-right {
    float: left !important;
    width: 50% !important;
    padding-left: 15px!important;
    padding-top: 9px!important;
  }

  /* drop down people */
  .marginBottom{
    margin-bottom: -5px!important;
  }

  #suggested-job-title{
      width: 250px !important;
  }

  ul.sub-list.content li.ui-menu-item, 
  ul.sub-list.content-app li.ui-menu-item{  
    padding-top: 6px !important;
    padding-bottom: 7px !important;
    background-color: white !important;
    width: 90% !important;
    padding-left: 0px;
    line-height: 19px !important;
  }

  ul.sub-list.content li.ui-menu-item:hover, 
  ul.sub-list.content-app li.ui-menu-item:hover{  
    background-color: #bbb !important;
  }
  
}

@media (min-width: 1025px){
    ul.sub-list{
      width: 50%!important;
    }

    ul.sub-list.people, 
    ul.sub-list.people-app{
      position: sticky;
      padding-left: 6% !important;
      margin-left: 0px !important;
      left: 50% !important;
    }

    ul.sub-list.content,
    ul.sub-list.content-app{
      margin-left: 0px !important;
      padding-left: 6% !important;
    }

    .ui-autocomplete .ui-menu-item{
      padding-bottom: 0px!important;
      padding-left: 2px !important;
    }

    .vertical {
      border-left: 1px solid #D8D9DA;
      position:absolute;
      left: 50%;
    }

    #suggested-column-left {
      float: left;
      width:70px!important;
      padding-top: 10px !important;
      text-align: left !important;
    }
    
    #suggested-column-right {
      float: left !important;
      width: 75% !important;
      padding-left: 15px!important;
      padding-top: 9px!important;
    }

    /* drop down people */
    .marginBottom{
      margin-bottom: -5px!important;
    }

    #suggested-job-title{
      width: 275px !important;
  }

  ul.sub-list.content li.ui-menu-item, 
  ul.sub-list.content-app li.ui-menu-item{  
    padding-top: 6px !important;
    padding-bottom: 7px !important;
    background-color: white !important;
    width: 90% !important;
    padding-left: 0px;
    line-height: 19px !important;
  }

  ul.sub-list.content li.ui-menu-item:hover, 
  ul.sub-list.content-app li.ui-menu-item:hover{  
    background-color: #bbb !important;
  }
[id^="ui-id-"] {
  width: 99.8% !important;
}
}


@media (max-width: 767px) {
  /* #ui-id-1{ */
  [id^="ui-id-"]{
    vertical-align: middle!important;
    width: 99.8% !important;
    height: auto !important;
  }

  a[id^="ui-id-"]{
    width: 80vw!important;
  }

  /* drop down people */
  .marginBottom{
    margin-bottom: 5px!important;
  }

  ul.sub-list{
    width: 95%!important;
  }

  .vertical {
    display: none;
  }

  #suggested-column-left {
    float: left;
    width:70px!important;
    height: 70px !important;
    text-align: left !important;
  }
  
  #suggested-column-right {
    float: left !important;
    width: 70% !important;
    padding-left: 15px!important;
    height: 135px!important;
  }

  .ui-autocomplete .ui-menu-item{
    padding-bottom: 0px!important;
    padding-left: 2px!important;
  }

  #suggested-job-title{
    width: 200px !important;
  }

  ul.sub-list.content li.ui-menu-item, 
  ul.sub-list.content-app li.ui-menu-item{  
    padding-top: 6px !important;
    padding-bottom: 7px !important;
    background-color: white !important;
    width: 95% !important;
    padding-left: 0px;
    line-height: 19px !important;
  }

  ul.sub-list.content li.ui-menu-item:hover, 
  ul.sub-list.content-app li.ui-menu-item:hover{  
    background-color: #bbb !important;
  }
}

/* tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  [class^='Office365Image']{
    right: calc(3vw - 5px) !important;
  }

  #bMagnifying{
    right: 10px !important;
  }

  #suggested-job-title{
    width: 250px !important;
  }

}

/* 1280*/
@media only screen and (min-width: 1023px) {
  [class^='Office365Image']{
    right: calc(3vw - 10px) !important;
  }

  #bMagnifying{
    right: 0px !important;
  }

  #suggested-job-title{
    width: 275px !important;
  }

}

@media only screen and (min-width: 1500px) {
  [class^='Office365Image']{
    right: 10px !important;
  }

  #bMagnifying{
    right: 0px !important;
  }

  #suggested-job-title{
    width: 275px !important;  
  }

}

/* #ui-id-1{ */
[id^="ui-id-"]{
  border-color: #bbb;
}

ul.sub-list{
  width: 50%;
  list-style: none!important;
}

/* #ui-id-1 ul{ */
[id^="ui-id-"] ul{
  float: left !important;
  margin-left: 30px!important;
  margin-top: 10px!important;
  margin-bottom: 1em!important;
  list-style: none!important;
}

/* #ui-id-1 li{ */
[id^="ui-id-"] li{
  padding-right: 2em!important;
  line-height: 1.5em!important;
  font-size: 18px!important;
}

li#people, li#content{
  font-size: 36px!important;
  font-family: var(--font-family);
  font-weight: bold!important;
  border-bottom: none!important;
  color: #141B4D;
}

.noMarginBottom{
  margin-bottom: 0 !important;
}

/* .ui-widget-content li{ */
[aria-describedBy="Content"]{
  padding-bottom: 3px !important;
  width: 90% !important;
  margin-left: 0px !important;
}

/* for people results - box border when not focused */
.suggested-person-result {
  width: inherit !important;
  height: 100px;
  align-content: left !important;
  text-align: left !important;
  cursor: pointer !important; 
}

.suggested-us-phone{
  width: 100% !important;
  line-height: 1!important;
  text-align: left;
  font-family: var(--font-family);
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
}

/* for people results -  preferred first/last name */
#suggested-preferred-name{
  text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold !important;
    line-height: 1!important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
    width: 275px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
}

#suggested-job-title{
  text-align: left;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal !important;
  line-height: 1!important;
  font-size: 14px;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
}

#suggested-login-id{
  text-align: left;
  font-family: var(--font-family);
  font-style: normal;
  line-height: 1!important;
  font-size: 14px;
  letter-spacing: 0px;
  color: #171717;
  text-transform: uppercase;
  opacity: 1;
  clear: both;
}

#suggested-login-id:hover {
  text-decoration: underline #0047bb !important;
}
.ui-autocomplete .ui-state-focus,
.ui-autocomplete .ui-state-active {
  cursor: pointer;
  background: transparent !important;
  border: none!important;
  padding: 0px !important
}

.ui-menu-item-wrapper .ui-state-active,
.ui-menu-item-wrapper .ui-state-focus{
  border-left: none!important;
  border-right: none!important;
  padding-bottom: 0px!important;
  margin-bottom: 0px!important;
}

.ui-menu-item-wrapper.ui-state-active:hover, 
.ui-state-focus:hover{
  background-color: transparent!important;
  border-bottom: 0px!important;
  padding-bottom: 0px!important;
  margin-bottom: 0px!important;
  transition: none!important;
} 

[aria-describedBy="People"]:hover {
  background: transparent!important;
  padding-bottom: 0px!important;
  margin-bottom: 0px!important;
}

form.search-field ul.ui-autocomplete li .ui-menu-item-wrapper, .ui-autocomplete li a{
  transition: none!important;
  padding: 0px!important;
}

/* Clear floats after the columns */
.suggested-row {
  width: 100% !important;
}

.suggested.media.center{
  padding-bottom: 0px!important;
  padding-left: 0px!important;
  padding-top: 2px!important;
  width: 100%!important;
  height: inherit !important;
}

.ui-autocomplete{
  box-sizing: border-box !important;
  border-bottom: 1px solid #D8D9DA !important;
  border-top: 1px solid #bbb !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: 0px 8px 19px #00000040 !important;
}

button.btn.btn-link.mb-no-print.magnifyingG {
  right: 5px !important;
}

.mb-header-search-form{
  width: inherit !important;
}

.noResults{
  font-weight: bold;
  font-size: 18px;
}

#divUl {
  max-width: 1250px;
  margin: auto;
}

#bSeeAll{
  padding: 0 !important;
  font-size: 16px !important;
  color: #0047BB;
  font-weight: 600;
}

.unmatched {
  font-weight: 400 !important
}

.matched{
  font-weight: 700 !important
}

