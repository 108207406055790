@import "../../../assets/scss/index.module.scss";

.column {
    height: 60px;
    padding-bottom: 46px;
    text-align: center;
}

// td {
//     padding-left: auto;
//     padding-right: auto;
// }

p {
    
    padding-left: 3px;
    padding-right: 3px;
}

.enabled {
    text-transform: uppercase;
    font-family: $Gotham;
    font-size: 28px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 1px;
    text-align: center;
    color: #171717;
    width:100%;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    position: static;
}
.enabled:hover {    
    text-decoration: underline;
    text-decoration-color: #0047bb;
    text-decoration-thickness: 4px;
    text-align: center;
    
}
.letterBorder {    
    text-decoration: underline;
    text-decoration-color: #0047bb;
    text-decoration-thickness: 4px;
    
}
.disabled {
    text-transform: uppercase;
    font-family: $Gotham;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #d0d3d4;
    width:100%;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;
    position: static;
}

// span {
//     display: inline-block;
//     padding-left: 12px;
//     padding-right: 12px;
//     position: static;
// }
