@import "../../../assets/scss/index.module.scss";

.errorStyles {
  font-family: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-align: left;
  padding-left: 5%;
}

.errorMessage{
  padding-top: 15%;
  font-size: 40px;
}

.actionMessage{
  font-size: 28px;
  font-weight: bold;
}