@import "../../../assets/scss/index.module.scss";
@import "../../../assets//scss/fonts.module.scss";

// popup styles
.event_modal {
  position: fixed;
  z-index: 20000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 100px;
}

.modal_content {
  background-color: white;
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 776px;
  height: auto;
  border: 1px #FFFFFF;
}

.close {
  color: black;
  float: right;
}

.close:hover {
  color: cyan;
  cursor: pointer;
}

.date_header {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  width: 87px;
  height: 81px;
  border: 1px solid #707070;
  padding: 5px 13px 17px 9px;
  margin-left: 10px;
  margin-right: 25px;
  line-height: 1;
  float: left;
}

.header_title_text {
  font-family: $Gotham;
  font-weight: medium;
  color: #171717;
}

.eventStartDate {
  font-family: $Gotham;
  // font-weight: Book;
  color: #171717;
  margin: 0.618em 0;
}

.header_title {
  height: 81px;
  margin-bottom: 19px;
}

.popup_titlebar {
  background-color: #30cdd7;
}

.close_button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: transparent;
}

.close_btn {
  font-size: 12px;
}

.close_div_button {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.image_close_div_button {
  width: 25px;
  height: 25px;
  margin-bottom: 3px;
}

.inner_close_div_button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup_header {
  margin-top: 40px;
}

.modal_footer {
  border-top: 2px solid #D0D3D4;
  padding-top: 5px;
  margin-left: 21px;
  margin-right: 21px;
  bottom: 40px;
  display: block;
  width: 740px;
}

.modal_footer_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: 10px;
}

.footer_add_to_calendar_button {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: $Gotham;
  font-weight: 600;
  font-size: 13px;
  background-color: #6ECEB2;
  border: 1px #6ECEB2;
  width: 120px;
  margin-right: 15px;
  outline: 0;
  color: #141B4D;

  &:hover {
    background-color: #141B4D;
    color: #6ECEB2;
  }
}

.description {
  font-size: 12pt;
  font-family: $Gotham !important;
  // font-weight: Book;
  color: #0047BB;
  margin-top: 30px;
  margin-left: 1px;
}

.location_desc_content {
  padding: 5px;
}

.header_color {
  height: 40px;
}

.model_description_content {
  padding-right: 20px;
  padding-left: 20px;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.loc_image {
  width: 18px;
  height: 25px;
  margin-right: 10px;
  background-size: contain;
}

.event_type_image {
  width: 20px;
  height: 18px;
  margin-right: 10px;
  background-size: contain;
}

.event_time_image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-size: contain;
}

.right_div_buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}