@import "../../../assets/scss/index.module.scss";

.button {
  width: 200px;
}

.noBorderButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .iconCircle {
    min-width: 40px;
    min-height: 40px;
  }

  .userCardBtnText {
    color: $charcoal;
    font-family: $Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
