@import "../../../../assets/scss/index.module.scss";

.customize {
  bottom: 8px;
  right: 0;
  display: flex;
  font-family: $Gotham !important;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;

  & span {
    align-self: flex-end;
  }
  & img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    height: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;

    & img {
      width: 19px;
      height: 19px;
    }
  }
}

.done {
  justify-content: center;
}

.subHeader {
  width: 763px;
  height: 16px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 3px;
  color: var(--1-primary-white);
}

.subSectionHeader {
  width: 100%;
  min-height: 16px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-top: 5px;
  color: rgb(216, 210, 210);
}

.selectWidgets {
  margin-top: 20px;
  margin-left: 20px;
}

.widgets {
  color: #ffffff;
  padding-top: 25px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.selectOne {
  background: #6eceb2;
  color: #141b4d;
}

.widget {
  width: 32%;
  min-height: 200px;
  margin-bottom: 30px;
  @media (min-width: $bp-phone-max-events-plus) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 47%;
  }
  @media (max-width: $bp-phone-max-events) {
    width: 100%;
    margin-bottom: 60px;
  }
}

.section {
  text-align: left;
  position: static;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.sectionDesc {
  text-align: left;
  position: static;
  padding-top: 3px;
  padding-bottom: 7px;
  padding-left: 15px;
  font-size: 15px;
  font-weight: normal;
  color: rgb(216, 210, 210);
}

.hrSwitch {
  border: solid 2px #6eceb2;
  font-size: 15px;
  color: #141b4d;
  background-color: #eee;
  border-radius: 5px;
  width: 100%;
}

.hrSwitchSelected {
  color: gray;
}
