@import "../../../assets/scss/index.module.scss";

.ErrorDiv {
  font-family: $SegoeUI;
  text-align: center;
  min-height: 350px;

  .heading {
    font-family: $SegoeUI;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    margin-top: -1px;
    padding-bottom: 7px;
    text-align: left;
    border-bottom: solid 4px $steel-grey;
  }

  h2 {
    text-align: center;
    padding-top: 150px;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $steel-grey;
  }

  .noImageSrcStyle {
    padding-top: 30px;
  }

  p {
    width: 100%;
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $charcoal-grey;
    background-color: transparent;
    padding-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;

    .errorLink {
      color: $accent-blue;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
