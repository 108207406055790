@import "../../../../assets/scss/index.module.scss";

.showHide {
  color: $hr-connect-accent;
  position: absolute;
  right: 0px;
  top: -15px;
  cursor: pointer;
  text-align: center;
  width: 50px;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  &:hover {
    text-decoration: none;
    color: $hr-connect-accent
  }
  .screenReaderOnly {
    position: absolute;
    overflow: hidden;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    color: transparent;
    background-color: transparent;
  }
}