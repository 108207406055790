@import "../../../assets/scss/index.module.scss";

.hamburgerButton {
  background-color: transparent;
    border: none;
  width: 50px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 5px;
.hamburgerMenuIcon{
  width: 16px;
  height: 16px;
}
  .nwMmHam {
    box-sizing: content-box;
    width: 20px;
    height: 2px;
    border-width: 10px 0 10px 0;
    border-color: #fff;
    border-style: solid;
    // background-color: $nw-grey;
    position: absolute;
    left: 40%;
    margin-left: -12px;
    top: 2px;
    margin-top: 0;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &:before,
    &:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: $nw-grey;
      border-radius: 2px;
      transition: 0.2s;
      left: 0;
    }
    &:before {
      top: -8px;
    }
    &:after {
      top: 8px;
    }

    &.nwMmHamOpen {
      // background-color: transparent !important;

      &:before {
        transform: translate3d(0, 8px, 0) rotate(-45deg);
      }
      &:after {
        transform: translate3d(0, -8px, 0) rotate(45deg);
      }
    }
  }

  .hamburgertText {
    display: block;
    width: 100%;
    // text-align: center;
    color:#FFFFFF;
    font-size: 8px;
    margin-top: 5px;
    font-weight: 600;
    font-family: $Gotham;
  }
}
