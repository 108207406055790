@import "../../../assets/scss/index.module.scss";



.orgContainer {
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  overflow-y: auto;
  margin: 20px;
  p {
    margin: 0px !important;
    padding: 0px !important;
    font-weight: 400;
  }

  
.department {
  font-size: 18px;
  font-weight: 700;
}

.levelOne {
  border: 1px solid #171717;
  border-radius: 8px;
  margin: 20px;
  flex: 1;
  font-size: 16px;
  padding: 10px;
  .name{
    font-weight: 700;
    color: #171717 !important;
    border:0px;
    text-align: center;
    width:100%;
    background: none;
  }
  .selectedName{
    text-shadow: 0 0 5px #F9E27D;
    font-weight: 700;
    color: #171717 !important;
    border:0px;
    text-align: center;
    width:100%;
    background: none;
  }
}

.levelTwo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.levelTwoCard {
  border: 1px solid #171717;
  border-radius: 8px;
  margin: 5px;
  font-size: 14px;
  padding: 10px;
  min-height: 120px;
  .name{
    font-weight: 700;
    color: #171717 !important;
    border:0px;
    text-align: center;
    width:100%;
    background: none;
  }
  .selectedName{
    text-shadow: 0 0 5px #F9E27D;
    font-weight: 700;
    color: #171717 !important;
    border:0px;
    text-align: center;
    width:100%;
    background: none;
  }
}

.levelThreeWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.levelThree {
  font-size: 12px;
  text-align: start;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: 700;
  color: #171717 !important;
  background: none;
  border:0px;
  text-align: left;
  width:100%;
  &:hover{
    color: $nw-blue!important;
  }
}

.selectedName{
  text-shadow: 0 0 5px #F9E27D;
  font-weight: 700;
  color: #171717 !important;
  border:0px;
  background: none;
  text-align: left;
  width:100%;
  &:hover{
    color: $nw-blue!important;
  }
}
.row::after {
  display: none;
}

.levelTwoWrapper {
  display: flex;
  flex-direction: row;
}
}

.ui-helper-hidden-accessible {
  position: absolute!important;
  left: -5000px!important;
  display: none;
    visibility: hidden;
}