@import "../../../assets/scss/index.module.scss";

.contactInfo {
  top:-50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  text-align: center;
  font-family: $Gotham;
  color: $charcoal;
  padding-bottom: 15px;

  .imageWrapper {
    position: relative;
    top: -50px;
    background-color: transparent;
    min-width: 43%;
    max-width: 45%;
    width: 50%;
    min-height: 100px;
    text-align: center;
  }

  .employeeImage {
    border-radius: 50%;
    border: 3px solid white;
    background-color: $page-bg-color;
  }


  .identification {
    margin-top: -50px;
    font-size: 14px;
    padding: 11px 0 14px 0;
    background-color: transparent;

    .name {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .jobTitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .department {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }


  }

  .line{
    border-bottom: $charcoal 1px solid;
    width: 100%;
    margin: 0 30px;
  }

  .lineSkeleton{
    border-bottom: $pale-gray 1px solid;
    width: 100%;
    margin: 0 30px;
  }

  .buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactMethods {
    display: flex;
    flex-direction: column;

    font-family: $Gotham;
    margin-top: 20px;

    .userShortName {
      color: $vibrant-blue;
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      line-height: normal;
    }

    .phoneWrapper {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      div {
        display: inline-block;
        padding-top: 11px;
        padding-bottom: 7px;
      }
    }

    .emailAddress {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .chatIcon {
      width: 20px;
      height: 17px;
    }

    .emailIcon {
      width: 17px;
      height: 20px;
    }

    .userCardBtnContainer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 30px;
      gap: 12px
    }

    .userCardEmailBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 200px;
      height: 40px;

      font-family: $Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      gap:10px;

      color: $white;
      background-color: $vibrant-blue;
      cursor: pointer;
    }

    .userCardEmailBtn:hover {
      background-color: $medium-blue;
      color: #ffffff;
    }

    .userCardChatBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 200px;
      height: 40px;

      font-family: $Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      gap:10px;
      color: $white;
      background-color: $dark-min;
      cursor: pointer;
    }

    .userCardChatBtn:hover {
      background-color: $mint;
      color: #ffffff;
    }

    .circleButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      text-decoration: none;
    }
  }
}
