.modal-header,
.modal-body,
.modal-footer {
  border: 0 !important;
}
.modal-header {
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4f53;
  margin-bottom: 25px !important;
  padding: 0 !important;
}
.modal-content {
  border-radius: 10px !important;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.23) !important;
  border: solid 1px #979797 !important;
  width: 100%;
  padding: 30px !important;
  padding-bottom: 0 !important;
  bottom: 0px;
  display: inline-block !important;
}
.modal-body {
  margin-bottom: 13px !important;
  padding: 0 !important;
}
.modal-footer {
  padding: 0px !important;
  padding-bottom: 25px !important;
  /* padding-right: 25px !important; */
}
.modal-title {
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-weight: bold;
  width: 100%;
}

.modal-backdrop.fade {
  opacity: 0.5 !important;
}

.modal-dialog{
  margin: 0 !important;
}
.modal-open {
  overflow: initial !important;
  overflow-x: hidden !important;
  padding-right: 0 !important;
}
.modal-dialog-centered {
  min-height: calc(100% - 23.5rem) !important;
}

.fade {
  opacity: 1 !important;
}
.modal{
  top: 0 !important;
  background-color:#ffffff00!important;
  box-shadow:none!important;
}