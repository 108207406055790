@import "../../../assets/scss/index.module.scss";

.payDayBar {
  color: black;
  .nextpayday {
    width: 100%;
    background-color: $silver-two;
    padding: 0.5em 0;
    margin-top: 0px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .npLabel {
      width: 35%;
      padding-left: 0.5em;
      font-family: $SegoeUI;
      font-size: 14px;
      font-weight: 600;
      color: $charcoal-grey;

      display: block;
      @media (min-width: $bp-phone-min) {
        font-size: 11px;
      }

      @media (min-width: $bp-tablet-min) {
        width: 30%;
      }
      @media (min-width: $bp-desktop) {
        width: 35%;
      }
    }

    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      @media (min-width: $bp-phone-min) {
        font-size: 10px;
        width: 70%;
      }
      @media (min-width: $bp-tablet-min) {
        font-size: 10px;
        width: 100%;
      }

      .npItem {
        font-size: 12px;
        color: $charcoal-grey;
        padding: 0 0.25em;

        display: block;

        @media (min-width: $bp-phone-min) {
          font-size: 10px;
          padding: 0;
        }

        @media (min-width: $bp-tablet-min) {
          padding: 0 0.25em;
        }

        &:first-child {
          font-weight: bold;
        }

        a {
          color: $charcoal-grey;

          padding: 0 0.5em;
          text-decoration: none;
          cursor: auto;
          outline: 0;
          @media (min-width: $bp-phone-min) {
            font-size: 10px;
            padding: 0;
          }

          @media (min-width: $bp-tablet-min) {
            padding: 0 0.5em;
          }
        }
      }
      &.npItemActive {
        font-weight: bold;
      }
    }
  }
}
