@import "../../../assets/scss/index.module.scss";

%chevron-styles {
  display: inline-block;
  border-right: 4px solid $dark-blue;
  border-bottom: 4px solid $dark-blue;
  width: 12px;
  height: 12px;
}

.nwChevron {
  display: inline-block;
  height: 25px;
  width: 25px;

  .chevronArrowRight {
    @extend %chevron-styles;
    transform: rotate(-45deg);
  }

  .chevronArrowLeft {
    @extend %chevron-styles;
    transform: rotate(135deg);
  }

  .chevronArrowUp {
    @extend %chevron-styles;
    transform: rotate(-135deg);
  }

  .chevronArrowDown {
    @extend %chevron-styles;
    transform: rotate(45deg);
  }
}
