@import "../../../assets/scss/index.module.scss";

.campaignBanner {
  text-align: center;
  margin-top: 6px;
  height: 186px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    display: block;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    margin-top: 0px;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      position: relative;
      max-width: 100%;
    }
  }

  .screenReaderOnly {
    position: absolute;
    overflow: hidden;
    left: -9999em;
    top: 0;
    width: 1px;
    height: 1px;
    color: transparent;
    background-color: transparent;
  }
}