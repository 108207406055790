@import "../../../../../assets/scss/index.module.scss";

$guage-size: 60px;
$guage-width: 20px;

.showRow {
  position: relative;
}

.beRow {
  margin: 0px !important;
  text-align: left;
}



.openBenefitElectionButtonLink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-weight: bold;
  text-align: center;
  color: $hr-connect-background;
  background-color: $hr-connect-accent;
  margin-top: 10px;
  font-size: 18px;

  &:hover,
  &:focus {
    background-color: $hr-connect-accent-4;
    text-decoration: none;
    color: $hr-connect-background;
    outline: none;
  }
}

.content1 {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin: auto;
}

.content2 {
  font-family: Arial, sans-serif;
  font-size: 13px;
  text-align: left;
  line-height: normal;
}

.content3 {
  font-family: Arial, sans-serif;
  font-size: 22px;
  text-align: left;
  color: adjust-hue($color: #6ECEB2, $degrees: 0);
  line-height: normal;
  margin-bottom: 7px;
}

.infoContent1 {
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  line-height: normal;
  margin: 10px 2px 2px 10px;
}

.infoContent2 {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: normal;
  margin: 10px 2px 2px 10px;
  padding-bottom: 3px;

}

.infoIcon {
  max-width: 60%;
  margin: auto;
}

.infoDiv {
  background-color: #008775;
  float: left;
  width: 12%;
  display: flex;

}

.infoContentDiv {
  background-color: white;
  color: black;
  float: right;
  width: 88%;
}

.benefitElectionBottomSection {
  min-height: 160px;
  text-align: center;
  position: static;
  margin: 15px, 0px;
}

.btnHolder {
  margin-top: 9px;
  display: flex;
  a:nth-of-type(2) {
    @media (min-width: $bp-phone-max-events) and (max-width: $bp-desktop-max) {
      min-width: 118px !important;
    }  
  }
}