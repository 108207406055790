@import "../../../assets/scss/index.module.scss";

.moreNews {
  text-align: left;
  font-family: $Gotham;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $accent-blue;

  & .link {
    color: $accent-blue;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
  }
}

.continueReadingStyles {
  @media (min-width: $bp-phone-min) and (max-width: ($bp-phone-max-events)-1) {
    border-bottom: 1px solid $med-grey;
    padding-bottom: 18px;
    padding-top: 10px;
  }

  .storyLink {
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $accent-blue;
    text-align: right;
    margin-top: 5px;
    position: relative;
    left: 6px;
    float: right;
    &:hover {
      text-decoration: underline;
    }

    @media (min-width: $bp-phone-min) and (max-width: ($bp-phone-max-events)-1) {
      float: left;
      position: relative;
      left: 0px;
      margin-top: 0;
    }

    .linkText {
      margin-top: 5px;
      color: $accent-blue;
    }

    .arrow {
      display: inline-block;
      width: 20px;
      box-shadow: none;
      font-size: 8px;
    }
  }
}
.commonTools {
  // border-bottom: solid 1px $med-grey;

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    margin-bottom: 29px;
  }
}

.customizeTools {
  font-family: $SegoeUI;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $accent-blue;
  text-align: right;
  position: relative;
  left: 12px;

  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max-events) {
    max-width: 100%;
  }

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    margin-top: -29px;
    margin-right: -11px;
    // left: 15px;
    left: 0;
  }
}

.heading {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $charcoal;
  margin: 5px 0;
  font-family: $TiemposHeadline;

  @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet) {
    font-size: 17px;
  }
}

.date {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $light-burgundy;
  margin-bottom: 0;
  margin-top: 0;
}
