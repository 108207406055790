@import "../../../assets/scss/index.module.scss";
@import "../../../assets//scss/fonts.module.scss";

.employeeModalContainer::-webkit-scrollbar {
  display: none !important;
}

.employeeModalContainer {
  padding: 0px!important;
  min-height: 0!important;
  height:fit-content!important;

  overflow: auto !important;
  opacity: 1;
  margin: 0!important;
  padding-top: 1rem!important;
  cursor: default;
  max-width: 90vh !important;
  width: 100%;
  left: 50% !important;
  transform: translateX(-50%);
  bottom: 50px;
  position: fixed !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  border: 0 !important;
  top: 15% !important;

  & .modalCloseButton {
    float: right;
    cursor: pointer;
    line-height: 1.1;
    font-weight: 600;
    border: none;
    background-color: transparent;

    & span {
      font-size: 8px;
      display: block;
      margin-left: -6px;
      font-family: $SegoeUI;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal-grey;
      margin-top: 3px;
    }
  }

  & .modalTitleText {
    font-size: 20px;
    font-weight: bold;
    width: 80%;
    display: inline-block;
    font-family: $SegoeUI;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
  }

  & .modalContent,
  .modalSubContent {
    font-family: $SegoeUI;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #231f20;
  }

  & .modalContent {
    margin-bottom: 25px;
  }

  & .modalSubHeading {
    font-family: $SegoeUI;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #231f20;
  }

  & .continueButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 4px;
    background-color: $accent-blue;
    background-image: none;
    font-family: $SegoeUI;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    bottom: 4px;
    padding: 4px 12px;
  }
}
