@import "../../../assets/scss/index.module.scss";

.officeBarContainer {
  width: 100%;
  position: fixed;
  height: 81px;
  top: 0;
  background-color: $vibrant-blue;
  z-index: 999;
  border-bottom: 4px solid $mint;
  padding: 0px 15px;

  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
    display:none;
  }

  .nwImage {
    margin-top: -5px;
    padding-right: 10px;
    margin-right: 10px;
  }

  .insideMainLogoWrapper{
    text-align: center;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex:4;
  }

  .insideMainLogo{
    height:52px;
  }
  .dateMainWrapper{
    display: flex;
    align-items: flex-end;
    flex:2;
  justify-content: flex-end;
  }

  .dateWrapper{
    position: absolute;
      bottom: 12px;
      flex-direction: row;
      display: flex;
  }

  .dateWrapper span{
    color:#FFFFFF!important;
    text-align: right;
    font-size: 18px!important;
    font-family: $Gotham;
    font-weight: 400;
  }

  .dateWrapper_skeleton{
      position: absolute;
      bottom: 12px;
      flex-direction: row;
      display: flex;
      width: 296px;
      height: 40px;
      background: #1F74DB;
      animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  @keyframes example {
    from {opacity: 1;}
    to {opacity: 0.7;}
  }
}

.insideHeaderSkip {
  position: absolute;
  top: -100px;
  overflow: hidden;
  opacity: 0;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  z-index: 15000;
  text-align: center;
  background-color: #fff;
  border: 1px solid #bcbdbc;
  padding: 5px 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out;
  transition: opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out, top 0s .2s;
  transition: transform .2s ease-out, opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out
}

.insideHeaderSkip:focus {
  top: 10px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
  transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out
}
