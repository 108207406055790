@import "./assets/scss/index.module.scss";
.App {
  margin: auto;
  position: relative;
  margin-top: 30px;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    margin-top: 30px;
}
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    margin-top: 30px;
  }

  @media (device-width:767px),
  (max-width:767px) {
    padding-top: 0;
  }
}
.ui-helper-hidden-accessible {
  position: absolute!important;
  left: -5000px!important;
}
.headerWrapper{
  position: sticky;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 9;
}
.appWrapper{
  margin: auto;
}
.padded {
  width: 100% !important;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1260px) {
    width: 92.5% !important;
  }
}

body {
  overflow-x: hidden;
  max-width: 100%;
}

.documentsAndCalendar {
  display: table;
  width: 100%;
  margin-bottom: 45px;
}



.searchBox {
  right:0px;
  left:0px;
  top:50px;
  width:100%;
  background: #ffffff;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    position: relative;
    top: 0px;
    z-index: 1; 
    flex-direction: column-reverse;
}
@media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  position: relative;
  top: 0px;
  z-index: 1; 
  flex-direction: column-reverse;
  }
}
//SeeAllAnnouncementsView.css
.announcements_container {
  min-height: 60vh;
  margin: auto;
  margin-bottom: 70px;
  width: 100% !important;
  max-width: 1200px;
}

[id^="announcement-"]:hover {
  color: #0072cf !important;
  text-decoration: none !important;
}

[id^="announcement-"] {
  color: inherit !important;
}
@media (max-width: 1260px) {
  .announcements_container {
    width: 92.5% !important;
  }
}

.announcements_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: #171717;
  font-size: 18px;
  margin: 0;
  list-style: none;
}

li.single_announcement {
  font-weight: bold !important;
  text-decoration: none !important;
}

.announcement_link {
  color: inherit;
}

.announcements_noResults {
  background-size: 150px;
  display: flex;
  align-items: flex-end;
  min-height: 220px;

}

@media (min-width: 320px) and (max-width: 1024px) {
  .announcements_noResults {
    min-height: 230px;
  }
}

.announcements_title {
  font-size: 36px;
  font-family: nw-primary, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-weight: bold;
  color: #171717;
  border-bottom: 2px solid #171717;
  margin-bottom: 26px;
  padding-bottom: 8px;
}

//NwCommonTools.module.scss
.customize_tools_page {
  max-width: 1200px;
  min-height: 50vh;
  margin: 25px auto auto auto;
  font-family: $Gotham!important;
  .page_heading{
    font-family: $Gotham!important;
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 40px;
    @media (min-width: $bp-phone-min) and (max-width: $bp-tablet-ipad-lanscape) {
      text-align: center;
      }
  }
  .customize_tools_content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 37px;

    @media only screen and (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
      flex-direction: column;
    }

    

  
  }
}
//AllAvailableToolsWidget.module.scss
.all_available_tools_widget {
  width: 586px;

  @media only screen and (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 80%;
    margin: 20px auto auto auto!important;
  }

  .all_available_tools_heading {
    border-bottom: 2px solid #171717;
    display: flex;

    .all_available_tools_title {
      flex: 8;
    }
    .all_available_tools_title_text{
      font-family: $Gotham;
      font-size: 30px;
      font-weight: 500;
      text-align: left;
    }
    .is_new_tool_wrap{
      margin-right: 5px;
      margin-bottom: .8rem;
      line-height: 1.2;
      .is_new_tool{
        width:12px;
        height:12px;
        border-radius: 10px;
      }
    }
    .newly_added_tools {
      flex: 4;
      justify-content: flex-end;
display: flex;
align-items: flex-end;
span{
  margin-bottom: .5rem;
font-weight: 500;
font-size: 19px;
line-height: 1.2;
color: #008775;
}
    }
  }

  .all_available_tools_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px !important;


  }
}
//CustomizeToolsWidget.module.scss
.customize_tools_widget {
  width: 384px;

  @media only screen and (min-width: $bp-mobile-min) and (max-width: $bp-tablet-ipad-lanscape) {
    width: 80%;
    margin: auto;
    margin-bottom: 20px !important;
  }

  .customize_tools_heading {
    border-bottom: 2px solid #171717;
    display: flex;

    .customize_tools_title {
      flex: 8;
    }
    .customize_tools_title_text{
      font-family: $Gotham;
      font-size: 30px;
      font-weight: 500;
      text-align: left;
    }
    .customize_tools_close {
      flex: 2;
display: flex;
justify-content: flex-end;
    }
  }

  .customize_tools_editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px !important;
    padding: 0px !important;

  }

  .button_group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .customize_widget_button {
    height:35px;
    font-size: 18px!important;
  }


  .close_button_wrapper {
    position: absolute;
    border: none;
    background: none;
    padding:5px 0px;
    .close_button{
      color:$charcoal-black;

    }
  }
  .reset_to_default_button {
    margin: 0;
    border: 0px !important;
    background: none;
    color:#0047BB;
    font-weight: 400; 
  }

  .save_button {
    border: 0px !important;
    font-weight: 500; 
    width: 112px;
    color: $charcoal!important;     
  }
  .active_button{
    background: $mint!important;
  }
  .inactive_button{
    background: $pale-gray!important;
  }
}
//AvailableToolsItem.module.scss
.all_available_tools_item {
  list-style: none;
  position: relative;
  width: 30%;
  margin-right: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  &:nth-child(3n+2){
    background:#F3F3F3;
    .is_new_tool{
      background:none;
    }
  }
  @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
  width: 45%!important;
  background:none;
  &:nth-child(3n+2){
    background:none;
    .is_new_tool{
      background:none;
    }
  }
    }
.all_available_tools_item_link{
width:100%;
color:#1c1b1b!important;
text-decoration: none;
}

  .all_available_tools_item_button {
    height: 50px;
    border: 0px !important;
    background: none;
    font-weight: 500;
    border-radius: 0px !important;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .is_new_tool_wrap{
      width:5%;
      margin-right: 10px;
      .is_new_tool{
        width:12px;
        height:12px;
        border-radius: 10px;
      }
    }
.tool_label_text_wrap{
display: flex;
.tool_label_text{
font-size: 18px!important;
}
}
  }

}
//CustomizeMyToolsItem.module.scss
.customize_tools_item {
  list-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;

  &:hover {
    .customize_tools_dropdown {
      transition: 0.3s ease;
      display: block;

    }
  }

  &:nth-child(even) {
    padding-left: 2%;
    padding-right: 0px;

    .customize_tools_dropdown {
      background: #fff;
      margin-right: 0px !important;
      margin-left: 10px !important;
    }
  }

  &:nth-child(odd) {
    padding-right: 2%;
    padding-left: 0px;

    .customize_tools_dropdown {
      background: #fff;
      margin-right: 10px !important;
      margin-left: 0px !important;
    }
  }

  .customize_tools_item_button {
    background: none;
    height: 50px;
    border: 1px solid #6b6969;
    border-radius: 0px !important;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    .button_label {
      margin: auto !important;
      padding: 0px 0px 0px 10px !important;
      width: 90%;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 120%;
    }

    .tools_dropdown_icon_wrap {
      width: 10%;
      display: flex;
      height: 90%;
      justify-content: center;
      align-items: flex-end;

      .tools_dropdown_icon {
        fill: $mint;
      }

    }
  }

  .customize_tools_dropdown {
    max-height: 300px;
    transition: 0.3s ease;
    background: #fff;
    position: absolute;
    left: 0px;
    right: 0px;
    border: 1px solid #6b6969;
    z-index: 2;
    overflow: hidden;
    overflow-y: auto;

    .customize_tools_dropdown_option {
      list-style: none;
      min-height: 39px;
      display: flex;
      align-items: center;
      padding: 0px 10px;

      &:hover {
        background-color: #F3F3F3;
      }

      .customize_tools_dropdown_option_button {
        background: none;
        border-top: 0px !important;
        border-right: 0px !important;
        border-bottom: 1px solid #6b6969;
        border-left: 0px !important;
        text-align: left;
        width: 100%;
        padding: 10px 0px;
      }
    }

  }
}

//Alert.tsx
.alertWrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 2.5px;
}

.alertContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    top: 100px;

    @media (max-width: 767px) {
      top: 81px;
    }
  }
}

.alertContainer:hover {
  text-decoration: none !important;
}

.alertMessageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  gap: 10px;
  flex-flow: row nowrap;
  text-decoration: none;

  @media (max-width: 1260px) {
    width: 92.5% !important;
  }
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1260px;
  gap: 10px;

  @media (max-width: $bp-phone-max-events) {
    text-align: left;
  }
}

.alertImage {
  height: 19.2px;
  width: 23.5px;
}

.alertMessage {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  order: 2;
  margin-bottom: 0;
  margin-right: 10px;
  text-align: center;
  padding: 10px 0;
}

.alertPrimary {
  background-color: #d32426;
}

.alertSecondary {
  background-color: #279989;
}

.alertDarkBlue {
  background-color: #003B5C;
}

.alertAccentBlue {
  background-color: #0072CF;
}

.alertPurple {
  background-color: #6F5091;
}

.alertDefault {
  background-color: #A83D72;
}

.cursorPointer {
  cursor: pointer;
}