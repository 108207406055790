@import "../../../assets/scss/index.module.scss";

.admin {
  width: 100%;
  font-family: $Gotham;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: $vibrant-blue;

  .notAnAdmin {
    color: $charcoal;
  }

  .rowWrapper {
    background-color: transparent;
    width: 100%;

    .label {
      width: 100%;
      min-height: 19px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal;
    }
    .value {
      width: auto;
      min-height: 19px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal;
      padding-left: 10px;
      cursor: pointer;
      display: inline-block;

      .admin{
        font-weight: 400;
      }

      .admin:hover {
        color: $navy;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
