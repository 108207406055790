@import "../../../assets/scss/index.module.scss";

.mobileFilterNavBar{
  width:100%;
  border-top: 1px solid blue;
  border-bottom: 4px solid blue;
  height:50px;
  display: flex;
}
.jumpToContainer{
  width:30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jumpToText{
  font-family: $Gotham;
  font-size: 16px;
  font-weight: 600!important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center!important;
  color: #171717;
}
.selectContainer{
  width:40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left:1px solid $pale-gray;
  border-right: 1px solid $pale-gray;
}

.backToTopContainer{
  width:30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backToTopText{
  font-family: $Gotham;
  font-size: 16px;
  font-weight: 600!important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #0047bb!important;
  padding:0;
}