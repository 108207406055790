@import "../../../assets/scss/index.module.scss";

.officeBarContainer {
  width: 100%;
  position: fixed;
  height: 81px;
  top: 0;
  background-color: $vibrant-blue;
  z-index: 999;
  border-bottom: 4px solid $mint;
  padding: 0px 15px;
  @media (min-width: $bp-tablet-min) and (max-width: $bp-tablet-ipad-lanscape) {
  display:none;
  }
  .nwImage {
    margin-top: -5px;
    padding-right: 10px;
    margin-right: 10px;
  }
.headerInnerWrapper{
  max-width: 1200px;
  margin: auto;
  display: flex;
  @media only screen and (min-width: $bp-tablet-min) and (max-width: $bp-tablet-max) {
    flex-direction: column;
    display: flex;
    }
  @media only screen and (min-width: $bp-mobile-min) and (max-width: ($bp-tablet-min - 1)) {
    display: block;
    margin: auto;
  }
    @media (max-width: 1260px) {
      width: 95.5% !important;
    }
 
}

.notificationCountWrapper{
  top: 0px;
  position: absolute;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  background: $cherry-red;
  border: 1px solid #FFFFFF;
  left: 25px;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}
.notificationCountWrapper span{
  font-size: 10px!important;
}
.greetingMainWrapper{
  flex:2;
  display: flex;
}
  .profileWrapper {
    position: relative;
    top: 25px;
    height: 40px;
    flex-direction: row;
    display: flex;
  
  }
  .profileImageWrapper{
    align-items: center;
    display: flex;
  }
  .userGreetingWrapper{
    display: flex;
    

  }
  .greetingWrapper{
    display: flex;
  }
  .greetingText{
    color: #FFFFFF!important;
    font-family: $Gotham;
    font-weight: 600;
    text-align: left;
    line-height: 1.2;
    font-size: 18px!important;
    margin: 0px!important;
    padding: 0px!important;
  }
  .dropdownIconWrapper{
    display: flex;
    align-items: flex-end;
    margin-left:-55px!important;
    background: none!important;
    border: 0!important;
    margin-top: 30px;
  }
  .profileMenuArrow{
    width:12px!important;
    min-width: 12px;
    margin-bottom: 5px;
  }

  .active{
    transform: rotate(-180deg);
  }

.profileDropdownWrapper {
  left: 0;
  width: 100%;
  top: 100%;
  min-width: 256px;
  position: absolute;
  margin: 0;
  margin-top: 11px;
  height: 128px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 19px #00000040;
  border: 1px solid #D0D3D4;
  z-index: 999;
  flex-direction: column;

  a {
    flex: 1;
    display: flex;
    align-items: center;
    color: $charcoal;
    font-size: 16px;
    font-family: $Gotham;
    font-weight: 500;
    text-decoration: none !important;

    &:hover {
      color: $vibrant-blue;
    }

    &:focus {
      outline: 1px solid #000000 !important;
    }

    li {
      list-style: none;
    }
  }
}
.profileDropDownItem{
  list-style: none;
  display: flex;
  flex: 1;
  &:focus{
    outline: 1px solid #000000;
  }
}
.profileMenuItem{
  &:focus{
    outline: 1px solid #000000;
  }
}
.notificationPlaceholder{
  width:30px;
  display: flex;
  justify-content: center;
}
.notificationCounterWrapper{
  width: 14px;
  height: 14px;
  color: #FFFFFF;
  background: $cherry-red;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  font-size: 8px!important;
}

.insideMainLogoWrapper{
      text-align: center;
      height: 81px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex:4;
}
.insideMainLogo{
  height:52px;
}
.dateMainWrapper{
  display: flex;
  align-items: flex-end;
  flex:2;
justify-content: flex-end;
}
.dateWrapper{
  position: absolute;
    bottom: 12px;
    flex-direction: row;
    display: flex;
}
.dateWrapper span{
  color:#FFFFFF!important;
  text-align: right;
  font-size: 18px!important;
  font-family: $Gotham;
  font-weight: 400;
}
.dateWrapper_skeleton{
    position: absolute;
    bottom: 12px;
    flex-direction: row;
    display: flex;
    width: 296px;
    height: 40px;
    background: #1F74DB;
    animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes example {
  from {opacity: 1;}
  to {opacity: 0.7;}
}
}


.insideHeaderSkip {
  position: absolute;
  top: -100px;
  overflow: hidden;
  opacity: 0;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  z-index: 15000;
  text-align: center;
  background-color: #fff;
  border: 1px solid #bcbdbc;
  padding: 5px 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out;
  transition: opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out, top 0s .2s;
  transition: transform .2s ease-out, opacity .2s ease-out, top 0s .2s, -webkit-transform .2s ease-out
}

a {
  line-height: inherit;
  color: #0047bb;
  text-decoration: underline;
  cursor: pointer;
}

.insideHeaderSkip:focus {
  top: 10px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
  transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out;
  transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out
}
