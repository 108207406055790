@import "../../../assets/scss/index.module.scss";
.CommonToolsWrapper {
  .toolsHeading {
    position: relative;
    font-family: $SegoeUI;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
    border-bottom: solid 4px $steel-grey;
    margin-top: -7px;

    .titleLink{
      color: $charcoal-grey;
      text-decoration: none;
    }
    .titleLink:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $accent-blue !important;
    }
    .title {
      font-family: $SegoeUI;
      font-size: 24px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $charcoal-grey;
      margin: 8px;
      margin-left: 0;
      margin-top: 0;
      display: inline-block;
    }

    .newToolsOrArrows {
      display: inline-block;
      height: 31px;

      .newTools {
        font-family: $SegoeUI;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $accent-green;
        margin-left: 5px;
        position: absolute;
        bottom: 11px;
      }

      .arrows {
        display: none;
        @media (min-width: $bp-phone-min) and (max-width: $bp-phone-max) {
          position: absolute;
          //margin-top: 2px;
          //background-image: url("../../../assets/images/LeftRight.png");
          background-repeat: none;
          width: 88px;
          height: 31px;
          color: transparent;
          float: right;
          display: inline-block;
          right: -14px;
          bottom: 26px;
        }
      }
    }

    .closeButton {
      position: absolute;
      text-align: center;
      cursor: pointer;
      display: inline-block;
      right: 0;
      top: -10px;

      .closeX {
        border-bottom: 2px solid $charcoal-grey;
        transform: rotate(-45deg);
        width: 20px;
        display: inline-block;
        margin-bottom: 3px;

        &:before {
          content: " ";
          border-bottom: 2px solid $charcoal-grey;
          transform: rotate(90deg);
          width: 20px;
          display: inline-block;
          position: absolute;
          left: 0;
        }
      }

      .closeText {
        font-family: $SegoeUI;
        font-size: 8px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $charcoal-grey;
        display: block;
      }
    }
  }

  .customizeToolsText {
    font-family: $SegoeUI;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $steel-grey;
    margin-left: 5px;
  }

  .toolsDisplay {
    margin-left: -3px;
    margin-right: -1.5px;
    margin-top: 5px;
    text-align: center; // do not change this

    .emptyTool {
      border: dashed 3px $silver;
      top: 3px;
    }

    // @media (min-width: $bp-phone-min-landscape-events) and (max-width: $bp-tablet-ipad-lanscape) {
    //   padding-bottom: 10px;
    // }

    @media (min-width: $bp-phone-min) and (max-width: ($bp-phone-max-events)-1) {
      margin-bottom: 12px;
    }
  }
}
