@import "../../../assets/scss/index.module.scss";

.GenericAnnouncementCard {
  height: 395px;
}

.commonTools {
  @media (min-width: $bp-phone-max-events) and (max-width: $bp-tablet-ipad-lanscape) {
      grid-column: 1 / 3 !important;
  }
}