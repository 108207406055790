@import "../../../../../assets/scss/index.module.scss";

$guage-size: 60px;
$guage-width: 20px;

.showRow {
  position: relative;
  button{
    top:11px
  }
}

.beRow {
  margin: 0px !important;
  text-align: left;
}

.guageSection {
  padding-top: 10px;
  position: relative;
  width: calc(2 * #{$guage-size});
  margin: 0px auto !important;
  min-height: 0px !important;
  margin-bottom: 20px !important;
}

.guage {
  position: relative;
  overflow: hidden !important;
  margin: 0px !important;
  padding: 0px !important;
  background: $hr-connect-accent-2;
  width: calc(2 * #{$guage-size});
  height: $guage-size;
  border-radius: calc(2 * #{$guage-size}) calc(2 * #{$guage-size}) 0px 0px;
  text-align: center;
}

.dial {
  position: absolute;
  margin: 0px !important;
  padding: 0px !important;
  top: $guage-size;
  left: -200%;
  width: 400%;
  height: 400%;
  background: $hr-connect-accent;
  margin-left: $guage-size  !important;
  transform-origin: top center;
}

.guageMask {
  background: $hr-connect-background;
  height: calc(#{$guage-size} - #{$guage-width}) !important;
  width: calc(2 * #{$guage-size} - 2 * #{$guage-width});
  margin: 0px auto !important;
  border-radius: calc(2 * #{$guage-size} - 2 * #{$guage-width}) calc(2 * #{$guage-size} - 2 * #{$guage-width}) 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.showHide {
  top: -25px;
  padding-left: 10px;
  // right: 5px !important;
}

.guageText {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  bottom: -18px;
  font-size: 18px;
  font-weight: bold;
  left: 0;
  right: 0;
}

.meters {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 80%;
}

.meter {
  background-color: $hr-connect-accent-2;
  height: 5px;
  margin-top: 0px !important;
  font-size: 16px;
  margin-bottom: 5px !important;
}

.meterComplete {
  height: 5px;
  margin: 0 !important;
  background-color: $hr-connect-accent;
}

.meterLabel {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: 18px;

  & .meterText {
    font-size: 16px;
    position: absolute;
    left: 0;
  }

  & .meterPercent {
    position: absolute;
    right: 0;
  }
}

.textWordBreak {
  word-break: break-all;
}

.obfuscate {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  min-height: 127px;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
}


.myHealthProgramBottomSection {
  min-height: 110px;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
  margin-left: 15px;
  margin-right: 15px;
}

.myHealthProgramTopSection {
  min-height: 50px;
  text-align: center;
  // & div {
  //   margin: $section-div-margin-top-bottom 0px;
  // }
  position: static;
}